import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'SIMPLIFIED_REPS';

export const fetchAllSimplifiedReps = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createSimplifiedReps = generateAPHTypes(
  resource,
  'CREATE'
);

export const copySimplifiedReps = generateAPHTypes(
  resource,
  'COPY'
);

export const editSimplifiedReps = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteSimplifiedReps = generateAPHTypes(
  resource,
  'DELETE'
);
