import React from 'react';
import Button from '../../../common/Button';

export default (isCreateDialog, isCopyDialog, deleteAction, saveAction, cancelAction) => (
  <>
    {
      !isCreateDialog && !isCopyDialog && deleteAction &&
      <Button
        error={true}
        onClick={deleteAction}
        style={{ float: 'left' }}
      >
        Delete
      </Button>
    }
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    {
      !isCopyDialog ?
        <Button primary={true} onClick={saveAction} >Save</Button> :
        <Button primary={true} onClick={saveAction} >Copy</Button>
    }
  </>
);