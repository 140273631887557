import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import space from '../../../assets/themes/base/space';
import shadows from '../../../assets/themes/base/shadows';
import borders from '../../../assets/themes/base/borders';

const BaseBox = styled.div`
  // Display
  display: ${props => props.display};

  // Space
  margin: ${props => {
    if (typeof props.m === 'number') {
      return space[props.m] || `${props.m}rem`;
    }
    return props.m;
  }};
  margin-top: ${props => {
    if (typeof props.mt === 'number') {
      return space[props.mt] || `${props.mt}rem`;
    }
    return props.mt;
  }};
  margin-right: ${props => {
    if (typeof props.mr === 'number') {
      return space[props.mr] || `${props.mr}rem`;
    }
    return props.mr;
  }};
  margin-bottom: ${props => {
    if (typeof props.mb === 'number') {
      return space[props.mb] || `${props.mb}rem`;
    }
    return props.mb;
  }};
  margin-left: ${props => {
    if (typeof props.ml === 'number') {
      return space[props.ml] || `${props.ml}rem`;
    }
    return props.ml;
  }};
  padding: ${props => {
    if (typeof props.p === 'number') {
      return space[props.p] || `${props.p}rem`;
    }
    return props.p;
  }};
  padding-top: ${props => {
    if (typeof props.pt === 'number') {
      return space[props.pt] || `${props.pt}rem`;
    }
    return props.pt;
  }};
  padding-right: ${props => {
    if (typeof props.pr === 'number') {
      return space[props.pr] || `${props.pr}rem`;
    }
    return props.pr;
  }};
  padding-bottom: ${props => {
    if (typeof props.pb === 'number') {
      return space[props.pb] || `${props.pb}rem`;
    }
    return props.pb;
  }};
  padding-left: ${props => {
    if (typeof props.pl === 'number') {
      return space[props.pl] || `${props.pl}rem`;
    }
    return props.pl;
  }};

  // Colors
  background-color: ${props => props.bg && (colors[props.bg] || props.bg)};
  color: ${props => props.color && (colors[props.color] || props.color)};

  // Dimensions
  width: ${props => typeof props.width === 'number' ? `${props.width}px` : props.width};
  height: ${props => typeof props.height === 'number' ? `${props.height}px` : props.height};
  min-width: ${props => typeof props.minWidth === 'number' ? `${props.minWidth}px` : props.minWidth};

  // Position
  position: ${props => props.position};
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};

  // Typography
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.$textAlign};

  // Borders
  border: ${props => {
    if (typeof props.border === 'number') {
      return borders[props.border];
    }
    return props.border;
  }};
  border-color: ${props => props.borderColor && colors[props.borderColor]};
  box-shadow: ${props => props.boxShadow && (shadows[props.boxShadow] || props.boxShadow)};
  border-radius: ${props => props.borderRadius && radii[props.borderRadius]};
  border-width: ${props => typeof props.borderWidth === 'number' ? `${props.borderWidth}px` : props.borderWidth};
  border-style: ${props => props.borderStyle};

  // Custom props
  cursor: ${props => props.cursor};

${
  ({ $borderRadii, borderRadius }) => $borderRadii && Array.isArray($borderRadii) ?
    css`
      border-top-left-radius: ${radii[$borderRadii[0]]};
      border-top-right-radius: ${radii[$borderRadii[1]]};
      border-bottom-right-radius: ${radii[$borderRadii[2]]};
      border-bottom-left-radius: ${radii[$borderRadii[3]]};
    ` :
    !borderRadius && css`
      border-radius: ${radii[$borderRadii || 0]};
    `
}

${
  ({ $ellipsis }) => $ellipsis && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-all;
  `
}
`;

const Box = React.forwardRef((props, ref) => {
  const { textAlign, borderRadii, ...rest } = props;
  return (
    <BaseBox
      ref={ref}
      $textAlign={textAlign}
      $borderRadii={borderRadii}
      {...rest}
    />
  );
});

export default Box;