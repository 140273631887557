import React from 'react';
import { List } from 'immutable';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import fontSizes from '../../../assets/themes/base/fontSizes';

import chromeLogo from '../../../assets/images/browsers/chrome.png';
import firefoxLogo from '../../../assets/images/browsers/firefox.png';
import safariLogo from '../../../assets/images/browsers/safari.png';
import edgeLogo from '../../../assets/images/browsers/edge.png';

import CadactiveHelmet from '../CadactiveHelmet';

import Box from '../Box';
import Flex from '../Flex';
import Text from '../Text';

const browsers = List([
  {
    key: 'chrome',
    logo: chromeLogo,
    name: 'Google Chrome',
    description: 'We recommend using Chrome since it\'s fast, available on every operating system, and provides the best CadActive experience.',
    link: '//www.google.com/chrome/browser/desktop/',
  },
  {
    key: 'firefox',
    logo: firefoxLogo,
    name: 'Mozilla Firefox',
    description: 'Firefox is an open-source web browser that is a great alternative to Chrome.',
    link: '//www.mozilla.org/en-US/firefox/new/',
  },
  {
    key: 'safari',
    logo: safariLogo,
    name: 'Safari',
    description: 'Safari is a lightweight and fast browser that is built on the same framework as Google Chrome, but consumes significantly less memory.',
    link: '//support.apple.com/en_IN/downloads/safari',
  },
  {
    key: 'edge',
    logo: edgeLogo,
    name: 'Microsoft Edge',
    description: 'Microsoft Edge is the latest browser released by Microsoft (since Microsoft intends to stop versioning its browsers). It is included in Microsoft 10 and future operating systems.',
    link: '//www.microsoft.com/en-us/windows/microsoft-edge',
  },
]);

const BrowserLogo = styled.img`
  width: 3rem;
  height: 3rem;
`;

const BrowserLink = styled.a`
  font-size: ${fontSizes[3]};
  color: ${colors.primary[4]};
  text-decoration: none;
`;

const BrowserNotSupported = React.memo(() => (
  <Flex
    position='absolute'
    flexDirection='center'
    alignItems='center'
    justifyContent='center'
    top='0px'
    right='0px'
    bottom='0px'
    left='0px'
  >
    <CadactiveHelmet title='Browser Not Supported' />

    <Box width='50%' ml='25%' mr='25%'>
      <Box mb={7}>
        <Text color='gray.6' fontSize={8} textAlign='center'>Browser Not Supported</Text>
      </Box>

      <Box mb={7}>
        <Text fontSize={3} color='gray.6' textAlign='center'>
          We currently don&apos;t support your browser. However, we do support every major browser listed below (and every single one is completely free). If you&apos;re having any issues, feel free to contact our support team.
        </Text>
      </Box>

      <Box>
        {
          browsers.map(({ key, logo, name, description, link }) => (
            <Flex
              key={key}
              width='100%'
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              mb={6}
            >
              <Flex mr={5}>
                <BrowserLogo src={logo} />
              </Flex>
              <Flex
                mr={5}
                flex='1 1 auto'
                flexDirection='column'
                justifyContent='center'
              >
                <Text fontSize={3} color='gray.6' mb={1}>{ name }</Text>
                <Text color='gray.5'>{ description }</Text>
              </Flex>
              <Flex
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
              >
                <BrowserLink href={link}>Download</BrowserLink>
              </Flex>
            </Flex>
          ))
        }
      </Box>
    </Box>
  </Flex>
));

export default BrowserNotSupported;
