import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,1024})$/,
};

export default class NoteProfile extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING
  },
  archivedFlag: {
    value: false,
  },
  noteBlocks: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
  },
  notes: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  // get secondary() { return `${this.noteBlocks.size} ${pluralize('section', this.noteBlocks.size)}` || undefined; }
  // get secondaryPlaceholder() { return '0 section'; }

  get summary() {
    return {
      primary: this.name,
      // secondary: `${this.noteBlocks.size} ${pluralize('section', this.noteBlocks.size)}`,
    };
  }
}
