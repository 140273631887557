import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import space from '../../../assets/themes/base/space';

import Box from '../Box';

const StyledAlert = styled(Box)`
  padding: ${space[3]};
  border-radius: ${radii[2]};

  ${
  ({ $primary }) => $primary && css`
      background-color: ${colors.primary[0]};
      color: ${colors.primary[4]};
    `
}

  ${
  ({ $secondary }) => $secondary && css`
      background-color: ${colors.gray[0]};
    `
}

  ${
  ({ $error }) => $error && css`
      background-color: ${colors.error[0]};
      color: ${colors.error[4]};
    `
}
`;

const Alert = React.forwardRef(
  (props, ref) => <StyledAlert ref={ref} $primary={props.primary} $secondary={props.secondary} $error={props.error} />
);

export default Alert;