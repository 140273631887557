import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import borders from '../../../../../../../assets/themes/base/borders';
import colors from '../../../../../../../assets/themes/base/colors';
import radii from '../../../../../../../assets/themes/base/radii';
import space from '../../../../../../../assets/themes/base/space';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import Box from '../../../../../../../components/common/Box';
import Flex from '../../../../../../../components/common/Flex';
import Text from '../../../../../../../components/common/Text';

import ConnectedListItem from '../ConnectedListItem';

const ConnectedListContainer = styled(Box)`
  padding: ${space[4]};
  background-color: ${colors.gray[1]};
  padding: ${space[3]};

  border-radius: ${radii[2]};
  border: ${borders[2]};
`;

const ConnectedListItemsEmpty = styled(Flex)`
  background-color: ${colors.white};

  border-radius: ${radii[2]};
  border: ${borders[2]};

  height: 25rem;
`;

const ConnectedListItems = styled(Box)`
  min-height: 25rem;
  max-height: 35rem;
  overflow: auto;
`;

const ConnectedList = ({
  items,
  noItemsText,
  noItemsSubtext,
  currentSelectedItemId,
  onSort,
  onToggleSelect,
  onToggleConnector,
  onDelete,
}) => {
  const onMoveComplete = useCallback(
    (item, moveIndex) => {
      const existingIndex = items.findIndex((i) => i.id === item.id);
      if (existingIndex !== moveIndex) {
        onSort(item, moveIndex + 1);
      }
    },
    [items, onSort]
  );

  return (
    <DndProvider backend={Backend}>
      <ConnectedListContainer>
        {items.size > 0 ? (
          <ConnectedListItems>
            {items.map((item, index) => (
              <ConnectedListItem
                key={item.id}
                item={item}
                index={index}
                isSelected={item.id === currentSelectedItemId}
                onMoveComplete={onMoveComplete}
                onToggleSelect={onToggleSelect}
                onToggleConnector={onToggleConnector}
                onDelete={onDelete}
                firstInGroup={
                  index === 0 || items.getIn([index - 1, 'isOrLogic'])
                }
                lastInGroup={index + 1 === items.size || item.isOrLogic}
              />
            ))}
          </ConnectedListItems>
        ) : (
          <ConnectedListItemsEmpty
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize={3} color="gray.6" mb={4}>
              {noItemsText}
            </Text>
            <Text fontSize={2} color="gray.6">
              {noItemsSubtext}
            </Text>
          </ConnectedListItemsEmpty>
        )}
      </ConnectedListContainer>
    </DndProvider>
  );
};

export default ConnectedList;
