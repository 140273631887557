import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import BaseFormRow from '../../../components/form/BaseFormRow';
import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

import { SECTION_METHOD_TYPE_OPTIONS_LIST } from '../../../entities/Standardize/Sections/model';

const FilterForm = (props) => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field component={RDXTextInput} name="name" label="Name" type="text" />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name="sectionMethod"
        label="Section Method"
        options={SECTION_METHOD_TYPE_OPTIONS_LIST}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="datumFeatureName"
        label="Datum Feature Name"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="description"
        label="Description"
        type="text"
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.SECTIONS.state}` })
);

export default enhance(FilterForm);
