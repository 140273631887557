import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PARAMETER_PROFILE_PARAMETERS';

export const saveParameterProfileParameters = generateAPHTypes(resource, 'COLLECTION');

export const newParameterProfileParameterRows = [
  { id: 'NEW_ROW_1', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_2', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_3', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_4', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_5', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_6', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_7', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_8', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_9', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_10', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_11', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_12', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_13', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_14', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
  { id: 'NEW_ROW_15', paramIsLocked: false, paramIsDesignated: false, parameter: '', defaultValue: '' },
];