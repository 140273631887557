import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'ORIENTATIONS';

export const fetchAllOrientations = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createOrientationTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const saveOrientations = generateAPHTypes(
  resource,
  'COLLECTION'
);
