import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllRoutingProfiles,
  createRoutingProfile,
  copyRoutingProfile,
  editRoutingProfile,
  deleteRoutingProfile,
} from './constants';
import schema from './schema';

const client = api(schema, EK.AUTOMATE_ROUTING_PROFILES);

export const processFetchAllRoutingProfiles = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllRoutingProfiles,
    '/automate/routing_profiles'
  );

export const processCreateRoutingProfile = data =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createRoutingProfile,
    '/automate/routing_profiles',
    {
      data: {
        routingProfiles: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create profile',
      },
    }
  );

export const processEditRoutingProfile = (profileId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editRoutingProfile,
    `/automate/routing_profiles/${profileId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteRoutingProfile = profileId =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteRoutingProfile,
    `/automate/routing_profiles/${profileId}?with_full_detail=true`,
    {
      deleteKey: EK.AUTOMATE_ROUTING_PROFILES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete profile',
      },
    }
  );

export const processCopyRoutingProfile = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyRoutingProfile,
    '/automate/routing_profiles/copy',
    {
      data: {
        routingProfiles: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Routing profile successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy routing profile',
      },
    }
  );
