import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { StandardizeModuleParameterProfilesPath } from '../../../paths';

export default function ParameterProfilesBreadcrumb() {
  return (
    <BreadcrumbTemplate title={StandardizeModuleParameterProfilesPath.defaultTitle}></BreadcrumbTemplate>
  );
}
