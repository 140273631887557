import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';
import { ALGORITHM_TYPES, ALGORITHM_OPTIONS } from './constants';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_-]{0,31})$/,
};

// prettier-ignore
export default class RoutingProfile extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.name) && ALLOWANCES.NAME.test(entity.name),
    validateError: 'A valid name must be present and no longer than 31 characters',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  algorithm: {
    value: ALGORITHM_TYPES.A_STAR_MIN_MAX_ANT_SYSTEM,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.algorithm) &&
      !!ALGORITHM_OPTIONS.find(option => option.value === entity.algorithm),
    validateError: 'A valid algorithm type must be selected',
    filter: FILTERS.STRING,
  },
  allowRetraction: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
  },
  nIterations: {
    value: 10,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  nMinNodes: {
    value: 0,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  timeout: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  iterationTimeout: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  nAnts: {
    value: 10,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  acoAlpha: {
    value: '1.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  acoBeta: {
    value: '5.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  acoEvapRateGlobal: {
    value: '0.5',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  acoEvapRateLocal: {
    value: '0.5',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  acoQ: {
    value: '1.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  acoProbabilityBest: {
    value: '0.05',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  frontierNNodes: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.NUMBER,
  },
  frontierStartingDistance: {
    value: '1.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  frontierExpandingDistance: {
    value: '0.1',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  geometryDivideCurveByDistance: {
    value: '1.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  geometryDivideCurveBySegmentNumber: {
    value: '5.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  maxTurnAngle: {
    value: '100.0',
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  }
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}
