import React from 'react';
import { NavLink } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled, { css } from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import space from '../../../../assets/themes/base/space';
import transitions from '../../../../assets/themes/base/transitions';
import fontSizes from '../../../../assets/themes/base/fontSizes';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';

const BreadcrumbList = styled(Flex).attrs(props => ({
  flexDirection: 'row',
  alignItems: 'center',
}))`
  height: 2rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

const BreadcrumbToken = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  font-size: ${ ({ $large }) => $large ? fontSizes[2] : fontSizes[1] };
  font-weight: 500;
  height: 2rem;
  max-width: 10rem;
  margin-right: ${space[2]};
  text-transform: none;

  transition: color ${transitions.speed.fast} ${transitions.type.inOut};

  color: ${colors.gray[5]};

  &:last-child {
    color: ${colors.gray[7]};
  }

  ${
  ({ to }) => to && css`
      &:hover,
      &:focus,
      &:active {
        color: ${colors.gray[6]};
      }
    `
}
`;

const Breadcrumbs = ({ breadcrumbs, isLoading }) => (
  <BreadcrumbList>
    {
      breadcrumbs.map(({ breadcrumb, match }, idx) => {
        const isLastItem = breadcrumbs.length === idx + 1;
        return (
          <React.Fragment key={idx}>
            {
              (isLoading && <Box width={ idx === 0 ? '1.5rem' : '5rem' } mr={2}><Skeleton /></Box>) ||
              <BreadcrumbToken as={!isLastItem && NavLink || undefined} to={!isLastItem && match.url || undefined}>
                { breadcrumb }
              </BreadcrumbToken>
            }
            { !isLastItem && <BreadcrumbToken $large pl={1} pr={1}>{ '//' }</BreadcrumbToken> }
          </React.Fragment>
        );
      })
    }
  </BreadcrumbList>
);

export default Breadcrumbs;
