import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BackTemplate from '../../templates/Structures/Back';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../../Dashboard/selectors';

import { selectCurrentPipe } from '../PipesEditSwitch/selectors';

import { PipingModulePipesBendMachinesPath } from '../../../paths';

const mapStateToProps = createSelector(
  selectIsFetching(),
  selectIsLoadingInitialData(),
  selectCurrentPipe(),
  (isFetching, isLoadingInitialData, pipe) => ({
    isLoading: isFetching || isLoadingInitialData,
    to:
      (pipe &&
        pipe.id &&
        PipingModulePipesBendMachinesPath.generate({ id: pipe.id })) ||
      undefined,
    label: (pipe && pipe.name && `${pipe.name} // Bend Machines`) || undefined,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BackTemplate);
