import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';

import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import Button from '../../../components/common/Button/index';
import { pluralize } from '../../../components/common/Header';

import {
  selectSidebarIsFiltering,
  selectAutomateModulePermissionsAndState,
} from '../../Dashboard/selectors';

import DeleteDialogFooter from '../../../components/modal/ModalFooters/DeleteDialogFooter';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';
// import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { AutomateModuleRoutingProfilesPath } from '../../../paths';
import {
  selectCurrentFilteredRoutingProfiles,
  selectCurrentRoutingProfilesCount
} from '../../../entities/Automate/RoutingProfiles/selectors';

import {
  processCreateRoutingProfile,
  processEditRoutingProfile,
  processDeleteRoutingProfile
} from '../../../entities/Automate/RoutingProfiles/actions';

import { ALGORITHM_OPTIONS } from '../../../entities/Automate/RoutingProfiles/constants';
import { selectUnitSystemsOptions } from '../../../entities/Synchronize/UnitSystems/selectors';

const mapStateToProps = createSelector(
  selectAutomateModulePermissionsAndState(),
  selectCurrentFilteredRoutingProfiles(),
  selectSidebarIsFiltering(),
  selectCurrentRoutingProfilesCount(),
  selectUnitSystemsOptions(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count,
    unitSystems
  ) => {
    const isLoading = isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isLoading,
      count,
      isFiltering,
      hasValidLicense,
      canCollaborate,
      unitSystems: unitSystems.toArray() || []
    };
  }
);

export default function RoutingProfilesBody(props) {
  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const emptyRow = {
    id: '',
    name: '',
    description: '',
    unitSystemId: '',
    algorithm: '',
    nIterations: '',
    nAnts: '',
    acoAlpha: '',
    acoBeta: '',
    acoEvapRateGlobal: '',
    acoEvapRateLocal: '',
    acoQ: '',
    acoProbabilityBest: '',
    frontierNNodes: '',
    frontierStartingDistance: '',
    frontierExpandingDistance: '',
    geometryDivideCurveByDistance: '',
    geometryDivideCurveBySegmentNumber: '',
    maxTurnAngle: '',
    allowRetraction: false,
    nMinNodes: '',
    timeout: '',
    iterationTimeout: ''
  };

  const [row, setRow] = useState(emptyRow);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const newRoutingProfile = () => {
    setRow(emptyRow);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const editRow = (rowdata) => {
    let editRow = {
      id: rowdata.id,
      name: rowdata.name || '',
      description: rowdata.description || '',
      unitSystemId: rowdata.unitSystem || '',
      algorithm: rowdata.algorithm || '',
      nIterations: rowdata.nIterations || '',
      nAnts: rowdata.nAnts || '',
      acoAlpha: rowdata.acoAlpha || '',
      acoBeta: rowdata.acoBeta || '',
      acoEvapRateGlobal: rowdata.acoEvapRateGlobal || '',
      acoEvapRateLocal: rowdata.acoEvapRateLocal || '',
      acoQ: rowdata.acoQ || '',
      acoProbabilityBest: rowdata.acoProbabilityBest || '',
      frontierNNodes: rowdata.frontierNNodes || '',
      frontierStartingDistance: rowdata.frontierStartingDistance || '',
      frontierExpandingDistance: rowdata.frontierExpandingDistance || '',
      geometryDivideCurveByDistance: rowdata.geometryDivideCurveByDistance || '',
      geometryDivideCurveBySegmentNumber: rowdata.geometryDivideCurveBySegmentNumber || '',
      maxTurnAngle: rowdata.maxTurnAngle || '',
      allowRetraction: rowdata.allowRetraction || false,
      nMinNodes: rowdata.nMinNodes || '',
      timeout: rowdata.timeout || '',
      iterationTimeout: rowdata.iterationTimeout || ''
    };
    setRow(editRow);
    setIsDialogOpen(true);
  };

  const cancelDialog = () => {
    setIsCreateDialogOpen(false);
    setIsDialogOpen(false);
  };

  const saveAction = () => {
    //dispatches event to update row, the useSelector will update itself if the object it is pointed at changes
    if (!reduxProps.editable) {
      cancelDialog();
      return;
    }

    if (isCreateDialogOpen) {
      dispatch(processCreateRoutingProfile(row));
    } else {
      dispatch(processEditRoutingProfile(row.id, row));
    }
    cancelDialog();
  };

  const deleteItemDialog = () => {
    cancelDialog();
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteItem = () => {
    dispatch(processDeleteRoutingProfile(row.id));
    setIsDeleteDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    //copies the rowdata, then replaces just the correct field with the changed input value
    const val = (e.target && e.target.value !== null) ? e.target.value : '';
    let _row = { ...row };
    _row[`${name}`] = val;
    setRow(_row);
  };

  //actions for each row
  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => editRow(rowdata)} />
      </>
    );
  };

  const algorithmBodyTemplate = (rowData) => {
    const option = ALGORITHM_OPTIONS.find(opt => opt.value === rowData.algorithm);
    return option ? option.label : rowData.algorithm;
  };

  const getDialogHeader = useMemo(() => {
    return isCreateDialogOpen ? 'Create Routing Profile' : `Edit '${row.name}'`;
  }, [isDialogOpen]);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={AutomateModuleRoutingProfilesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Routing Profile', reduxProps.data.length)}` :
            `Showing ${reduxProps.count} ${pluralize('Routing Profile', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={newRoutingProfile}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          <Column
            field='name'
            header="Name"
            style={{ width: '15%' }}
          />
          <Column
            field='algorithm'
            header='Algorithm'
            body={algorithmBodyTemplate}
            style={{ width: '15%' }}
          />
          <Column
            field='nIterations'
            header='# Iterations'
            style={{ width: '10%' }}
          />
          <Column
            field='description'
            header='Description'
            style={{ width: '25%' }}
          />
          <Column
            body={actionBodyTemplate}
            style={{ width: '20%', textAlign: 'right' }}
          />
        </DataTable>
      </Flex>
      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={getDialogHeader}
        footer={DialogFooter(isCreateDialogOpen, false, deleteItemDialog, saveAction, cancelDialog)}
        modal
        className="p-fluid"
        closable={false}
      >
        <h3>Basic Information</h3>
        <div className="field">
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText
            id="name"
            value={row.name}
            onChange={(e) => onInputChange(e, 'name')}
            required
            autoFocus
          />
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea
            id="description"
            value={row.description}
            onChange={(e) => onInputChange(e, 'description')}
            required
            rows={3}
            cols={20}
          />
        </div>
        <div className='field'>
          <label htmlFor='unitSystemId' className='font-bold'>Unit System</label>
          <Dropdown
            value={row.unitSystemId}
            onChange={(e) => onInputChange(e, 'unitSystemId')}
            options={reduxProps.unitSystems || []}
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Unit System"
          />
        </div>
        <div className='field'>
          <label htmlFor='algorithm' className='font-bold'>Algorithm</label>
          <Dropdown
            value={row.algorithm}
            onChange={(e) => onInputChange(e, 'algorithm')}
            options={ALGORITHM_OPTIONS}
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div className='field'>
          <label htmlFor='nIterations' className='font-bold'># Iterations</label>
          <InputText
            id='nIterations'
            value={row.nIterations}
            onChange={(e) => onInputChange(e, 'nIterations')}
            required
          />
        </div>

        <h3>Ant Colony Parameters</h3>
        <div className='field'>
          <label htmlFor='nAnts' className='font-bold'># Ants</label>
          <InputText
            id='nAnts'
            value={row.nAnts}
            onChange={(e) => onInputChange(e, 'nAnts')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoAlpha' className='font-bold'>Alpha</label>
          <InputText
            id='acoAlpha'
            value={row.acoAlpha}
            onChange={(e) => onInputChange(e, 'acoAlpha')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoBeta' className='font-bold'>Beta</label>
          <InputText
            id='acoBeta'
            value={row.acoBeta}
            onChange={(e) => onInputChange(e, 'acoBeta')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoEvapRateGlobal' className='font-bold'>Global Evaporation Rate</label>
          <InputText
            id='acoEvapRateGlobal'
            value={row.acoEvapRateGlobal}
            onChange={(e) => onInputChange(e, 'acoEvapRateGlobal')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoEvapRateLocal' className='font-bold'>Local Evaporation Rate</label>
          <InputText
            id='acoEvapRateLocal'
            value={row.acoEvapRateLocal}
            onChange={(e) => onInputChange(e, 'acoEvapRateLocal')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoQ' className='font-bold'>Q Value</label>
          <InputText
            id='acoQ'
            value={row.acoQ}
            onChange={(e) => onInputChange(e, 'acoQ')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='acoProbabilityBest' className='font-bold'>Best Path Probability</label>
          <InputText
            id='acoProbabilityBest'
            value={row.acoProbabilityBest}
            onChange={(e) => onInputChange(e, 'acoProbabilityBest')}
            required
          />
        </div>

        <h3>Frontier Settings</h3>
        <div className='field'>
          <label htmlFor='frontierNNodes' className='font-bold'># Frontier Nodes</label>
          <InputText
            id='frontierNNodes'
            value={row.frontierNNodes}
            onChange={(e) => onInputChange(e, 'frontierNNodes')}
          />
        </div>
        <div className='field'>
          <label htmlFor='frontierStartingDistance' className='font-bold'>Frontier Starting Distance</label>
          <InputText
            id='frontierStartingDistance'
            value={row.frontierStartingDistance}
            onChange={(e) => onInputChange(e, 'frontierStartingDistance')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='frontierExpandingDistance' className='font-bold'>Frontier Expanding Distance</label>
          <InputText
            id='frontierExpandingDistance'
            value={row.frontierExpandingDistance}
            onChange={(e) => onInputChange(e, 'frontierExpandingDistance')}
            required
          />
        </div>

        <h3>Geometry Settings</h3>
        <div className='field'>
          <label htmlFor='geometryDivideCurveByDistance' className='font-bold'>Curve Division Distance</label>
          <InputText
            id='geometryDivideCurveByDistance'
            value={row.geometryDivideCurveByDistance}
            onChange={(e) => onInputChange(e, 'geometryDivideCurveByDistance')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='geometryDivideCurveBySegmentNumber' className='font-bold'>Curve Segments</label>
          <InputText
            id='geometryDivideCurveBySegmentNumber'
            value={row.geometryDivideCurveBySegmentNumber}
            onChange={(e) => onInputChange(e, 'geometryDivideCurveBySegmentNumber')}
            required
          />
        </div>

        <h3>Path Constraints</h3>
        <div className='field'>
          <label htmlFor='maxTurnAngle' className='font-bold'>Max Turn Angle</label>
          <InputText
            id='maxTurnAngle'
            value={row.maxTurnAngle}
            onChange={(e) => onInputChange(e, 'maxTurnAngle')}
            required
          />
        </div>
        <div className='field'>
          <label htmlFor='allowRetraction' className='font-bold'>Allow Retraction</label>
          <Checkbox
            id='allowRetraction'
            checked={row.allowRetraction}
            onChange={(e) => onInputChange(e, 'allowRetraction')}
          />
        </div>
        <div className='field'>
          <label htmlFor='nMinNodes' className='font-bold'>Min Nodes</label>
          <InputText
            id='nMinNodes'
            value={row.nMinNodes}
            onChange={(e) => onInputChange(e, 'nMinNodes')}
            required
          />
        </div>

        <h3>Timeouts</h3>
        <div className='field'>
          <label htmlFor='timeout' className='font-bold'>Timeout (ms)</label>
          <InputText
            id='timeout'
            value={row.timeout}
            onChange={(e) => onInputChange(e, 'timeout')}
          />
        </div>
        <div className='field'>
          <label htmlFor='iterationTimeout' className='font-bold'>Iteration Timeout (ms)</label>
          <InputText
            id='iterationTimeout'
            value={row.iterationTimeout}
            onChange={(e) => onInputChange(e, 'iterationTimeout')}
          />
        </div>
      </Dialog>
      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header="Delete Routing Profile"
        footer={DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteItem)}
        modal
        className='p-fluid'
        closable={false}
      >
        <div>
          <Text>Are you sure you want to delete {row.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}