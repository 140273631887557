import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

// Data Grid imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// sagas
import saga from './sagas';
import injectSaga from '../../../utils/sagas/injectSaga';
import EK from '../../../entities/keys';

// cell imports
import NameInstancesLinkCell from '../../../components/grid/NameInstancesLinkCell';
import TextCell from '../../../components/grid/TextCell';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

// dialog imports
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { InputText } from 'primereact/inputtext';
import DeleteDialogFooter from '../../../components/modal/ModalFooters/DeleteDialogFooter';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';
import { BlankInsulationFamily, VALIDATION_FIELDS } from '../../../entities/Piping/InsulationFamilies/model';
import { areFieldsValid, doRequiredFieldsExist } from './components';

// misc imports
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';

// Header imports
import Header from '../../templates/Structures/Header';
import Skeleton from 'react-loading-skeleton';
import Options from '../../templates/Structures/Options';
import { pluralize } from '../../../components/common/Header';
import { PipingModuleInsulationsEditPath, PipingModuleInsulationsPath } from '../../../paths';

// actions
import {
  processArchiveInsulationFamilies,
  processCopyInsulationFamily,
  processCreateInsulationFamily,
  processDeleteInsulationFamily,
  processEditInsulationFamily,
  processFetchAllInsulationFamilies,
  processUnarchiveInsulationFamilies,
} from '../../../entities/Piping/InsulationFamilies/actions';
import { processFetchAllUnitSystems } from '../../../entities/Synchronize/UnitSystems/actions';

// selectors
import { createSelector } from 'reselect';
import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import {
  selectCurrentFilteredInsulations,
  selectInsulationFamiliesCounts,
} from '../../../entities/Piping/InsulationFamilies/selectors';
import { selectUnitSystems } from '../../../entities/Synchronize/UnitSystems/selectors';
import { selectPipingCatalogUnitSystem } from '../../../entities/Piping/Catalogs/selectors';
import { DeleteInsulationDialog, InsulationDialog } from '../../../containers/dialog/templates/Piping/InsulationDialog';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredInsulations(),
  selectInsulationFamiliesCounts(),
  selectUnitSystems(),
  selectPipingCatalogUnitSystem(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    { unarchived, total },
    unitSystems,
    defaultUnitSystem,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      unarchived,
      total,
      isFetching,
      unitSystems: unitSystems && unitSystems.toList().toArray() || [],
      defaultUnitSystem,
      canCollaborate,
      hasValidLicense,
    };
  },
);

function InsulationsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);                 // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);     // used to determine header text and select "save" action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);         // used to determine header text and select "save" action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);     // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  // used to provide data for the modals
  const [currentEditingRow, setCurrentEditingRow] = useState(BlankInsulationFamily);

  // header actions
  const openCreateDialog = () => {
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  // Dialog Actions
  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BlankInsulationFamily);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
  };

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Insulation Family';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const saveAction = () => {
    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      unit_system_id: currentEditingRow.unitSystem?.id || '',
      description: currentEditingRow.description,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateInsulationFamily(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyInsulationFamily(editedRow));
    } else {
      dispatch(processEditInsulationFamily(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteInsulationFamily(currentEditingRow.id));
    setCurrentEditingRow(BlankInsulationFamily);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    // closes the other dialogs and opens the delete Dialog
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  //cell actions
  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const gotoInsulationInstances = (insulationId) => {
    const link = PipingModuleInsulationsEditPath.generate({ id: insulationId });
    dispatch(push(link));
  };

  const editRowAction = (rowdata) => {
    // Immutable.Entity's properties prevent the object from being used directly, so the object is converted here
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      unitSystem: rowdata.unitSystem,
      description: rowdata.description,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      unitSystem: rowdata.unitSystem,
      description: rowdata.description,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveInsulationFamilies([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveInsulationFamilies([rowdata.id]));
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BlankInsulationFamily);
  };

  useEffect(() => {
    dispatch(processFetchAllInsulationFamilies());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading || (reduxProps.isFetching && reduxProps.total === 0)}
          title={PipingModuleInsulationsPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Insulation',
              reduxProps.showArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.showArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Insulation', reduxProps.unarchived)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'
        >
          <Column
            field='name'
            header='Name // Instances'
            body={(rowData) => NameInstancesLinkCell(rowData, gotoInsulationInstances)}
            style={{ width: '33%' }}
          />
          <Column
            field='description'
            header='Description'
            body={(rowdata) => <TextCell value={rowdata.description} placeholder="No Description" />}
            style={{ width: '33%' }}
          />
          <Column
            header=''
            style={{ textAlign: 'right' }}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => gotoInsulationInstances(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />
            }
          />
        </DataTable>
      </Flex>
      <InsulationDialog
        currentEditingRow={currentEditingRow}
        editable={reduxProps.editable}
        getDialogHeader={getDialogHeader}
        handleEditedRowChange={handleEditedRowChange}
        isCreateDialogOpen={isCreateDialogOpen}
        isCopyDialogOpen={isCopyDialogOpen}
        isDialogOpen={isDialogOpen}
        openDeleteDialog={openDeleteDialog}
        saveAction={saveAction}
        cancelDialogs={cancelDialogs}
        validation={validation}
        defaultUnitSystem={reduxProps.defaultUnitSystem}
      />
      <DeleteInsulationDialog
        editable={reduxProps.editable}
        isDeleteDialogOpen={isDeleteDialogOpen}
        currentEditingRow={currentEditingRow}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        deleteAction={deleteAction}
      />
    </>
  );
}

export default injectSaga({ key: `${EK.INSULATIONS.state}Page`, saga })(withRouter(InsulationsGrid));
