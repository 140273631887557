import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../../entities/keys';

import selectEntities from '../../../modules/entities/selectors';

import { selectRouteLocationPathname } from '../../../modules/router/selectors';

import { selectNormalizedNoteProfiles } from '../../../entities/Standardize/NoteProfiles/selectors';

import NoteBlockNoteSchema from '../../../entities/Standardize/NoteBlockNotes/schema';

import {
  StandardizeModuleNoteProfilesEditPath,
} from '../../../paths';

export const selectCurrentNoteProfileId = () => createSelector(
  selectRouteLocationPathname(),
  pathname => {
    const match = matchPath(pathname, { path: StandardizeModuleNoteProfilesEditPath.url });
    return match && match.params.id || null;
  }
);

export const selectCurrentNoteProfile = () => createSelector(
  selectCurrentNoteProfileId(),
  selectNormalizedNoteProfiles(),
  (noteProfileId, noteProfiles) => noteProfiles.get(noteProfileId)
);

export const selectCurrentNoteBlocks = () => createSelector(
  selectCurrentNoteProfileId(),
  selectEntities(),
  (profileId, entities) => profileId && entities.get(EK.NOTE_BLOCKS.state).filter(noteBlock => noteBlock.noteProfile === profileId).toList() || null
);

export const selectCurrentNoteBlockNotes = () => createSelector(
  (_, ownProps) => ownProps.noteBlock.id,
  selectEntities(),
  (noteBlockId, entities) => noteBlockId && entities.get(EK.NOTE_BLOCK_NOTES.state).filter(noteBlockNote => noteBlockNote.noteBlock === noteBlockId).sortBy(noteBlockNote => noteBlockNote.sortOrder).toList() || null
);

export const selectCurrentNoteBlockNote = () => createSelector(
  (_, ownProps) => ownProps.noteBlockNote,
  selectEntities(),
  (noteBlockNote, entities) => (denormalize(noteBlockNote, NoteBlockNoteSchema, entities) || {}).note || undefined
);

export const selectCurrentNoteProfileNotesCount = () => createSelector(
  selectCurrentNoteBlocks(),
  noteBlocks => noteBlocks && noteBlocks.reduce((total, noteBlock) => noteBlock.noteBlockNotes.size + total, 0) || 0
);

export const selectIsSavingCurrentNoteProfile = () => state => state.getIn([`${EK.NOTE_PROFILES.state}Edit`, 'isSaving']);