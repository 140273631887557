import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.NOTE_PROFILES.state;

export const fetchAllNoteProfilesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualNoteProfiles = generateAPHTypes(resource, 'FETCH');

export const createNoteProfiles = generateAPHTypes(resource, 'CREATE');

export const copyNoteProfiles = generateAPHTypes(resource, 'COPY');

export const editNoteProfiles = generateAPHTypes(resource, 'EDIT');

export const deleteNoteProfiles = generateAPHTypes(resource, 'DELETE');

export const archiveNoteProfilesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveNoteProfilesTypes = generateAPHTypes(resource, 'UNARCHIVE');
