/**
 * Handles login process and waits for logout
 */

import { call, delay, put, race, select, take } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

import { authenticateClient, deauthenticateClient } from '../api/client';
import { getToken, removeToken, setToken } from './utils/token';
import { processFetchCurrentUser, logoutHandled } from './actions';

import { selectNormalizedCurrentUser } from '../../entities/CurrentUser/selectors';

import { processLogout } from './constants';

export function* signout() {
  try {
    yield call(removeToken);
    yield call(deauthenticateClient);
    yield put(logoutHandled());
  } catch (error) {
    console.error('Error during signout:', error);
  }
}

export default function* authorizationSaga() {
  while (true) {
    try {
      let token = yield call(getToken);

      if (!token) {
        const { payload: { authenticationToken, rememberMe = true } = {} } =
          yield take((action) =>
            action?.payload?.authenticationToken
          );

        // Clear any existing token first
        yield call(removeToken);
        yield call(deauthenticateClient);

        token = authenticationToken;

        if (!token) continue;

        if (rememberMe) {
          yield call(setToken, token);
          yield call(authenticateClient, token);
        }
      }

      yield call(authenticateClient, token);

      // if there is no current user at the moment because we registered or accepted an invite, we need to reload current user
      if (!(yield select(selectNormalizedCurrentUser()))) {
        yield put(processFetchCurrentUser());
      }

      try {
        token = yield call(jwtDecode, token);
      } catch (error) {
        console.error('Error decoding token:', error);
        yield call(signout);
        continue;
      }

      let userSignedOut = false;
      while (!userSignedOut) {
        const { expired } = yield race(
          {
            expired: delay(token.exp, true),
            signout: take(processLogout.HANDLE),
          }
        );

        if (expired) {
          yield call(signout);
          userSignedOut = true;
        } else {
          yield call(signout);
          userSignedOut = true;
        }
      }
    } catch (error) {
      console.error('Error in auth saga:', error);
      yield call(signout);
    }
  }
}
