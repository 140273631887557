import { toast } from 'react-toastify';

import pipeInstanceClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { savePipeInstances } from './constants';

export const processSavePipeInstances = (pipeFamilyId, data) => pipeInstanceClient(REQUEST_TYPES.PATCH, 'plural', savePipeInstances, `/piping/pipe_families/${pipeFamilyId}?diff_result=true`, {
  data: { pipeInstancesAttributes: data },
  mutateResponse: ({ pipeFamily: { id } = {}, pipeInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    pipeInstances: {
      created: created.map(instance => ({
        ...instance,
        pipeFamily: `${id}`,
        pipeFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        pipeFamily: `${id}`,
        pipeFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        pipeFamily: `${id}`,
        pipeFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
