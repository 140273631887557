import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';

import EK from '../../keys';
import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const fittingCategoryFilterForm = formValueSelector(`filters.${EK.FITTING_CATEGORIES.state}`);

export const selectNormalizedFittingCategories = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.FITTING_CATEGORIES.state)
);

export const selectCurrentFilteredNormalizedFittingCategories = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedFittingCategories(),
  state => {
    const formValue = fittingCategoryFilterForm(state, 'allowValveNumber');

    // When a value is selected, formValue will be an Immutable Map with a value property
    // When cleared, it will be undefined/null

    // Extract the actual boolean value
    let allowValveNumber;
    if (formValue === undefined || formValue === null || formValue === '') {
      allowValveNumber = undefined;
    } else if (typeof formValue === 'boolean') {
      allowValveNumber = formValue;
    } else if (formValue && formValue.get && typeof formValue.get === 'function') {
      const value = formValue.get('value');
      allowValveNumber = value === '' ? undefined : value;
    } else if (formValue && formValue.value !== undefined) {
      allowValveNumber = formValue.value === '' ? undefined : formValue.value;
    }

    return {
      categoryName: fittingCategoryFilterForm(state, 'categoryName'),
      symbolFile: fittingCategoryFilterForm(state, 'symbolFile'),
      allowValveNumber
    };
  },
  (isFiltering, fittingCategories, query) => {
    return isFiltering
      ? fittingCategories.filter((fc) => {
        const matchesCategoryName = !query.categoryName || fc.categoryName.toLowerCase().includes(query.categoryName.toLowerCase());
        const matchesSymbolFile = !query.symbolFile || fc.symbolFile === query.symbolFile;

        // Handle three cases:
        // 1. No filter selected (undefined) - show all
        // 2. Filter is true - show only true
        // 3. Filter is false - show only false
        const matchesAllowValveNumber = query.allowValveNumber === undefined || fc.allowValveNumber === query.allowValveNumber;

        return matchesCategoryName && matchesSymbolFile && matchesAllowValveNumber;
      }).toList()
      : fittingCategories.toList();
  }
);

export const selectUnfilteredFittingCategoriesCount = () => createSelector(
  selectNormalizedFittingCategories(),
  (fittingCategories) => fittingCategories.toList().size
);

export const selectUnarchivedFittingCategoryOptions = () => createSelector(
  selectNormalizedFittingCategories(),
  cats => cats.filter(cat => !cat.archivedFlag)
);