import LayerStateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const layerStateFilterForm = formValueSelector(
  `filters.${EK.LAYER_STATES.state}`
);

export const selectNormalizedLayerStates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.LAYER_STATES.state)
  );

export const selectNormalizedLayerStatesList = () =>
  createSelector(selectNormalizedLayerStates(), (layerStates) =>
    layerStates.toList()
  );

export const selectCurrentFilteredLayerStates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedLayerStates(),
    state => ({
      name: layerStateFilterForm(state, 'name'),
      description: layerStateFilterForm(state, 'description')
    }),
    (isFiltering, layerStates, query) =>
      isFiltering
        ? layerStates.filter((ls) => ls.doesMatchQuery(query)).toList()
        : layerStates.toList()
  );

export const selectCurrentLayerStatesCount = () =>
  createSelector(
    selectNormalizedLayerStates(),
    (layerStates) => (layerStates && layerStates.toList() && layerStates.toList().size) || 0
  );

export const selectDenormalizedLayerStatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.LAYER_STATES.state),
      [LayerStateSchema],
      entities
    )
  );
