import { createSelector } from 'reselect';

import { selectNormalizedModelTemplates } from '../../../../../entities/Standardize/ModelTemplates/selectors';

export const selectCurrentModelTemplate = () =>
  createSelector(
    selectNormalizedModelTemplates(),
    (state, ownProps) => ownProps.modelTemplateId,
    (modelTemplates, modelTemplateId) =>
      modelTemplates.get(modelTemplateId)
  );
