import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { StandardizeModuleSimplifiedRepsPath } from '../../../paths';

export default function SimplifiedRepsBreadcrumb() {
  return (
    <BreadcrumbTemplate
      title={StandardizeModuleSimplifiedRepsPath.defaultTitle}
    />
  );
}