import EntityRecord, { FILTERS, VALIDATORS } from '../../../modules/entities/model';

export default class Template extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.name),
    validateError: 'A valid name must be present',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  status: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  createdAt: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.DATE,
  },
  updatedAt: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.DATE,
  }
}) {
  get primary() {
    return this.name;
  }

  get primaryPlaceholder() {
    return 'No Name';
  }

  get secondary() {
    return this.description;
  }

  get secondaryPlaceholder() {
    return 'No Description';
  }
}