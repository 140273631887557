import { createSelector } from 'reselect';
import {
  getFormValues,
  getFormInitialValues,
} from 'redux-form/immutable';
import { Map } from 'immutable';

import EK from '../../../../../entities/keys';

import { selectNormalizedNonCustomConfigSettingCategoriesList } from '../../../../../entities/Synchronize/ConfigSettingCategories/selectors';

import { selectNormalizedConfigSettings } from '../../../../../entities/Synchronize/ConfigSettings/selectors';

import { selectNormalizedConfigProfiles } from '../../../../../entities/Synchronize/ConfigProfiles/selectors';

export const selectConfigProfileEditorModalIsReady = () => state => state.getIn([`${EK.CONFIG_SETTINGS.state}Modal`, 'isReady']);

export const selectCurrentConfigProfile = () => createSelector(
  selectNormalizedConfigProfiles(),
  (_, props) => props.configProfileId,
  (configProfiles, id) => configProfiles.get(id)
);

export const selectConfigProfileCategories = () => createSelector(
  selectNormalizedNonCustomConfigSettingCategoriesList(),
  selectNormalizedConfigSettings(),
  getFormValues(EK.CONFIG_SETTINGS.state),
  getFormInitialValues(EK.CONFIG_SETTINGS.state),
  (categories, settings, values, initialValues) => {
    let totalValuesCount = 0;
    let totalEditedValuesCount = 0;
    if (values && initialValues) {
      const categoriesCounts = values.reduce((categoriesSettingsMap, value, settingId) => {
        const categoryId = settings.getIn([settingId, [EK.CONFIG_SETTING_CATEGORIES.single]]);
        const count = categoriesSettingsMap.get(categoryId, { valuesCount: 0, editedValuesCount: 0 });

        totalValuesCount += 1;

        if (settings.has(settingId) && settings.get(settingId).isValueEditedAgainst(value, initialValues.get(settingId))) {
          totalEditedValuesCount += 1;
          return categoriesSettingsMap.set(categoryId, { ...count, editedValuesCount: count.editedValuesCount + 1 });
        } else {
          if (initialValues.has(settingId)) {
            return categoriesSettingsMap.set(categoryId, { ...count, valuesCount: count.valuesCount + 1 });
          } else {
            return categoriesSettingsMap;
          }
        }
      }, Map());

      return {
        categories: categories.map(category =>
          category
            .set('valuesCount', categoriesCounts.getIn([category.id, 'valuesCount'], 0))
            .set('editedValuesCount', categoriesCounts.getIn([category.id, 'editedValuesCount'], 0))
        ),
        totalValuesCount,
        totalEditedValuesCount,
      };
    } else {
      return { categories, totalValuesCount, totalEditedValuesCount };
    }
  }
);