import EntityRecord, { VALIDATORS, FILTERS } from '../../../modules/entities/model';

export const ALLOWANCES = {
  STOCKNO: /^(.{0,255})$/,
  MCCS_CODE: /^(.{0,255})$/,
};

class FittingInstance extends EntityRecord({
  cadModelName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  schedule: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'scheduleId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.schedule),
    validateError: 'A valid schedule must be present',
    filter: FILTERS.ENTITY,
  },
  creoValidated: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  fittingCode: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
  },
  port0Size: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_0SizeId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.port0Size),
    validateError: 'A Port 0 Size is required',
    filter: FILTERS.ENTITY,
  },
  port0EndType: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_0EndTypeId',
    filter: FILTERS.ENTITY,
  },
  port1Size: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_1SizeId',
    validate: (entity) => {
      if (VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCode)) {
        const endPortCount = entity.fittingCode.endCount;
        const changesSize = entity.fittingCode.changesSize;

        if (endPortCount === 2) {
          return (
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port1Size) &&
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port0Size) &&
            (changesSize || entity.port0Size.id === entity.port1Size.id)
          );
        } else if (endPortCount === 3) {
          return (
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port1Size) &&
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port0Size) &&
            entity.port0Size.id === entity.port1Size.id
          );
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validateError: (entity) => {
      if (VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCode)) {
        const endPortCount = entity.fittingCode.endCount;
        const changesSize = entity.fittingCode.changesSize;

        if (endPortCount === 3 || !changesSize) {
          return 'Port 1 Size is required and must match Port 0 Size';
        } else {
          return 'Port 1 Size is required';
        }
      } else {
        return 'Data was not loaded correctly and the Fitting Code is missing';
      }
    },
    filter: FILTERS.ENTITY,
  },
  port1EndType: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_1EndTypeId',
    filter: FILTERS.ENTITY,
  },
  port2Size: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_2SizeId',
    validate: (entity) => {
      if (VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCode)) {
        const endPortCount = entity.fittingCode.endCount;
        const changesSize = entity.fittingCode.changesSize;

        if (endPortCount === 3) {
          return (
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port1Size) &&
            VALIDATORS.IS_REQUIRED_ENTITY(entity.port2Size) &&
            (changesSize || entity.port1Size.id === entity.port2Size.id)
          );
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    validateError: (entity) => {
      if (VALIDATORS.IS_REQUIRED_ENTITY(entity.fittingCode)) {
        const changesSize = entity.fittingCode.changesSize;

        if (!changesSize) {
          return 'Port 2 Size is required and must match Port 1 Size';
        } else {
          return 'Port 2 Size is required';
        }
      } else {
        return 'Data was not loaded correctly and the Fitting Code is missing';
      }
    },
    filter: FILTERS.ENTITY,
  },
  port2EndType: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'port_2EndTypeId',
    filter: FILTERS.ENTITY,
  },
  stockno: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.stockno || ALLOWANCES.STOCKNO.test(entity.stockno),
    validateError:
      'Stockno must either be empty or be no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  mccsCode: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.mccsCode || ALLOWANCES.MCCS_CODE.test(entity.mccsCode),
    validateError:
      'MCCS Code must either be empty or be no longer than 255 characters',
    filter: FILTERS.STRING,
  },
  fittingFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  fittingFamilyId: {
    value: null,
    handleCopy: true,
  },
  selectionName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  setEndPortSize(sizeField, value) {
    const endCount = this.getIn(['fittingCode', 'endCount']);
    const changesSize = this.getIn(['fittingCode', 'changesSize']);

    if (endCount === 2 && !changesSize) {
      return this.set('port0Size', value).set('port1Size', value);
    } else if (endCount === 3) {
      if (changesSize) {
        if (sizeField === 'port0Size') {
          return this.set('port0Size', value).set('port1Size', value);
        } else if (sizeField === 'port2Size') {
          return this.set('port2Size', value);
        } else {
          return this;
        }
      } else {
        return this.set('port0Size', value)
          .set('port1Size', value)
          .set('port2Size', value);
      }
    } else {
      return this.set(sizeField, value);
    }
  }
}

export default FittingInstance;
