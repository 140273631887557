import AppearanceStateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const appearanceStateFilterForm = formValueSelector(
  `filters.${EK.APPEARANCE_STATES.state}`
);

export const selectNormalizedAppearanceStates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.APPEARANCE_STATES.state)
  );

export const selectNormalizedAppearanceStatesList = () =>
  createSelector(selectNormalizedAppearanceStates(), (appearanceStates) =>
    appearanceStates.toList()
  );

export const selectCurrentFilteredAppearanceStates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedAppearanceStates(),
    state => ({
      name: appearanceStateFilterForm(state, 'name'),
      description: appearanceStateFilterForm(state, 'description')
    }),
    (isFiltering, appearanceStates, query) =>
      isFiltering
        ? appearanceStates.filter((as) => as.doesMatchQuery(query)).toList()
        : appearanceStates.toList()
  );

export const selectCurrentAppearanceStatesCount = () =>
  createSelector(
    selectNormalizedAppearanceStates(),
    (appearanceStates) => (appearanceStates && appearanceStates.toList() && appearanceStates.toList().size) || 0
  );

export const selectDenormalizedAppearanceStatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.APPEARANCE_STATES.state),
      [AppearanceStateSchema],
      entities
    )
  );
