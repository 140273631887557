import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.PROJECTS.state;

export const fetchAllProjects = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualProjectTypes = generateAPHTypes(resource, 'FETCH');

export const createProjectTypes = generateAPHTypes(resource, 'CREATE');

export const copyProjectTypes = generateAPHTypes(resource, 'COPY');

export const editProjectTypes = generateAPHTypes(resource, 'EDIT');

export const archiveProjects = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveProjects = generateAPHTypes(resource, 'UNARCHIVE');

export const saveIndividualProjectSpecs = generateAPHTypes(resource, 'SAVE_SPECS');

export const deleteProjectTypes = generateAPHTypes(resource, 'DELETE');