import mixpanel from 'mixpanel-browser';

export const getEnvNodeEnv = () => {
  const NODE_ENV = String(process.env.NODE_ENV);
  return NODE_ENV;
};

export const getEnvMixpanelToken = () => {
  const MIXPANEL_TOKEN = String(process.env.REACT_APP_MIXPANEL_TOKEN || '');
  return MIXPANEL_TOKEN;
};

export const getEnvMixpanelEnvironment = () => {
  const MIXPANEL_ENVIRONMENT = String(process.env.REACT_APP_MIXPANEL_ENVIRONMENT || 'development');
  return MIXPANEL_ENVIRONMENT;
};

export const getEnvAppVersion = () => {
  const APP_VERSION = String(process.env.REACT_APP_VERSION || 'unknown');
  return APP_VERSION;
};

export const initMixpanel = () => {

  if (getEnvNodeEnv() === 'development') {
    console.log('--------------------------------');
    console.log('Initializing Mixpanel');
    console.log('- NODE_ENV:', getEnvNodeEnv());
    console.log('- Token:', getEnvMixpanelToken());
    console.log('- Environment:', getEnvMixpanelEnvironment());
    console.log('- App Version:', getEnvAppVersion());
    console.log('--------------------------------');
  }

  if (!isMixpanelConfigured()) {
    console.warn('Mixpanel is disabled - Token not found');
    return false;
  }

  try {
    // Initialize with optimal configuration
    mixpanel.init(getEnvMixpanelToken(), {
      // debug: NODE_ENV === 'development',
      // track_pageview: false, // We handle this manually for more control
      // persistence: 'localStorage',
      // ignore_dnt: false, // Respect Do Not Track
      // batch_requests: true, // Batch multiple events
      // secure_cookie: true,
      // property_blacklist: ['$current_url', '$initial_referrer', '$referrer'], // We track these manually
      // disable_persistence: true, // Disable automatic persistence to prevent device ID generation
      // disable_cookie: true, // Disable cookies to prevent device ID generation
      // cross_subdomain_cookie: false // Prevent cross-subdomain tracking
    });

    // Set global properties that will be sent with every event
    mixpanel.register({
      environment: getEnvMixpanelEnvironment(),
      platform: 'web',
      host: window.location.hostname,
      app_version: getEnvAppVersion(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      user_agent: navigator.userAgent,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      language: navigator.language,
      language_code: navigator.language.split('-')[0],
      language_region: navigator.language.split('-')[1],
      screen_color_depth: window.screen.colorDepth,
      screen_pixel_depth: window.screen.pixelDepth,
      screen_orientation: window.screen.orientation,
      screen_pixel_ratio: window.devicePixelRatio,
    });

    return true;

  } catch (e) {
    console.error('Mixpanel initialization failed:', e);
    return false;
  }
};

// Helper function to check if Mixpanel is available
const isMixpanelInstalled = () => {
  return mixpanel && typeof mixpanel.track === 'function';
};

// Helper function to check if Mixpanel is configured
const isMixpanelConfigured = () => {
  if (!isMixpanelInstalled()) {
    return false;
  }

  if (!getEnvMixpanelToken() || getEnvMixpanelToken().trim() === '') {
    return false;
  }

  return true;
};

// Enhanced tracking with error handling and validation
export const track = (eventName, properties = {}) => {
  if (!isMixpanelConfigured() || !eventName) {
    return;
  }

  try {
    // Add timestamp and common properties if not present
    const enrichedProperties = {
      timestamp: new Date().toISOString(),
      url: window.location.href,
      path: window.location.pathname,
      referrer: document.referrer,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      ...properties
    };

    mixpanel.track(eventName, enrichedProperties);

  } catch (e) {
    console.error(`Failed to track event ${eventName}:`, e);
  }
};

// Enhanced user identification with error handling
export const identify = (userId, userProperties = {}) => {
  if (!isMixpanelConfigured() || !userId) {
    console.debug('Mixpanel is not available or user ID is missing');
    return;
  }

  try {
    const previousId = mixpanel.get_distinct_id();

    // First reset the identity to clear any existing state
    mixpanel.reset();

    // Set the distinct_id immediately
    mixpanel.register({ distinct_id: userId });

    // Identify the user
    mixpanel.identify(userId);

    // If this was a signup/first login (previous ID was anonymous), create an alias
    if (previousId && previousId !== userId && previousId.includes('$device')) {
      mixpanel.alias(userId, previousId);
    }

    // Set initial user properties
    mixpanel.people.set_once({
      $distinct_id: userId,
      user_id: userId,
      first_seen: new Date().toISOString()
    });

    // Set/update user properties
    const commonProperties = {
      $last_seen: new Date().toISOString(),
      $browser: navigator.userAgent,
      $browser_version: navigator.appVersion,
      $os: navigator.platform,
      $screen_height: window.screen.height,
      $screen_width: window.screen.width,
      ...userProperties,
      distinct_id: userId
    };

    setUserProperties(commonProperties);

    // Track identification
    track('User Identified', {
      distinct_id: userId,
      user_id: userId,
      is_first_login: previousId && previousId !== userId && previousId.includes('$device')
    });

  } catch (e) {
    console.error('Failed to identify user:', e);
  }
};

// Enhanced user properties setting with validation
export const setUserProperties = (properties = {}) => {
  if (!isMixpanelConfigured() || !properties || Object.keys(properties).length === 0) {
    return;
  }

  try {
    // Filter out any null or undefined values
    const validProperties = Object.entries(properties).reduce((acc, [key, value]) => {
      if (value != null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(validProperties).length > 0) {
      mixpanel.people.set(validProperties);
    }
  } catch (e) {
    console.error('Failed to set user properties:', e);
  }
};

// Increment a numeric property
export const incrementProperty = (property, value = 1) => {
  if (!isMixpanelConfigured() || !property) {
    return;
  }

  try {
    mixpanel.people.increment(property, value);
  } catch (e) {
    console.error(`Failed to increment property ${property}:`, e);
  }
};

// Reset user data (useful for logout)
export const reset = () => {
  if (!isMixpanelConfigured()) {
    return;
  }

  try {
    // Track logout before resetting
    track('User Logged Out');

    // Reset the Mixpanel state
    mixpanel.reset();
  } catch (e) {
    console.error('Failed to reset Mixpanel:', e);
  }
};