import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { selectCurrentNoteProfile } from './selectors';

import {
  StandardizeModuleNoteProfilesEditPath,
} from '../../../paths';

const mapStateToProps = createSelector(
  selectCurrentNoteProfile(),
  profile => ({ title: profile && profile.name || StandardizeModuleNoteProfilesEditPath.defaultTitle })
);

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(BreadcrumbTemplate);
