import ExplodeStateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const explodeStateFilterForm = formValueSelector(
  `filters.${EK.EXPLODE_STATES.state}`
);

export const selectNormalizedExplodeStates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.EXPLODE_STATES.state)
  );

export const selectNormalizedExplodeStatesList = () =>
  createSelector(selectNormalizedExplodeStates(), (explodeStates) =>
    explodeStates.toList()
  );

export const selectCurrentFilteredExplodeStates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedExplodeStates(),
    state => ({
      name: explodeStateFilterForm(state, 'name'),
      description: explodeStateFilterForm(state, 'description')
    }),
    (isFiltering, explodeStates, query) =>
      isFiltering
        ? explodeStates.filter((es) => es.doesMatchQuery(query)).toList()
        : explodeStates.toList()
  );

export const selectCurrentExplodeStatesCount = () =>
  createSelector(
    selectNormalizedExplodeStates(),
    (explodeStates) => (explodeStates && explodeStates.toList() && explodeStates.toList().size) || 0
  );

export const selectDenormalizedExplodeStatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.EXPLODE_STATES.state),
      [ExplodeStateSchema],
      entities
    )
  );