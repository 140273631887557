import React from 'react';
import Text from '../../common/Text';
import styled from 'styled-components';

interface StyledTextProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const StyledText = styled(Text)<StyledTextProps>``;

/**
 * Renders the header for a port column in a grid.
 *
 * @param {string} hyperscript - The hyperscript value for the header.
 * @param {string} header - The header text.
 * @returns {JSX.Element} The rendered header component.
 */
function PortColumnHeader (hyperscript: string, header: string): JSX.Element {
  return (
    <>
      <StyledText style={{ fontSize: '.7rem' }}>{hyperscript}</StyledText>
      <StyledText>{header}</StyledText>
    </>
  );
}

export default PortColumnHeader;