import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { StandardizeModuleExplodeStatesPath } from '../../../paths';

export default function ExplodeStatesBreadcrumb() {
  return (
    <BreadcrumbTemplate
      title={StandardizeModuleExplodeStatesPath.defaultTitle}
    />
  );
}