import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import space from '../../../../assets/themes/base/space';
import fontSizes from '../../../../assets/themes/base/fontSizes';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';

import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Text from '../../../../components/common/Text';

import Tooltip, { CONTAINERS } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import { toggleShowArchived } from '../../../../modules/query/actions';
import { selectIsShowingArchived } from '../../../../modules/query/selectors';

const Widget = styled(Flex)`
  overflow: hidden;
  background: ${colors.white};
  flex-grow: 1;
  flex-shrink: 0;
  cursor: pointer;
`;

const WidgetText = styled(Text)`
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: ${space[2]};
  padding-right: ${space[2]};
`;

const WidgetToggle = styled(Flex)`
  padding: ${space[3]};
  font-size: ${fontSizes[2]};
`;

const ShowArchivedWidget = () => {
  const dispatch = useDispatch();
  const toggle = useCallback(() => dispatch(toggleShowArchived()), []);
  const memoizedSelectIsShowingArchived = useMemo(selectIsShowingArchived, []);
  const showingArchived = useSelector(state => memoizedSelectIsShowingArchived(state));

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'right' });
  const isHovering = useIsHovering(reference);

  return (
    <Flex
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      mb={5}
      pr={3}
      pl={3}
    >
      <Widget
        onClick={toggle}
        flexDirection='row'
        justifyContent='center'
        alignItems='center'
        border={1}
        $borderRadius={1}
      >
        <WidgetToggle
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          $borderRadii={[1,0,0,1]}
        >
          { showingArchived ? <Icon name='checkbox' color='primary.4' /> : <Icon name='checkbox-outline' color='gray.6' /> }
        </WidgetToggle>

        <WidgetText
          color='gray.7'
          fontWeight={600}
        >
          Show Archived
        </WidgetText>
      </Widget>

      <Flex
        ref={reference}
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        p={3}
      >
        <Icon name='help' color='gray.7' />
      </Flex>

      {
        isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='small'>
            <TooltipBody>
              <TooltipSection small inverse>
                Archived entities are hidden from view by default. If this toggle is on, then all entities will be visible.
              </TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Flex>
  );
};

export default ShowArchivedWidget;

