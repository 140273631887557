import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { BrowserTracing } from '@sentry/tracing';
import { ApiError } from '../modules/api/utils/errors';

export const initSentry = () => {

  // Variables for Sentry
  const NODE_ENV = String(process.env.NODE_ENV);
  const SENTRY_WEB_APP_DSN = String(process.env.REACT_APP_SENTRY_WEB_APP_DSN || '');
  const SENTRY_ENVIRONMENT = String(process.env.REACT_APP_SENTRY_ENVIRONMENT || 'development');

  if (NODE_ENV === 'development') {
    console.log('--------------------------------');
    console.log('Initializing Sentry');
    console.log('- NODE_ENV:', NODE_ENV);
    console.log('- DSN:', SENTRY_WEB_APP_DSN);
    console.log('- Environment:', SENTRY_ENVIRONMENT);
    console.log('--------------------------------');
  }

  if (SENTRY_WEB_APP_DSN && SENTRY_WEB_APP_DSN.trim() !== '') {
    try {
      Sentry.init({
        dsn: SENTRY_WEB_APP_DSN,
        environment: SENTRY_ENVIRONMENT,
        integrations: [
          new BrowserTracing({
            tracePropagationTargets: [
              'localhost',
              'app-dev.cadactive.com', 'app-staging.cadactive.com', 'app.cadactive.com',
              'api-dev.cadactive.com', 'api-staging.cadactive.com', 'api.cadactive.com',
              'cadactive.com',
            ],
          }),
          new Replay({
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        debug: NODE_ENV === 'development',
        beforeSend(event, hint) {
          // Check if error is an API error
          const error = hint?.originalException;
          if (error instanceof ApiError) {
            // Skip 401 Unauthorized errors
            if (error.status === 401) {
              return null;  // Returning null prevents the event from being sent
            }
          }
          return event;
        },
      });
    } catch (e) {
      console.error('Sentry initialization failed:', e);
    }
  } else {
    console.warn('Sentry is disabled - DNS not found');
  }
};
