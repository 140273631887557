import React from 'react';
import styled, { css } from 'styled-components';

import transitions from '../../../../assets/themes/base/transitions';
import space from '../../../../assets/themes/base/space';
import fontSizes from '../../../../assets/themes/base/fontSizes';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';

const BaseContainer = styled(Box)`
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 20rem;
  top: 0;
  bottom: 0;
  left: 0;

  transition: left ${transitions.speed.fast} ${transitions.type.inOut};
  will-change: left;

  ${
  ({ $isMinimized, $isFiltering }) => $isMinimized && !$isFiltering && css`
      left: -19rem;
    `
}

  ${
  ({ $isMinimized, $isHovering }) => $isMinimized && $isHovering && css`
      left: 0;
    `
}
`;

const Inner = styled(Flex)`
  flex-direction: row;
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;

  transition: padding-top ${transitions.speed.fast} ${transitions.type.inOut},
    padding-bottom ${transitions.speed.fast} ${transitions.type.inOut};

  ${
  ({ isMinimized }) => isMinimized && css`
      padding-top: ${space[4]};
      padding-bottom: ${space[4]};
    `
}
`;

const Header = styled(Box)`
  margin-bottom: ${space[3]};
  padding: ${space[5]} ${space[3]};

  flex-grow: 0;
  flex-shrink: 0;
`;

const Body = styled(Box)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  overflow-x: hidden;
  overflow-y: auto;
`;

const Footer = styled(Box)`
  padding: ${space[3]} ${space[4]};
  flex-grow: 0;
  flex-shrink: 0;
`;

const ToggleWatcherContainer = styled(Flex)`
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: ${fontSizes[3]};
  top: 0;
  bottom: 0;
  left: 100%;
  width: 4rem;
  margin-left: -2rem;

  transition: opacity 0.1s 0.1s ease;
  opacity: 0;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
`;

const ToggleWatcher = ({ isMinimized, toggle }) => (
  <ToggleWatcherContainer>
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      onClick={toggle}
      cursor='pointer'
      bg='white'
      border={2}
      $borderRadius={1}
      height='2rem'
      width='2rem'
    >
      <Icon
        color='primary.4'
        name={isMinimized ? 'chevron-right' : 'chevron-left'}
      />
    </Flex>
  </ToggleWatcherContainer>
);

const BaseHoverWatcher = styled(Box)`
  position: absolute;
  height: 100vh;
  width: 0;
  top: 0;
  bottom: 0;
  left: 0;

  transition: width ${transitions.speed.fast} ${transitions.type.inOut};

  ${
  ({ isActive }) => isActive && css`
      width: 23rem;
    `
}
`;

const Container = React.forwardRef((props, ref) => <BaseContainer ref={ref} $isMinimized={props.isMinimized} $isFiltering={props.isFiltering} $isHovering={props.isHovering} { ...props } />);

const HoverWatcher = React.forwardRef((props, ref) => <BaseHoverWatcher ref={ref} { ...props } />);

const Sidebar = {
  Container,
  Inner,
  Header,
  Body,
  Footer,
  ToggleWatcher,
  HoverWatcher,
};

export default Sidebar;
