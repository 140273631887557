import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import PipingCatalogSchema from './schema';

export const selectPipingCatalogUnitSystemId = () => createSelector(
  selectEntities(),
  entities => {
    if (entities.has(EK.PIPING_CATALOGS.state)) {
      const currentPipingCatalog = entities.get(EK.PIPING_CATALOGS.state).first();
      return (currentPipingCatalog || {}).unitSystem;
    } else {
      return null;
    }
  }
);

export const selectPipingCatalogUnitSystem = () => createSelector(
  selectEntities(),
  entities => {
    if (entities && entities.get(EK.PIPING_CATALOGS.state)) {
      const pipingCatalog = denormalize(entities.get(EK.PIPING_CATALOGS.state).first(), PipingCatalogSchema, entities);
      return pipingCatalog && pipingCatalog.unitSystem;
    }
  }
);

export const selectPipingCatalogUnitSystemOption = () => createSelector(
  selectPipingCatalogUnitSystem(),
  PipingCatalogUnitSystem => PipingCatalogUnitSystem && PipingCatalogUnitSystem.toOption() || {}
);
