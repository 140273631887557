import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { SynchronizeModuleMapkeysPath } from '../../../paths';

export default function SynchronizeMapkeysBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={SynchronizeModuleMapkeysPath.defaultTitle}></BreadcrumbTemplate>
  );
}
