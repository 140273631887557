import React from 'react';
import styled from 'styled-components';

import CadactiveHelmet from '../../../components/common/CadactiveHelmet';

import Box from '../../../components/common/Box';
import Flex from '../../../components/common/Flex';

const ExternalPageContainer = styled(Box)`
  width: 30rem;
  margin: 0 auto;
`;

const ExternalPage = ({ title, children }) => (
  <Flex
    position='absolute'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    top='0px'
    right='0px'
    bottom='0px'
    left='0px'
  >
    <CadactiveHelmet title={title} />

    <ExternalPageContainer>
      { children }
    </ExternalPageContainer>
  </Flex>
);

export default ExternalPage;
