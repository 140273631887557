import EntityRecord from '../../../modules/entities/model';

export const getUnitLength = (listOfUnitValues, currentUnitSystem) => {
  // this needs to be rewritten, the method is repeated for the PipeDialog. It just needs to be untangled from the state
  // units are not stored by family, they are stored by unit id, so we need to check units/unitSystem and then get the id from there
  if (typeof (listOfUnitValues) == 'number') {
    // edited units are stored as a number, so there is no need to search.
    return listOfUnitValues;
  }
  // these two prevent "no unit system" and "no unit Value" errors
  const currentUnitValue = listOfUnitValues && listOfUnitValues.find((item) => item.id == currentUnitSystem.unitLength.id);

  return currentUnitValue &&
    currentUnitValue.value ||
    null;
};

export default class UnitSystem extends EntityRecord({
  name: {
    value: '',
    handleCopy: entity => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
  },
  unitLength: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  unitWeight: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  unitDensity: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
}) {
  get primary() {
    return this.name;
  }

  get primaryPlaceholder() {
    return 'No Name';
  }

  get secondary() {
    return `${this.unitLength?.name || ''}, ${this.unitWeight?.name || ''}, ${this.unitDensity?.name || ''}`;
  }

  get secondaryPlaceholder() {
    return '--';
  }

  toOption() {
    return {
      id: this.id,
      value: this.id,
      label: this.primary || this.primaryPlaceholder,
      primary: this.primary || this.primaryPlaceholder,
      secondary: this.secondary || this.secondaryPlaceholder,
      unitLength: this.unitLength,
      unitWeight: this.unitWeight,
      unitDensity: this.unitDensity,
    };
  }
}

export const UNIT_SYSTEM_FILTERS = {
  DEFAULT: unitSystem => unitSystem.name !== null && unitSystem.name !== undefined && unitSystem.name.toLowerCase() === 'mmks' || unitSystem.name.toLowerCase() === 'ips',
};
