import { take, put, fork, select } from 'redux-saga/effects';

import { selectIsFetching } from '../../../modules/utility/selectors';
import { signout } from '../../../modules/auth/sagas';
import { isInitialLoadComplete } from '../../Dashboard/actions';

import { processFetchAllTemplates } from '../../../entities/Reporting/Templates/actions';

export function* watchLoadReportingModuleSaga() {
  try {
    yield put(processFetchAllTemplates());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

export default function* main() {
  yield fork(watchLoadReportingModuleSaga);
}