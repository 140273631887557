import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import transitions from '../../../assets/themes/base/transitions';
import fontSizes from '../../../assets/themes/base/fontSizes';

import Box from '../Box';

const Label = styled(Box).attrs(({ primary, secondary, error, success, ...props }) => ({
  $primary: primary,
  $secondary: secondary,
  $error: error,
  $success: success,
  ...props,
}))`
  display: inline-block;
  font-size: ${fontSizes[0]};
  font-weight: 600;
  line-height: 1.25;
  height: 1.5rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-bottom: 0.25rem;
  padding-right: 0.5rem;
  user-select: none;

  border-radius: ${radii[1]};
  transition: background-color ${transitions.speed.fast},
              border-color ${transitions.speed.fast},
              color ${transitions.speed.fast};

  ${({ $primary, disabled }) => $primary && (disabled && css`
      background-color: ${colors.primary[0]};
      border: 1px solid ${colors.primary[2]};
      color: ${colors.primary[2]};
    ` || css`
      background-color: ${colors.primary[1]};
      border: 1px solid ${colors.primary[4]};
      color: ${colors.primary[6]};
    `)
}

  ${({ $error, disabled }) => $error && (disabled && css`
      background-color: ${colors.error[0]};
      border: 1px solid ${colors.error[2]};
      color: ${colors.error[2]};
    ` || css`
      background-color: ${colors.error[1]};
      border: 1px solid ${colors.error[4]};
      color: ${colors.error[6]};
    `)
}

  ${({ $success, disabled }) => $success && (disabled && css`
      background-color: ${colors.success[0]};
      border: 1px solid ${colors.success[2]};
      color: ${colors.success[2]};
    ` || css`
      background-color: ${colors.success[1]};
      border: 1px solid ${colors.success[4]};
      color: ${colors.success[6]};
    `)
}

  ${({ $secondary, disabled }) => $secondary && (disabled && css`
      background-color: ${colors.gray[0]};
      border: 1px solid ${colors.gray[2]};
      color: ${colors.gray[2]};
    ` || css`
      background-color: ${colors.gray[1]};
      border: 1px solid ${colors.gray[4]};
      color: ${colors.gray[6]};
    `)
}
`;

export default React.memo(Label);
