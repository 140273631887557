import SimplifiedRepSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const simplifiedRepFilterForm = formValueSelector(
  `filters.${EK.SIMPLIFIED_REPS.state}`
);

export const selectNormalizedSimplifiedReps = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.SIMPLIFIED_REPS.state)
  );

export const selectNormalizedSimplifiedRepsList = () =>
  createSelector(selectNormalizedSimplifiedReps(), (simplifiedReps) =>
    simplifiedReps.toList()
  );

export const selectCurrentFilteredSimplifiedReps = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedSimplifiedReps(),
    state => ({
      name: simplifiedRepFilterForm(state, 'name'),
      description: simplifiedRepFilterForm(state, 'description'),
      creoAction: simplifiedRepFilterForm(state, 'creoAction'),
    }),
    (isFiltering, simplifiedReps, query) =>
      isFiltering
        ? simplifiedReps.filter((sr) => sr.doesMatchQuery(query)).toList()
        : simplifiedReps.toList()
  );

export const selectCurrentSimplifiedRepsCount = () =>
  createSelector(
    selectNormalizedSimplifiedReps(),
    (simplifiedReps) => (simplifiedReps && simplifiedReps.toList() && simplifiedReps.toList().size) || 0
  );

export const selectDenormalizedSimplifiedRepsLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.SIMPLIFIED_REPS.state),
      [SimplifiedRepSchema],
      entities
    )
  );