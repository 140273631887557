import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Box from '../../../../../components/common/Box';

import ModalTitle from '../../../../../components/modal/templates/ModalTitle';

import PipingCatalogUnitSystemForm from '../../../../form/templates/Piping/PipingCatalogUnitSystemForm';

import { selectIsPerformingInModal } from '../../../../../modules/utility/selectors';

import { processUpdatePipingCatalog } from '../../../../../entities/Piping/Catalogs/actions';

import injectSaga from '../../../../../utils/sagas/injectSaga';

import saga from './sagas';

const PipingCatalogUnitSystemModal = ({ isPerforming, updateDefaultUnitSystem }) => {
  return (
    <Box>
      <ModalTitle><strong>Edit</strong> Default Unit System</ModalTitle>

      <PipingCatalogUnitSystemForm
        isPerforming={isPerforming}
        onRDXSubmit={updateDefaultUnitSystem}
      />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
});

const enhance = compose(
  injectSaga({ key: 'pipingCatalogUnitSystemModal', saga }),
  connect(
    mapStateToProps,
    { updateDefaultUnitSystem: processUpdatePipingCatalog }
  ),
);

export default enhance(PipingCatalogUnitSystemModal);
