import React from 'react';
import styled from 'styled-components';
import space from '../../../assets/themes/base/space';

import Flex from '../../common/Flex';

export const Cell = styled(Flex)`
  flex-direction: column;

  padding: ${space[3]};
  position: relative;
  overflow: hidden;

  height: ${({ height }) => height || 'auto'};
  min-height: ${({ minHeight }) => minHeight || '6rem'};
`;

const BaseFormCell = React.forwardRef(({ bg, ...props }, ref) => (
  <Cell
    ref={ref}
    bg={bg || 'white'}
    {...props}
  />
));

export default BaseFormCell;