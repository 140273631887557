import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectNormalizedSettings } from '../../Settings/selectors';

import FittingInstanceSchema from './schema';
import EK from '../../keys';
import { newFittingInstancesArray } from './constants';

import { formValueSelector } from 'redux-form/immutable';

export const selectNormalizedFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.FITTING_INSTANCES.state)
);

export const selectDenormalizedFittingInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.FITTING_INSTANCES.state), [FittingInstanceSchema], entities).toList()
);

export const selectFittingInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.FITTING_INSTANCES.state), [FittingInstanceSchema], entities.delete(EK.FITTING_FAMILIES.state))
);

const fittingInstanceFilterForm = formValueSelector(`filters.${EK.FITTING_INSTANCES.state}`);

export const selectCurrentDenormalizedFilteredFittingInstances = (fittingFamilyId) => createSelector(
  selectNormalizedFittingInstances(),
  selectSidebarIsFiltering(),
  state => fittingInstanceFilterForm(state,
    'schedule',
    'cadModelName',
    'port0Size',
    'port1Size',
    'port2Size',
    'port0EndType',
    'port1EndType',
    'port2EndType',
    'selectionName',
    'stockno',
    'mccsCode'
  ),
  selectEntities(),
  (fittings, isFiltering, query, entities) => {
    const instances = !isFiltering ? fittings : fittings.toList().filter(instance => instance.doesMatchQuery(query));

    return denormalize(instances, [FittingInstanceSchema], entities.delete(EK.FITTING_FAMILIES.state)).toList().filter(item => item.fittingFamilyId == fittingFamilyId);
  }
);

export const selectCurrentFittingInstances = (fittingFamilyId) => createSelector(
  selectDenormalizedFittingInstances(),
  (fittings) => fittings.filter(item => item.fittingFamilyId == fittingFamilyId)
);

export const selectFittingInstanceCount = (fittingFamilyId) => createSelector(
  selectCurrentFittingInstances(fittingFamilyId),
  (instances) => instances && instances.size || 0
);

export const selectCurrentFilteredFittingInstances = (fittingFamilyId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentDenormalizedFilteredFittingInstances(fittingFamilyId),
  (isFiltering, instances) =>
    isFiltering ? instances.toArray() : instances.toArray().concat(newFittingInstancesArray)
);

const empty = { value: '...' };
export const selectPortNames = () => createSelector(
  selectNormalizedSettings(),
  settings => {
    return List([
      (settings.find(setting => setting.name === 'Fitting Inlet Feature Name') || empty).value,
      (settings.find(setting => setting.name === 'Fitting Outlet Feature Name') || empty).value,
      (settings.find(setting => setting.name === 'Fitting Branch Feature Name') || empty).value,
    ]);
  }
);
