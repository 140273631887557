import React, {useMemo} from 'react';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import Text from '../../../../components/common/Text';
import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import { ALLOWANCES } from '../../../../entities/Standardize/Notes/model';
import { Dropdown } from 'primereact/dropdown';
import { NOTE_TYPES_OPTIONS } from '../../../../entities/Standardize/Notes/model';
import { InputTextarea } from 'primereact/inputtextarea';

export function NotesDialog({ currentEditingRow, editable, handleEditedRowChange, isCreateDialogOpen = false, isCopyDialogOpen = false, isDialogOpen, openDeleteDialog = false, saveAction, cancelDialogs, validation }) {
  // no copy api method for this one
  const getDialogHeader = useMemo(() => {
    if (isCreateDialogOpen) {
      return 'Create Note';
    }
    return `Edit '${currentEditingRow.name}'`;
  }, [isDialogOpen]);

  return (
    <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={getDialogHeader} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
      <div>
        <label>Name</label>
        <InputText keyfilter={ALLOWANCES.NAME} value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
      </div>
      <div>
        <label>Note Type</label>
        <Dropdown
          optionLabel='label'
          value={currentEditingRow.noteType}
          options={NOTE_TYPES_OPTIONS}
          onChange={(e) => handleEditedRowChange(e.value, 'noteType')}
          filter
        />
      </div>
      <div>
        <label>Body</label>
        <InputTextarea value={currentEditingRow.noteBody} onChange={(e) => handleEditedRowChange(e.target.value, 'noteBody')} className={!validation.noteBody && 'p-invalid'} />
      </div>
      <div>
        <label>Description</label>
        <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')} />
      </div>
    </Dialog>
  );
}

export function DeleteNoteDialog({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) {

  return (
    <Dialog visible={editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Note '${currentEditingRow.name}'`} footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)} closable={false} >
      <div>
        <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
        <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
      </div>
    </Dialog>
  );
}