import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { track } from '../configuration/mixpanel';

const RouteTracker = () => {
  const location = useLocation();
  const currentUser = useSelector(state => state.getIn(['currentUser', 'data']));

  useEffect(() => {
    // Don't track if we're on an error page or loading state
    if (location.pathname.includes('/error') || location.pathname === '/loading') {
      return;
    }

    // Get the page title from the document
    const pageTitle = document.title || 'Unknown Page';

    // Build properties object with rich metadata
    const properties = {
      // Page Info
      path: location.pathname,
      search: location.search,
      hash: location.hash,
      page_title: pageTitle,
      url: window.location.href,
      referrer: document.referrer,

      // Technical Context
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
      user_agent: navigator.userAgent,
      timestamp: new Date().toISOString()
    };

    // Add user context if available (but don't try to identify)
    if (currentUser) {
      properties.user_id = currentUser.get('id');
      properties.user_email = currentUser.get('email');
      properties.user_role = currentUser.get('role');
      properties.organization_id = currentUser.getIn(['organization', 'id']);
      properties.organization_name = currentUser.getIn(['organization', 'name']);
    }

    // Track the page view with enhanced properties
    track('Page View', properties);
  }, [location, currentUser]);

  return null;
};

export default RouteTracker;