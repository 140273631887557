import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';
import selectEntities from '../../modules/entities/selectors';
import CurrentUserSchema from './schema';

export const selectNormalizedCurrentUser = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CURRENT_USER.state).first()
);

export const selectIsPendingForcedPasswordReset = () => createSelector(
  selectNormalizedCurrentUser(),
  currentUser => currentUser && currentUser.passwordResetRequired
);

export const selectCurrentUser = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.CURRENT_USER.state), [CurrentUserSchema], entities).first()
);

export const selectCurrentOrganization = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.organization
);

export const selectCurrentOrganizationId = () => createSelector(
  selectCurrentOrganization(),
  organization => organization.id
);

export const selectCurrentUserAllowedRoleAssignments = () => createSelector(
  selectCurrentUser(),
  currentUser => currentUser.allowedRoleAssignments
);
