import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const mapkeyFilterForm = formValueSelector(
  `filters.${EK.MAPKEYS.state}`
);

export const selectNormalizedMapkeys = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.MAPKEYS.state)
  );

export const selectNormalizedMapkeysList = () =>
  createSelector(selectNormalizedMapkeys(), (mapkeys) =>
    mapkeys.toList()
  );

export const selectCurrentFilteredMapkeys = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedMapkeys(),
    state => ({
      name: mapkeyFilterForm(state, 'name'),
      description: mapkeyFilterForm(state, 'description'),
      keystroke: mapkeyFilterForm(state, 'keystroke')
    }),
    (isFiltering, mapkeys, query) =>
      isFiltering
        ? mapkeys.filter((mapkey) => mapkey.doesMatchQuery(query)).toList()
        : mapkeys.toList()
  );

export const selectCurrentMapkeysCount = () =>
  createSelector(
    selectNormalizedMapkeys(),
    (mapkeys) => (mapkeys && mapkeys.toList() && mapkeys.toList().size) || 0
  );
