import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import NoteSchema from './schema';

export const selectNormalizedNotes = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.NOTES.state)
);
export const selectNormalizedNotesList = () => createSelector(
  selectNormalizedNotes(),
  notes => notes.toList()
);

export const selectNotes = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.NOTES.state), [NoteSchema], entities)
);

export const selectNotesCount = () => createSelector(
  selectNormalizedNotes(),
  notes => notes.toList().count()
);

const noteFilterForm = formValueSelector(`filters.${EK.NOTES.state}`);

export const selectCurrentFilteredNormalizedDraftNotes = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedNotes(),
  state => ({
    name: noteFilterForm(state, 'name'),
    noteBody: noteFilterForm(state, 'noteBody'),
    description: noteFilterForm(state, 'description'),
    noteType: noteFilterForm(state, 'noteType'),
  }),
  (isFiltering, notes, query) => {
    if (!isFiltering) return notes;

    return notes.filter(note => {
      if (!query) return true;

      // Text field filters
      if (query.name && !note.name?.toLowerCase().includes(query.name.toLowerCase())) return false;
      if (query.noteBody && !note.noteBody?.toLowerCase().includes(query.noteBody.toLowerCase())) return false;
      if (query.description && !note.description?.toLowerCase().includes(query.description.toLowerCase())) return false;

      // NoteType filter
      if (query.noteType) {
        const selectedType = query.noteType.get ? query.noteType.get('value') : query.noteType;
        if (selectedType && note.noteType !== selectedType) return false;
      }

      return true;
    });
  }
);

export const selectCurrentFilteredNotes = () => createSelector(
  selectCurrentFilteredNormalizedDraftNotes(),
  selectEntities(),
  (notes, entities) => denormalize(notes, [NoteSchema], entities)
);