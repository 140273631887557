import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link, withRouter } from 'react-router-dom';

import Box from '../../components/common/Box';
import Button from '../../components/common/Button';
import Flex from '../../components/common/Flex';
import Text from '../../components/common/Text';

import CadActiveTextLogoGraphic from '../../components/common/graphics/CadActiveTextLogoGraphic';
import AccessMissingGraphic from '../../components/common/graphics/AccessMissing';

import PasswordResetRequestForm from '../../containers/form/templates/PasswordResetRequestForm';

import ExternalPage from '../templates/ExternalPage';

import { UserIsNotAuthenticated } from '../../modules/auth/utils/routes';

import { selectIsPerforming } from '../../modules/utility/selectors';

import { selectPasswordResetRequestSubmitted } from '../../modules/auth/selectors';

import {
  processRequestPasswordReset,
} from '../../modules/auth/actions';

import theme from '../../assets/themes/theme';

import {
  LoginPath,
  RegisterPath,
} from '../../paths';

const PasswordResetRequest = ({ isPerforming, passwordResetRequestSubmitted, requestPasswordReset }) => (
  <ExternalPage title={passwordResetRequestSubmitted ? 'Password Reset Email Sent' : 'Forgot your password?'}>
    <Box width="20rem" ml="auto" mr="auto" mb={4}>
      <CadActiveTextLogoGraphic />
    </Box>

    <Box width='14rem' ml='auto' mr='auto' mb={7}>
      <AccessMissingGraphic
        backgroundColor={theme.colors.gray[5]}
        backgroundAccentColor={theme.colors.gray[4]}
        iconColor={passwordResetRequestSubmitted ? theme.colors.primary[4] : theme.colors.error[4]}
        iconAccentColor={passwordResetRequestSubmitted ? theme.colors.primary[6] : theme.colors.error[6]}
      />
    </Box>

    <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={7}>
      {
        passwordResetRequestSubmitted ?
          <Text as='span' fontSize={5} color='gray.7'><strong>Email</strong> Sent</Text> :
          <Text as='span' fontSize={5} color='gray.7'><strong>Forgot</strong> your password?</Text>
      }
    </Flex>

    {
      passwordResetRequestSubmitted ?
        <React.Fragment>
          <Box ml='auto' mr='auto' mb={6}>
            <Text as='p' color='gray.6' fontSize={2} textAlign='center' m={0} p={0}>
              If we find an account for that email, you should receive an email with your password reset link. If you still have trouble logging in, please contact our support team.
            </Text>
          </Box>

          <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={8}>
            <Text as={Link} $link to={LoginPath.url}>
              Back to Log In
            </Text>
          </Flex>
        </React.Fragment> :
        <React.Fragment>
          <Box ml='auto' mr='auto' mb={5}>
            <PasswordResetRequestForm isPerforming={isPerforming} onRDXSubmit={requestPasswordReset} />
          </Box>

          <Flex flexDirection='row' justifyContent='center' ml='auto' mr='auto' mb={8}>
            <Text as={isPerforming ? 'span' : Link} $link to={LoginPath.url}>
              Back to Log In
            </Text>
          </Flex>

          <Box ml='auto' mr='auto'>
            <Text as='p' color='gray.7' fontSize={2} textAlign='center' m={0} p={0}>
              CadActive Technologies &copy; {new Date().getFullYear()}
            </Text>
          </Box>
        </React.Fragment>
    }
  </ExternalPage>
);

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerforming(),
  passwordResetRequestSubmitted: selectPasswordResetRequestSubmitted(),
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    { requestPasswordReset: processRequestPasswordReset }
  ),
  UserIsNotAuthenticated,
);

export default enhance(PasswordResetRequest);
