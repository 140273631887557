import React, {useState, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import Button from '../../../components/common/Button/index';
import { pluralize } from '../../../components/common/Header';
import { StandardizeModuleCombinedStatesPath } from '../../../paths';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processCreateCombinedState,
  processEditCombinedState,
  processDeleteCombinedState
} from '../../../entities/Standardize/CombinedStates/actions';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../components/modal/ModalFooters/DeleteDialogFooter';

import { selectCurrentFilteredCombinedStates, selectCurrentCombinedStatesCount } from '../../../entities/Standardize/CombinedStates/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredCombinedStates(),
  selectSidebarIsFiltering(),
  selectCurrentCombinedStatesCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count
  ) => {
    const isLoading = isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isLoading,
      count,
      isFiltering,
      hasValidLicense,
      canCollaborate
    };
  }
);

export default function CombinedStatesBody(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const emptyRow = {
    id: '',
    name: '',
    description: '',
  };

  const [row, setRow] = useState(emptyRow);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const newCombinedState = () => {
    setRow(emptyRow);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const editRow = (rowdata) => {
    let editRow = { id: rowdata.id, name: rowdata.name, description: rowdata.description};
    setRow(editRow);
    setIsDialogOpen(true);
  };

  const cancelDialog = () => {
    setIsCreateDialogOpen(false);
    setIsDialogOpen(false);
  };

  const saveRowChanges = () => {
    if (!reduxProps.editable) {
      cancelDialog();
      return;
    }

    if(isCreateDialogOpen) {
      dispatch(processCreateCombinedState(row));
    } else {
      dispatch(processEditCombinedState(row.id, row));
    }
    cancelDialog();
  };

  const deleteItemDialog = () => {
    setIsDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteItem = () => {
    dispatch(processDeleteCombinedState(row.id));
    setIsDeleteDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    const val = (!!e.target && e.target.value) || '';
    let _row = { ...row };
    _row[`${name}`] = val;
    setRow(_row);
  };

  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => editRow(rowdata)} />
      </>
    );
  };

  const getDialogHeader = useMemo(() => {
    return isCreateDialogOpen ? 'Create Combined State' : `Edit '${row.name}'`;
  }, [isDialogOpen]);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleCombinedStatesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Combined State', reduxProps.data.length)}` :
            `Showing ${reduxProps.count} ${pluralize('Combined State', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={newCombinedState}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          <Column field='name' header="Name" style={{ width: '25%' }} ></Column>
          <Column field='description' header='Description' style={{width: '55%'}} ></Column>
          <Column body={actionBodyTemplate} style={{ width: '20%', textAlign: 'right' }} ></Column>
        </DataTable>
      </Flex>
      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={getDialogHeader}
        footer={DialogFooter(isCreateDialogOpen, false, deleteItemDialog, saveRowChanges, cancelDialog)}
        modal className="p-fluid" onHide={cancelDialog} closable={false}
      >
        <div className="field">
          <label htmlFor="name" className="font-bold">Name</label>
          <InputText id="name" value={row.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus />
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">Description</label>
          <InputTextarea id="description" value={row.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header="Delete Combined State" footer={DeleteDialogFooter(cancelDeleteDialog, deleteItem)} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {row.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}