import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { StandardizeModuleCombinedStatesPath } from '../../../paths';

export default function CombinedStatesBreadcrumb() {
  return (
    <BreadcrumbTemplate
      title={StandardizeModuleCombinedStatesPath.defaultTitle}
    />
  );
}