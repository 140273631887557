import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'REPORTING_TEMPLATES';

export const fetchAllTemplates = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createTemplate = generateAPHTypes(
  resource,
  'CREATE'
);

export const editTemplate = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteTemplate = generateAPHTypes(
  resource,
  'DELETE'
);

export const copyTemplate = generateAPHTypes(
  resource,
  'COPY'
);

export const archiveTemplate = generateAPHTypes(
  resource,
  'ARCHIVE'
);

export const unarchiveTemplate = generateAPHTypes(
  resource,
  'UNARCHIVE'
);