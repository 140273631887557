import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllSimplifiedReps,
  createSimplifiedReps,
  copySimplifiedReps,
  editSimplifiedReps,
  deleteSimplifiedReps,
} from './constants';
import schema from './schema';

const client = api(schema, EK.SIMPLIFIED_REPS);

export const processFetchAllSimplifiedReps = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllSimplifiedReps,
    '/standardize/simplified_reps'
  );

export const processCreateSimplifiedRep = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createSimplifiedReps,
    '/standardize/simplified_reps',
    {
      data: {
        simplifiedReps: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Simplified rep successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create simplified rep',
      },
    }
  );

export const processCopySimplifiedRep = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copySimplifiedReps,
    '/standardize/simplified_reps/copy',
    {
      data: {
        simplifiedReps: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Simplified rep successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy simplified rep',
      },
    }
  );

export const processEditSimplifiedRep = (simplifiedRepId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editSimplifiedReps,
    `/standardize/simplified_reps/${simplifiedRepId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteSimplifiedRep = (simplifiedRepId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteSimplifiedReps,
    `/standardize/simplified_reps/${simplifiedRepId}?with_full_detail=true`,
    {
      deleteKey: EK.SIMPLIFIED_REPS.state,
      dependentDeletionKeys: [
        EK.SIMPLIFIED_REP_RULESETS.state,
        EK.SIMPLIFIED_REP_RULESET_RULES.state,
        EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state,
      ],
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Simplified rep deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete simplified rep',
      },
    }
  );
