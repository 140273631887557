import { call, takeLatest, put, fork } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { LoginPath } from '../../paths';

import { samlLoginTypes } from '../../modules/auth/constants';

import { setToken } from '../../modules/auth/utils/token';
import { authenticateClient } from '../../modules/api/client';
import { DashboardPath } from '../../paths';

export function* handleSAMLLoginSuccess({ payload = {} }) {
  const { authenticationToken } = payload;
  if (!authenticationToken) {
    // TODO: handle error here
    yield put(push(LoginPath.url));
    return;
  }

  // First remove any existing token
  yield call(setToken, '');

  // Set the new token
  yield call(setToken, authenticationToken);

  // Update the API client with the new token
  yield call(authenticateClient, authenticationToken);

  // Redirect to dashboard
  window.location = DashboardPath.url;
}

export function* watchLoginSaga() {
  yield takeLatest(samlLoginTypes.SUCCESS, handleSAMLLoginSuccess);
}

// final output saga
export default function* main() {
  yield fork(watchLoginSaga);
}
