import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Text from '../../../../components/common/Text';
import Flex from '../../../../components/common/Flex';

import {
  processUpdateLastLegalInformedAt
} from '../../../../entities/CurrentUser/actions';

const LegalTermsUpdatedModal = ({ updateLastLegalInformedAt }) => (
  <Box>
    <ModalTitle><strong>Terms</strong> Updated</ModalTitle>

    <Flex
      flexDirection='row'
      justifyContent='center'
      ml='auto'
      mr='auto'
      border={1}
      $borderRadius={1}
      mb={6}
      p={3}
      bg='white'
    >
      <Text as='span' fontSize={2} color='gray.7'>
        Our <Text as='a' target='_blank' href='//cadactive.com/legal/'>Terms of Use, Privacy Policy, and End User License Agreement</Text> have been updated. Please review them <Text as='a' target='_blank' href='//cadactive.com/legal/'>here</Text>. By continuing to use our products, you are agreeing to these updated terms. If you have any questions or concerns, feel free to contact our support team.
      </Text>
    </Flex>

    <Button primary type='submit' large full onClick={updateLastLegalInformedAt}>I understand</Button>
  </Box>
);

const mapDispatchToProps = (dispatch, props) => ({
  updateLastLegalInformedAt() {
    dispatch(processUpdateLastLegalInformedAt());
    props.closeModal();
  },
});

const enhance = compose(
  connect(null, mapDispatchToProps),
);

export default enhance(LegalTermsUpdatedModal);