import { take, put, fork, select } from 'redux-saga/effects';

import { selectIsFetching } from '../../../modules/utility/selectors';
import { signout } from '../../../modules/auth/sagas';
import { isInitialLoadComplete } from '../../Dashboard/actions';

import { processFetchAllSpools } from '../../../entities/Cabling/Spools/actions';

export function* watchLoadCablingModuleSaga() {
  try {
    yield put(processFetchAllSpools());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadCablingModuleSaga);
}