import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import styled from 'styled-components';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Flex from '../../../../../components/common/Flex';
import Button from '../../../../../components/common/Button';
import Icon from '../../../../../components/common/Icon';

import RDXTextInput from '../../../RDXTextInput';

const FlexibleInputContainer = styled(Box)`
  flex-grow: 1;
  height: 100%;
  height: 4rem;
`;

const BoxForm = styled(Box)`
  flex-grow: 1;
`;

const SearchForm = ({ handleSubmit, isPerforming, valid }) => (
  <BoxForm as='form' onSubmit={handleSubmit}>
    <Flex flexDirection='row' alignItems='center' pl={4} pr={4}>
      <Flex flexDirection='column' justifyContent='center' alignItems='center'>
        <Icon name='search' fontSize={3} color='gray.4' />
      </Flex>
      <FlexibleInputContainer>
        <Field
          component={RDXTextInput}
          name='searchTerm'
          type='text'
          height='100%'
          placeholder='Search for...'
        />
      </FlexibleInputContainer>
      <Button
        primary
        transparent
        subtle
        type="submit"
        icon='enter'
        large
        disabled={isPerforming || !valid}
        isPerformingAction={isPerforming}
        ml={2}
      />
    </Flex>
  </BoxForm>
);

const enhance = compose(
  reduxForm({
    form: `search.${EK.CONFIG_SETTINGS.state}`,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.get('searchTerm'));
    },
  })
);

export default enhance(SearchForm);
