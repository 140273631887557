import React from 'react';

import Text from '../../common/Text';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

const ComplexCellRenderer = ({
  value,
  placeholder = false,
  isCapitalized,
  paddingLeft = false,
  paddingRight = false,
  showSecondary = true,
}) => {
  const isValuePresent = !!value;
  const hasSecondaryPlaceholder = ((value && !!value.secondaryPlaceholder) || (placeholder && !!placeholder.secondary));

  return (
    <BaseCell flexDirection='column' justifyContent='center' $spaceLeft={paddingLeft} $spaceRight={paddingRight} >
      <BaseCellDataItem $clickable={isValuePresent && !!value.to} $disabled={!isValuePresent || !value.primary} $isCapitalized={isCapitalized}>
        { (isValuePresent && (value.primary || value.primaryPlaceholder)) || (placeholder && placeholder.primary) }
      </BaseCellDataItem>
      {showSecondary &&
        <BaseCellDataItem $secondary $disabled={!isValuePresent || !value.secondary}>
          { isValuePresent && value.tertiary && <Text as='span' color='gray.7' fontWeight={600}>[{ value.tertiary }]</Text> || null }
          {isValuePresent && value.tertiary && (value.secondary || hasSecondaryPlaceholder) && <Text as='span'>{ ' // ' }</Text> || null }
          { (isValuePresent && (value.secondary || value.secondaryPlaceholder) || placeholder && placeholder.secondary) }
        </BaseCellDataItem>
      }
    </BaseCell>
  );
};

export default ComplexCellRenderer;