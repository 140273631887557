import { FittingCategory } from '../../../entities/Piping/FittingCategories/types';

export const saveCurrentFittingCategoryChanges = (
  changes: FittingCategory[],
  saveAction: (data: Array<any>) => any,
) => {
  const collectionsdata: Array<any> = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({ categoryName: item.categoryName, symbolFile: item.symbolFile, allowValveNumber: item.allowValveNumber } as any);
    } else if (item.isDeleted) {
      collectionsdata.push({ ...item, _destroy: '1' } as any);
    } else if (item.isEdited) {
      collectionsdata.push({ ...item } as any);
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};

export const convertToFittingCategory = ({
  id,
  isCreated,
  isEdited,
  isDeleted,
  categoryName,
  symbolFile,
  allowValveNumber,
}: FittingCategory) => {
  return {
    id,
    isCreated,
    isEdited,
    isDeleted,
    categoryName,
    symbolFile,
    allowValveNumber,
  };
};