import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchCurrentUsers, updateLastLegalInformedAtTypes, updateCurrentUsers, changeCurrentUserPasswordTypes } from './constants';

import schema from './schema';

export const client = api(schema, EK.CURRENT_USER);

export const fetchCurrentUser = disableErrorRedirect => client(REQUEST_TYPES.GET, 'single', fetchCurrentUsers, '/me', {
  disableErrorRedirect,
});

export const processUpdateLastLegalInformedAt = () => client(REQUEST_TYPES.PATCH, 'single', updateLastLegalInformedAtTypes, '/me/accept', {
  data: {
    eulaAccepted: true,
  },
});

export const updateCurrentUser = data => client(REQUEST_TYPES.PATCH, 'single', updateCurrentUsers, '/me', {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile updated',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to update profile',
  },
});

export const changeCurrentUserPassword = data => client(REQUEST_TYPES.PATCH, 'single', changeCurrentUserPasswordTypes, '/me/change_password', {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Password updated',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to update password - did you enter your current password correctly?',
  },
});