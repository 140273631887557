import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { MATERIAL_FILTERS } from '../../../entities/Piping/Materials/model';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='name'
        label='Name'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='fittingCode'
        label='Fitting Code'
        ek={EK.FITTING_CODES}
        clearable
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='fittingCategory'
        label='Fitting Category'
        ek={EK.FITTING_CATEGORIES}
        clearable
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='material'
        label='Material'
        ek={EK.MATERIALS}
        eFilter={MATERIAL_FILTERS.FITTING}
        clearable
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='selectionName'
        label='Selection Name'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='stockno'
        label='Stock No'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='mccsCode'
        label='MCCS Code'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='boltNutFamily'
        label='Bolt Nut'
        ek={EK.BOLT_NUTS}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.FITTING_FAMILIES.state}` })
);

export default enhance(FilterForm);
