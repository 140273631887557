import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useFloating, useHover, useInteractions } from '@floating-ui/react';

import useMergeRefs from '../../../../hooks/useMergeRefs';
import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';
import Tooltip, { CONTAINERS } from '../../../../components/tooltip/Tooltip';
import GridIsEditingTooltip from '../../../../components/tooltip/templates/GridIsEditingTooltip';
import ArchivedEntityTooltip from '../../../../components/tooltip/templates/ArchivedEntityTooltip';
import InvalidLicenseTooltip from '../../../../components/tooltip/templates/InvalidLicenseTooltip';
import InvalidRoleTooltip from '../../../../components/tooltip/templates/InvalidRoleTooltip';
import FilterToggleButton from '../FilterToggleButton';


const Options = React.memo(({
  isLoading,
  isPerforming,
  product,
  hasValidLicense,
  shouldHaveLicense: incomingShouldHaveValidLicense,
  canAdmin,
  shouldAdmin: incomingShouldAdmin,
  canCollaborate,
  shouldCollaborate: incomingShouldCollaborate,
  isArchived,
  role,
  requiredRole,
  isEditingGrid,
  canFilter,
  isFilterDisabled,
  pendingValidChanges,
  archiveEntity,
  isArchiveEntityDisabled,
  unarchiveEntity,
  isUnarchiveEntityDisabled,
  editEntity,
  isEditEntityDisabled,
  createEntity,
  isCreateEntityDisabled,
  updateEntities,
  isUpdateEntitiesDisabled,
  ConstructedOptions,
  ...rest
}) => {

  //this particular case uses useFloating instead of useFloatingState because of hovering
  const [infoOpen, setInfoOpen] = useState(false);
  const {
    x: infoX,
    y: infoY,
    reference: infoReference,
    floating: infoFloating,
    strategy: infoFloatingStrategy,
    context: infoFloatingContext,
  } = useFloating({placement: 'bottom-end', infoOpen, onOpenChange: setInfoOpen});

  //TODO: the 'typing' floating refuses to leave the corner. Should just sit above save changes button
  const {
    x: typingX,
    y: typingY,
    reference: typingReference,
    floating: typingFloating,
    strategy: typingFloatingStrategy,
  } = useFloating({placement: 'top-end'});

  const {
    getReferenceProps: getInfoReferenceProps,
    getFloatingProps: getInfoFloatingProps,
  } = useInteractions([useHover(infoFloatingContext)]);

  const {
    getReferenceProps: getTypingReferenceProps,
    getFloatingProps: getTypingFloatingProps,
  } = useInteractions([]);

  const parentRef = useMergeRefs(infoReference, typingReference);

  const shouldHaveLicense = incomingShouldHaveValidLicense !== undefined ? incomingShouldHaveValidLicense : hasValidLicense !== undefined;
  const shouldCollaborate = incomingShouldCollaborate !== undefined ? incomingShouldCollaborate : canCollaborate !== undefined;
  const shouldAdmin = incomingShouldAdmin !== undefined ? incomingShouldAdmin : canAdmin !== undefined;
  const canArchive = isArchived !== undefined;

  const canEditGrid = isEditingGrid !== undefined;

  const isOptionsDisabled = (shouldHaveLicense && !hasValidLicense) ||
    (shouldCollaborate && !canCollaborate) ||
    (shouldAdmin && !canAdmin) ||
    (canEditGrid && isEditingGrid);

  return (
    <Box ref={parentRef} {...getInfoReferenceProps(getTypingReferenceProps())}>
      <Flex flexDirection='row' justifyContent='center'>
        {
          !isLoading && ConstructedOptions &&
          <ConstructedOptions
            isLoading={isLoading}
            shouldHaveLicense={shouldHaveLicense}
            hasValidLicense={hasValidLicense}
            shouldCollaborate={shouldCollaborate}
            canCollaborate={canCollaborate}
            shouldAdmin={shouldAdmin}
            canAdmin={canAdmin}
            canArchive={canArchive}
            isArchived={isArchived}
            canEditGrid={canEditGrid}
            isEditingGrid={isEditingGrid}
            isOptionsDisabled={isOptionsDisabled}
            {...rest}
          />
        }
        {
          !isLoading && canFilter && <FilterToggleButton disabled={isFilterDisabled === undefined ? isOptionsDisabled : isFilterDisabled} />
        }
        {
          !isLoading && canArchive && (
            isArchived ? (
              unarchiveEntity && <Button secondary icon='unarchive' disabled={isUnarchiveEntityDisabled === undefined ? (isOptionsDisabled || isPerforming) : isUnarchiveEntityDisabled} onClick={unarchiveEntity} mr={2} /> || null
            ) : (
              archiveEntity && <Button secondary icon='archive' disabled={isArchiveEntityDisabled === undefined ? (isOptionsDisabled || isPerforming) : isArchiveEntityDisabled} onClick={archiveEntity} mr={2} />
            )
          ) || null
        }
        {
          !isLoading && editEntity && (!canArchive || (canArchive && !isArchived)) && <Button secondary icon='edit' disabled={isEditEntityDisabled === undefined ? (isOptionsDisabled || isPerforming) : isEditEntityDisabled} onClick={editEntity} mr={2} />
        }
        {
          !isLoading && createEntity &&
          <Button
            primary={!(shouldHaveLicense && !hasValidLicense)}
            $error={shouldHaveLicense && !hasValidLicense}
            disabled={isCreateEntityDisabled === undefined ? (isOptionsDisabled || isPerforming) : isCreateEntityDisabled} onClick={createEntity}
          >
            {shouldHaveLicense && !hasValidLicense ? 'No License' : 'Add New'}
          </Button>
        }
        {
          !isLoading && updateEntities &&
          <Button
            primary={!(shouldHaveLicense && !hasValidLicense)}
            $error={shouldHaveLicense && !hasValidLicense}
            disabled={isUpdateEntitiesDisabled === undefined ? (!pendingValidChanges || isOptionsDisabled || isPerforming || (canArchive && isArchived)) : isUpdateEntitiesDisabled} onClick={updateEntities}
          >
            {shouldHaveLicense && !hasValidLicense ? 'No License' : 'Save Changes'}
          </Button>
        }
      </Flex>
      {
        canEditGrid && isEditingGrid && ReactDOM.createPortal(
          <Tooltip
            ref={typingFloating}
            style={{
              position: typingFloatingStrategy,
              top:typingY ?? 0,
              left: typingX ?? 0,
            }}
            size='fluid'
            {...getTypingFloatingProps()}
          >
            <Text fontSize={1} fontWeight={500} color='gray.6'>
              Currently editing data...
            </Text>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
      {
        infoOpen && (isOptionsDisabled || isArchived) && ReactDOM.createPortal(
          <Tooltip ref={infoFloating} style={{position:infoFloatingStrategy, top: infoY??0, left:infoX??0}} size='large' {...getInfoFloatingProps()}>
            {isEditingGrid && <GridIsEditingTooltip />}
            {shouldHaveLicense && !hasValidLicense && <InvalidLicenseTooltip product={product} />}
            {((shouldCollaborate && !canCollaborate) || (shouldAdmin && !canAdmin)) && <InvalidRoleTooltip role={role} requiredRole={requiredRole} />}
            {!isOptionsDisabled && isArchived && <ArchivedEntityTooltip />}
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Box>
  );
});

export default Options;