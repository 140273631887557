import React from 'react';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import Text from '../../../../components/common/Text';
import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import { ALLOWANCES } from '../../../../entities/Piping/BendMachineFamilies/model';

export function BendMachineDialog({ currentEditingRow, editable, handleEditedRowChange, isCreateDialogOpen = false, isCopyDialogOpen = false, isDialogOpen, openDeleteDialog=false, saveAction, cancelDialogs, validation }) {
  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Bend Machine';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  return (
    <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={() => getDialogHeader()} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
      <div>
        <label>Name</label>
        <InputText keyfilter={ALLOWANCES.NAME} value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
      </div>
    </Dialog>
  );
}

export function DeleteBendMachineDialog({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) {

  return (
    <Dialog visible={editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Bend Family '${currentEditingRow.name}'`} footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)} closable={false} >
      <div>
        <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
        <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
      </div>
    </Dialog>
  );
}