import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'END_TYPES';

export const fetchAllEndTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const saveEndTypes = generateAPHTypes(resource, 'COLLECTION');

export const newEndTypeRows = [
  { name: '', code: '', id: 'NEW_ROW_1', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_2', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_3', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_4', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_5', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_6', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_7', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_8', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_9', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', code: '', id: 'NEW_ROW_0', isEdited: false, isDeleted: false, isCreated: false, },
];