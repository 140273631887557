import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { processFetchAllInsulationFamilies } from '../../../entities/Piping/InsulationFamilies/actions';
import { processFetchAllInsulationSpecs } from '../../../entities/Piping/InsulationSpecs/actions';
import { processFetchAllSpecs } from '../../../entities/Piping/Specs/actions';
import { useDispatch } from 'react-redux';

export default function SpecsSwitchBody(props) {
  const dispatch = useDispatch();

  useEffect( () => {
    dispatch(processFetchAllInsulationFamilies());
    dispatch(processFetchAllInsulationSpecs());
    dispatch(processFetchAllSpecs());
  }, []);

  return (
    <Switch {...props} />
  );
}
