import { put, take, select, fork, takeLatest } from 'redux-saga/effects';
import {
  getFormValues,
  getFormInitialValues,
} from 'redux-form/immutable';
import { Map } from 'immutable';
import get from 'lodash/get';

import EK from '../../../../../entities/keys';

import { reloadModal, hideModal } from '../../../../../modules/modal/actions';

import { selectIsFetchingInModal } from '../../../../../modules/utility/selectors';

import { processFetchAllConfigSettingCategories } from '../../../../../entities/Synchronize/ConfigSettingCategories/actions';

import {
  selectNormalizedConfigSettings
} from '../../../../../entities/Synchronize/ConfigSettings/selectors';

import {
  processFetchConfigProfile
} from '../../../../../entities/Synchronize/ConfigProfiles/actions';
import {
  createConfigProfiles,
  copyConfigProfiles,
  deleteConfigProfiles,
} from '../../../../../entities/Synchronize/ConfigProfiles/constants';
import {
  selectNormalizedConfigProfiles
} from '../../../../../entities/Synchronize/ConfigProfiles/selectors';

import ConfigProfileValueModel from '../../../../../entities/Synchronize/ConfigProfileValues/model';
import { selectNormalizedConfigProfileValues } from '../../../../../entities/Synchronize/ConfigProfileValues/selectors';
import { processSaveConfigProfileValues } from '../../../../../entities/Synchronize/ConfigProfileValues/actions';

import {
  PREPARE_CONFIG_PROFILE_EDITOR_MODAL,
  SAVE_CONFIG_PROFILE,
} from './constants';
import { configProfileEditorModalPrepared } from './actions';

function* watchCreateConfigProfileSaga () {
  yield takeLatest([
    createConfigProfiles.SUCCESS,
    copyConfigProfiles.SUCCESS,
  ], function* ({ payload = {} }) {
    const profileId = `${get(payload, `${EK.CONFIG_PROFILES.plural}[0].id`)}`;

    if (profileId) {
      const watchSaveConfigProfileSaga = generateWatchSaveConfigProfileSaga(profileId);
      yield fork(watchSaveConfigProfileSaga);

      let profiles = yield select(selectNormalizedConfigProfiles());
      let copiedProfile = profiles.get(profileId);
      while (!copiedProfile) {
        yield take();
        profiles = yield select(selectNormalizedConfigProfiles());
        copiedProfile = profiles.get(profileId);
      }

      yield put(reloadModal('CONFIG_PROFILE_EDITOR_MODAL', { configProfileId: profileId, copyEntity: false }));
    }
  }
  );
}

const generateWatchSaveConfigProfileSaga = (id) =>
  function* () {
    yield takeLatest(
      SAVE_CONFIG_PROFILE,
      function* () {
        const settings = yield select(selectNormalizedConfigSettings());

        const formValues = yield select(getFormValues(EK.CONFIG_SETTINGS.state));
        const initialFormValues = yield select(getFormInitialValues(EK.CONFIG_SETTINGS.state));
        const profileValues = yield select(selectNormalizedConfigProfileValues());

        const currentProfileValues = profileValues.filter(profileValue => profileValue.get(EK.CONFIG_PROFILES.single) === id);
        const serialized = formValues.reduce((serializedRecords, formValue, settingId) => {
          const currentSetting = settings.get(settingId);
          if (initialFormValues.has(settingId)) {
            // this is an edited value
            if (currentSetting.multipleAllowed) {
              const initialProfileValues = currentProfileValues.filter(pv => pv.get(EK.CONFIG_SETTINGS.single) === settingId);
              const immutizedFormValues = formValue.map(fv => Map(fv));
              const editedFormValues = immutizedFormValues.reduce((intermediateSerializedRecords, fv) => {
                const initialProfileValue = initialProfileValues.find(ipv => ipv.get('id') === fv.get('id'));

                if (initialProfileValue) {
                  // there is matching profileValue here
                  if (currentSetting.isValueEditedAgainst(fv.get('value'), initialProfileValue.get('value'))) {
                    // value is edited
                    if (currentSetting.isValueDefaultOrUndefined(fv.get('value'))) {
                      // delete this profile value because it is undefined or default value
                      return [
                        ...intermediateSerializedRecords,
                        { id: initialProfileValue.get('id'), _destroy: '1' },
                      ];
                    } else {
                      // update the value for this profile value
                      return [
                        ...intermediateSerializedRecords,
                        {
                          id: initialProfileValue.get('id'),
                          ...initialProfileValue.set('value', fv.get('value')).serialize(),
                        }
                      ];
                    }
                  } else {
                    return intermediateSerializedRecords;
                  }
                } else {
                  // this is a new record
                  return [
                    ...intermediateSerializedRecords,
                    new ConfigProfileValueModel({
                      [EK.CONFIG_SETTINGS.single]: settingId,
                      valueType: currentSetting.valueType,
                    }).set('value', fv.get('value')).serialize(),
                  ];
                }
              }, serializedRecords);

              // and now check for any values that have been removed
              const removedFormValues = initialProfileValues.reduce((removedRecords, ipv) => {
                if (!immutizedFormValues.find(fv => ipv.get('id') === fv.get('id'))) {
                  return [
                    ...removedRecords,
                    {
                      id: ipv.get('id'),
                      _destroy: '1'
                    }
                  ];
                } else {
                  return removedRecords;
                }
              }, []);

              return [
                ...editedFormValues,
                ...removedFormValues,
              ];
            } else {
              const initialProfileValue = currentProfileValues.find(pv => pv.get(EK.CONFIG_SETTINGS.single) === settingId);
              if (currentSetting.isValueEditedAgainst(formValue, initialProfileValue.value)) {
                // value is edited
                if (currentSetting.isValueDefaultOrUndefined(formValue)) {
                  // delete this profile value because it is undefined or default value
                  return [
                    ...serializedRecords,
                    { id: initialProfileValue.id, _destroy: '1' },
                  ];
                } else {
                  // update the value for this profile value
                  return [
                    ...serializedRecords,
                    {
                      id: initialProfileValue.get('id'),
                      ...initialProfileValue.set('value', formValue).serialize(),
                    }
                  ];
                }
              } else {
                return serializedRecords;
              }
            }
          } else {
            if (currentSetting.multipleAllowed) {
              // this is a new list of values
              return [
                ...serializedRecords,
                ...formValue.map(fv => new ConfigProfileValueModel({
                  [EK.CONFIG_SETTINGS.single]: settingId,
                  valueType: currentSetting.valueType,
                }).set('value', fv.value).serialize()).toArray()
              ];
            } else {
              // this is a new value
              if (currentSetting.isValueEditedAgainst(formValue)) {
                // value is edited and not defaultValue
                return [
                  ...serializedRecords,
                  new ConfigProfileValueModel({
                    [EK.CONFIG_SETTINGS.single]: settingId,
                    valueType: currentSetting.valueType,
                  }).set('value', formValue).serialize(),
                ];
              } else {
                return serializedRecords;
              }
            }
          }
        }, []);

        yield put(processSaveConfigProfileValues(id, serialized));
      }
    );
  };

export function* handleDeleteConfigProfileSuccess() {
  yield put(hideModal());
}

export function* watchDeleteConfigProfileSaga() {
  yield takeLatest(
    deleteConfigProfiles.SUCCESS,
    handleDeleteConfigProfileSuccess
  );
}

// final output saga
export default function* main() {
  try {
    const {
      payload: { id, copyEntity },
    } = yield take(PREPARE_CONFIG_PROFILE_EDITOR_MODAL);

    yield put(processFetchAllConfigSettingCategories());

    if (id && !copyEntity) {
      yield put(processFetchConfigProfile(id));
    }

    let isFetchingInitialEntities = yield select(selectIsFetchingInModal());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetchingInModal());
    }

    yield put(configProfileEditorModalPrepared());

    if (id && !copyEntity) {
      const watchSaveConfigProfileSaga = generateWatchSaveConfigProfileSaga(id);
      yield fork(watchSaveConfigProfileSaga);
    } else {
      yield fork(watchCreateConfigProfileSaga);
    }

    yield fork(watchDeleteConfigProfileSaga);
  } catch(error) {
    console.log(error);
    yield put(hideModal());
  }
}