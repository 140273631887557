import { SpecFittingFamily } from '../../../entities/Piping/FittingFamilies/types';
import { EditableSpecFittingInstance } from '../../../entities/Piping/SpecFittingInstances/types';

export function convertToSpecFittingInstanceType(item: any): EditableSpecFittingInstance {
  if (item?.fittingInstance?.id) {
    return {
      id: item.id,
      fittingInstance: item.fittingInstance,
      fittingFamilyId: item.fittingFamilyId,
      spec: item.spec,
      stockno: item.stockno || item.fittingInstance.stockno,
      mccsCode: item.mccsCode || item.fittingInstance.mccsCode,
      isSelectedForSpec: !!item.isSelectedForSpec,
      schedule: item.schedule || item.fittingInstance.schedule,
      isEdited: !!item.isEdited,
      isDeleted: !!item.isDeleted,
      isCreated: !!item.isCreated,
      originalRow: item.originalRow || item,
      boltNutFamily: item.boltNutFamily,
      selectionName: item.selectionName,
    };
  }
  return {
    id: item.id,
    fittingInstance: item,
    fittingFamilyId: item.fittingFamilyId,
    spec: item.spec,
    stockno: item.stockno,
    mccsCode: item.mccsCode,
    isSelectedForSpec: !!item.isSelectedForSpec,
    isEdited: !!item.isEdited,
    isDeleted: !!item.isDeleted,
    isCreated: !!item.isCreated,
    originalRow: item.originalRow || item,
    boltNutFamily: item.boltNutFamily,
    selectionName: item.selectionName,
    schedule: item.schedule,
  };
}

// editedOrder will be handles in the 'saveaction' method in the body.js file to simplify matters since it will just be getting filtered and made into a string
export const saveCurrentPipingSpecFittingFamiliesChanges = (
  editedRows: Record<string, SpecFittingFamily>,
  specFittingInstances: Array<EditableSpecFittingInstance>,
  saveAction: any,
) => {
  const collectionsdata: any = [];

  for (const [key, value] of Object.entries(editedRows)) {
    if (value.isSelectedForSpec != value.originalData.isSelectedForSpec) {
      const fittingFamilyData = value.isSelectedForSpec ?
        { id: key, data: value.fittingInstances.toArray().map((instance: any) => ({ fitting_instance_id: instance.id, stockno: instance.stockno, mccsCode: instance.mccsCode })) } :
        { id: key, data: specFittingInstances.filter(({ fittingFamilyId }) => fittingFamilyId.id == key).map(instance => ({ id: instance.id, _destroy: '1' })) };
      collectionsdata.push(fittingFamilyData);
    }
  }

  saveAction(collectionsdata);
};
