export const convertToProjectSpecType = ({
  id,
  specName,
  mnemonic,
  instancesCount,
  archivedFlag,
  isSelectedForProject,
  isEdited=false,
  isCreated=false,
  isDeleted=false,
}) => ({
  id,
  specName,
  mnemonic,
  instancesCount,
  archivedFlag,
  isSelectedForProject,
  isEdited,
  isCreated,
  isDeleted
});

/**
 * MAY NEED TO CHANGE AS THE API COULD CHANGE/COULD NEED TO USE _destroy: '1' FOR ITEMS TO REMOVE
 * cases:
 *  isSelectedForProject is the same as the original: do nothing
 *  isSelectedForProject is different than original:
 *    if false: do nothing
 *    if true: add to list of new data
 * @param {Array} editedRows
 * @param {Project} project
 * @param {Function} saveAction
 */

export const saveCurrentProjectSpecs = (
  editedRows,
  project,
  saveAction,
) => {
  // need to have a mapping changes function similar to spec pipe families here.
  let data = project.specs?.toArray() || [];
  editedRows.forEach(item => {
    const oItemIndex = data.findIndex( foo => foo.id == item.id);
    if(oItemIndex != -1) {
      if(!item.isSelectedForProject) {
        data.splice(oItemIndex, 1);
      }
    } else {
      if(item.isSelectedForProject) {
        data.push({id: item.id});
      }
    }
  });
  data = data.map(item => item.id);

  saveAction(data);
};