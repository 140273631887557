import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchPipingCatalogTypes, updatePipingCatalogTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.PIPING_CATALOGS);

export const processFetchPipingCatalog = () => client(REQUEST_TYPES.GET, 'single', fetchPipingCatalogTypes, '/piping/catalogs/current_catalog');

export const processUpdatePipingCatalog = (unitSystemId) => client(REQUEST_TYPES.PATCH, 'single', updatePipingCatalogTypes, '/piping/catalogs/current_catalog', {
  data: { unitSystemId },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Default unit system successfully updated',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'There was an error updating your default unit system',
  },
});