import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../../form/templates/ConfirmDeleteForm';


import { selectIsPerformingInModal } from '../../../../../modules/utility/selectors';

import SimplifiedRepModel from '../../../../../entities/Standardize/SimplifiedReps/model';
import SimplifiedRepForm from '../../../../form/templates/Standardize/SimplifiedRepForm';
import {
  processCreateSimplifiedRep,
  processCopySimplifiedRep,
  processEditSimplifiedRep,
  processDeleteSimplifiedRep,
} from '../../../../../entities/Standardize/SimplifiedReps/actions';

import { selectCurrentSimplifiedRep } from './selectors';

import injectSaga from '../../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptySimplifiedRep = new SimplifiedRepModel();

const SimplifiedRepModal = ({
  simplifiedRep,
  copyEntity,
  isPerforming,
  onCreate,
  onCopy,
  onUpdate,
  onDelete,
}) => {
  const onSave = useCallback(
    (data) => {
      if (simplifiedRep) {
        if (copyEntity) {
          onCopy({ ...data, id: simplifiedRep.id });
        } else {
          onUpdate(simplifiedRep.id, data);
        }
      } else {
        onCreate(data);
      }
    },
    [simplifiedRep, copyEntity, onCreate, onCopy, onUpdate]
  );

  const onConfirmDelete = useCallback(
    () => onDelete(simplifiedRep.id),
    [simplifiedRep, onDelete]
  );

  return (
    <Box>
      <CreateOrEditEntityTitle
        edit={simplifiedRep && simplifiedRep.name}
        create="Simplified Rep"
        copy={copyEntity}
      />

      <SimplifiedRepForm
        initialValues={
          simplifiedRep || emptySimplifiedRep
        }
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {simplifiedRep && !copyEntity && (
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm
            deletePhrase={simplifiedRep.name}
            isPerforming={isPerforming}
            onRDXSubmit={onConfirmDelete}
          />
        </React.Fragment>
      )}
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  simplifiedRep: selectCurrentSimplifiedRep(),
});

const enhance = compose(
  injectSaga({ key: `${EK.SIMPLIFIED_REPS.state}Modal`, saga }),
  connect(mapStateToProps, {
    onCreate: processCreateSimplifiedRep,
    onCopy: processCopySimplifiedRep,
    onUpdate: processEditSimplifiedRep,
    onDelete: processDeleteSimplifiedRep,
  })
);

export default enhance(SimplifiedRepModal);
