import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

import Text from '../../../../components/common/Text';
import ComplexCellRenderer from '../../../../components/grid/ComplexCellRenderer';
import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import { processFetchAllUnitSystems } from '../../../../entities/Synchronize/UnitSystems/actions';
import { createSelector } from 'reselect';

import { selectUnitSystems } from '../../../../entities/Synchronize/UnitSystems/selectors';

const mapStateToProps = createSelector(
  selectUnitSystems(),
  (unitSystems) => ({
    unitSystems: unitSystems && unitSystems.toList().toArray() || [],
  })
);

export const InsulationDialog = ({ currentEditingRow, editable, getDialogHeader, handleEditedRowChange, isCreateDialogOpen = false, isCopyDialogOpen = false, isDialogOpen, openDeleteDialog = false, saveAction, cancelDialogs, validation, defaultUnitSystem}) => {
  const {unitSystems} = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const unitSystemArray = useMemo(() => {
    let usa = [{ primary: 'Default', secondary: 'uses Unit System from Piping Catalog', id: '', name: 'Default' }, ...unitSystems];
    return usa;
  }, [unitSystems]);

  useEffect(() => {
    dispatch(processFetchAllUnitSystems());
  }, []);

  return (
    <Dialog
      visible={editable && isDialogOpen}
      style={{ width: '75%' }}
      header={() => getDialogHeader()}
      footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)}
      modal
      className='p-fluid'
      closable={false}
    >
      <div>
        <label>Name</label>
        <InputText
          value={currentEditingRow.name}
          onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
          className={!validation.name && 'p-invalid'}
        />
      </div>
      <div>
        <label>Unit System</label>
        <Dropdown
          optionLabel='name'
          value={currentEditingRow.unitSystem || { primary: 'Default', secondary: 'uses Unit System from Piping Catalog', id: '', name: 'Default' }}
          options={unitSystemArray}
          onChange={(e) => handleEditedRowChange(e.target.value, 'unitSystem')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.primary, secondary: item.secondary }, placeholder: '', isCapitalized: false
          })}
          filter
        />
      </div>
      <div>
        <label>Description</label>
        <InputText
          value={currentEditingRow.description}
          onChange={(e) => handleEditedRowChange(e.target.value, 'description')}
          className={!validation.description && 'p-invalid'}
        />
      </div>
    </Dialog>
  );
};

export const DeleteInsulationDialog = ({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) =>
  <Dialog
    visible={editable && isDeleteDialogOpen}
    style={{ width: '32rem' }}
    header={`Delete Bolt Nut Family '${currentEditingRow.name}'`}
    footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)}
    closable={false}
  >
    <div>
      <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
      <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
      <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
    </div>
  </Dialog>;
