import React from 'react';
import { createSelector } from 'reselect';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { selectCurrentPipe } from '../PipesEditSwitch/selectors';
import { PipingModulePipesEditPath } from '../../../paths';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

const mapStateToProps = (PipeId) => createSelector(
  selectCurrentPipe(PipeId),
  (pipe) => ({pipe})
);

function PipeInstanceBreadcrumb ({location}) {
  const match = matchPath(location.pathname, { path: PipingModulePipesEditPath.url });
  const pipeId = (match && match.params.id) || null;

  const reduxProps = useSelector(mapStateToProps(pipeId));

  return <BreadcrumbTemplate
    title={reduxProps.pipe && reduxProps.pipe.name || PipingModulePipesEditPath.defaultTitle }
  />;
}

export default withRouter(PipeInstanceBreadcrumb);
