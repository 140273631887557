import React from 'react';
import Text from '../../common/Text';
import { Parameter } from '../../../entities/Standardize/Parameters/types';
import styled from 'styled-components';

interface StyledTextProps {
  $float?: string;
  $width?: string;
  children?: React.ReactNode;
}

const StyledText = styled(Text)<StyledTextProps>`
  float: ${props => props.$float || 'none'};
  width: ${props => props.$width || 'auto'};
`;

/**
 * Renders the default value body based on the parameter.valueType.
 *
 * @param {Parameter} rowdata - a parameter object
 * @returns {React.ReactNode} - The rendered default value body.
 */
const MultiTypeCellRenderer = (rowdata: Parameter): React.ReactNode => {
  switch (rowdata.valueType) {
  case 'double':
    return (
      <>
        <StyledText $float="left" $width="50%">{rowdata.defaultValue}</StyledText>
        <StyledText $float="right" $width="50%">{rowdata.unit?.abbreviation || '--'}</StyledText>
      </>
    );
  case 'bool':
    return (
      <StyledText as="span">
        {typeof rowdata.defaultValue === 'string'
          ? rowdata.defaultValue
          : rowdata.defaultValue === true
            ? 'true'
            : rowdata.defaultValue === false
              ? 'false'
              : 'undefined'}
      </StyledText>
    );
  default:
    return (
      <StyledText as="span">
        {rowdata.defaultValue}
      </StyledText>
    );
  }
};

export default MultiTypeCellRenderer;