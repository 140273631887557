import React from 'react';
import Button from '../../../common/Button';

export default (cancelAction, deleteAction) => (
  <>
    <Button
      secondary={true}
      onClick={cancelAction}
    >
      Cancel
    </Button>
    <Button
      error={true}
      onClick={deleteAction}
    >
      Delete
    </Button>
  </>
);