import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllSimplifiedRepRulesetsForIndividualSimplifiedReps,
  createSimplifiedRepRulesetTypes,
  editSimplifiedRepRulesetTypes,
  deleteSimplifiedRepRulesetTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.SIMPLIFIED_REP_RULESETS);

export const processFetchAllSimplifiedRepRulesetsForSimplifiedRep = (
  simplifiedRepId
) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllSimplifiedRepRulesetsForIndividualSimplifiedReps,
    `/standardize/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets`,
    {
      mutateResponse: ({
        simplifiedRep = {},
        simplifiedRepRulesets = [],
      }) => ({
        simplifiedRepRulesets: simplifiedRepRulesets.map(
          (ruleset) => ({
            ...ruleset,
            simplifiedRepRulesetRules: ruleset.simplifiedRepRulesetRules.map(
              (rule) => ({
                ...rule,
                simplifiedRepRulesetId: `${ruleset.id}`,
              })
            ),
            simplifiedRepId: `${simplifiedRep.id}`,
          })
        ),
        // simplifiedRep,
      }),
    }
  );

export const processCreateSimplifiedRepRuleset = (
  simplifiedRepId,
  data
) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createSimplifiedRepRulesetTypes,
    `/standardize/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets`,
    {
      data: {
        simplifiedRepRulesets: [data],
      },
      mutateResponse: ({
        simplifiedRep = {},
        simplifiedRepRulesets = [],
      }) => ({
        simplifiedRep,
        simplifiedRepRulesets: simplifiedRepRulesets.map(
          (ruleset) => ({
            ...ruleset,
            simplifiedRepRulesetRules: ruleset.simplifiedRepRulesetRules.map(
              (rule) => ({
                ...rule,
                simplifiedRepRulesetId: `${ruleset.id}`,
              })
            ),
            simplifiedRepId: `${simplifiedRep.id}`,
          })
        ),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Ruleset successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create ruleset',
      },
    }
  );

export const processEditSimplifiedRepRuleset = (
  simplifiedRepId,
  simplifiedRepRulesetId,
  data
) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editSimplifiedRepRulesetTypes,
    `/standardize/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets/${simplifiedRepRulesetId}`,
    {
      data,
      mutateResponse: ({
        simplifiedRep = {},
        simplifiedRepRuleset = {},
      }) => ({
        simplifiedRep,
        simplifiedRepRuleset: {
          ...simplifiedRepRuleset,
          simplifiedRepRulesetRules: simplifiedRepRuleset.simplifiedRepRulesetRules.map(
            (rule) => ({
              ...rule,
              simplifiedRepRulesetId: `${simplifiedRepRuleset.id}`,
            })
          ),
          simplifiedRepId: `${simplifiedRep.id}`,
        },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteSimplifiedRepRuleset = (
  simplifiedRepId,
  simplifiedRepRulesetId
) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteSimplifiedRepRulesetTypes,
    `/standardize/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets/${simplifiedRepRulesetId}`,
    {
      deleteKey: EK.SIMPLIFIED_REP_RULESETS.state,
      dependentDeletionKeys: [
        EK.SIMPLIFIED_REP_RULESET_RULES.state,
        EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state,
      ],
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Ruleset deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete ruleset',
      },
    }
  );
