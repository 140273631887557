import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import { selectNormalizedCurrentUser, selectCurrentUser } from '../../../entities/CurrentUser/selectors';
import { selectNormalizedOrganizations } from '../../../entities/Organizations/selectors';

/**
 * Current User information auth selectors
 */
export const selectCurrentUserIsPresent = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser)
);

export const selectCurrentUserIsNotPresent = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => isEmpty(currentUser)
);

export const selectCurrentUserIsNotPresentOrHasRequiredPasswordResetPending = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => isEmpty(currentUser) || currentUser.passwordResetRequired
);

export const selectCurrentUserHasNoRequiredPasswordResetPending = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && !currentUser.passwordResetRequired
);

export const selectCurrentUserForcedPasswordResetUrl = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => currentUser.passwordResetRequired ? `/password_reset/${currentUser.passwordResetToken}` : '/password_reset'
);

export const selectCurrentUserIsVerified = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.emailVerified
);

export const selectCurrentUserIsActive = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.activeFlag
);

export const selectCurrentUserIsInactive = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && !currentUser.activeFlag
);

export const selectCurrentUserHasOrganization = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && !isEmpty(currentUser.organization) && !!currentUser.organization
);

export const selectCurrentUserHasNoOrganization = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && (isEmpty(currentUser.organization) || !currentUser.organization)
);

export const selectCurrentUserCanAdmin = () => createSelector(
  selectNormalizedCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.isAdmin
);

export const selectCurrentUserCanCollaborate = () => createSelector(
  selectNormalizedCurrentUser(),
  currentUser => !isEmpty(currentUser) && currentUser.isCollaborator
);

export const selectCurrentUserIsOrganizationOwner = () => createSelector(
  selectNormalizedCurrentUser(),
  selectNormalizedOrganizations(),
  (currentUser, organizations) => !isEmpty(currentUser) && organizations.has(currentUser.organization) && currentUser.organization === organizations.get(currentUser.organization).owner
);

export const selectCurrentOrganizationIsValid = () => createSelector(
  selectCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.organization && currentUser.organization.isNotExpired
);

/**
 * Product Auth Selectors
 */
// Synchronize
export const selectCurrentUserHasSynchronizeModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.organization && currentUser.organization.hasProductTierSynchronizePrivileges
);

// Standardize
export const selectCurrentUserHasStandardizeModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.organization && currentUser.organization.hasProductTierStandardizePrivileges
);

// Automate
export const selectCurrentUserHasAutomateModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) => !isEmpty(currentUser) && currentUser.organization && currentUser.organization.hasProductTierAutomatePrivileges
);

/**
 * Module Auth Selectors
 */
// Cabling
export const selectCurrentUserHasCablingModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) =>
    !isEmpty(currentUser) &&
    currentUser.organization &&
    currentUser.organization.hasModuleCablingPrivileges &&
    currentUser.hasModuleCabling
);

// Piping
export const selectCurrentUserHasPipingModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) =>
    !isEmpty(currentUser) &&
    currentUser.organization &&
    currentUser.organization.hasModulePipingPrivileges &&
    currentUser.hasModulePiping
);

// ECAD
export const selectCurrentUserHasEcadModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) =>
    !isEmpty(currentUser) &&
    currentUser.organization &&
    currentUser.organization.hasModuleEcadPrivileges &&
    currentUser.hasModuleEcad
);

// Reporting
export const selectCurrentUserHasReportingModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) =>
    !isEmpty(currentUser) &&
    currentUser.organization &&
    currentUser.organization.hasModuleReportingPrivileges &&
    currentUser.hasModuleReporting
);

// Framework
export const selectCurrentUserHasFrameworkModule = () => createSelector(
  selectCurrentUser(),
  (currentUser) =>
    !isEmpty(currentUser) &&
    currentUser.organization &&
    currentUser.organization.hasModuleFrameworkPrivileges &&
    currentUser.hasModuleFramework
);
