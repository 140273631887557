import LayerSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const layerFilterForm = formValueSelector(
  `filters.${EK.LAYERS.state}`
);

export const selectNormalizedLayers = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.LAYERS.state)
  );

export const selectNormalizedLayersList = () =>
  createSelector(selectNormalizedLayers(), (layers) =>
    layers.toList()
  );

export const selectCurrentFilteredLayers = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedLayers(),
    state => ({
      name: layerFilterForm(state, 'name'),
      description: layerFilterForm(state, 'description')
    }),
    (isFiltering, layers, query) =>
      isFiltering
        ? layers.filter((l) => l.doesMatchQuery(query)).toList()
        : layers.toList()
  );

export const selectCurrentLayersCount = () =>
  createSelector(
    selectNormalizedLayers(),
    (layers) => (layers && layers.toList() && layers.toList().size) || 0
  );

export const selectDenormalizedLayersLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.LAYERS.state),
      [LayerSchema],
      entities
    )
  );