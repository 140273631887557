import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

//grid dependencies
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

import { rowExpansionTemplate } from './components';
import Flex from '../../../components/common/Flex';
import Text from '../../../components/common/Text';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';

//selectors
import { selectCombinedEndTypeCompatibilitiesArray } from '../../../entities/Piping/EndTypeCompatibilities/selectors';
import { selectAllEndTypesArray, isLoadingEndTypesData } from '../../../entities/Piping/EndTypes/selectors';
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';

//actions
import {
  processFetchAllEndTypes,
} from '../../../entities/Piping/EndTypes/actions';
import { processFetchAllEndTypeCompatibilities } from '../../../entities/Piping/EndTypeCompatibilities/actions';
import { handleCompatibilityChange as HCC, saveAllEndTypeChanges, removeItemFromCompatChanges } from './actions';

//general
import colors from '../../../assets/themes/base/colors';
import { BlankCompat, createEndTypeCompatibility } from './constants';

//header
import { PipingModuleEndTypesPath } from '../../../paths';
import { pluralize } from '../../../components/common/Header';

import { selectNormalizedSchedulesList } from '../../../entities/Piping/Schedules/selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCombinedEndTypeCompatibilitiesArray(),
  selectNormalizedSchedulesList(),
  isLoadingEndTypesData(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    compatibilities,
    schedules,
    isLoadingEndTypesData,
  ) => {
    return {
      ...rest,
      editable: canCollaborate && hasValidLicense,
      isLoadingInitialData,
      canCollaborate,
      hasValidLicense,
      compatibilities: compatibilities || [],
      schedules: schedules && schedules.toArray() || [],
      isLoading: isLoadingInitialData || isLoadingEndTypesData,
    };
  },
);

function EndTypesGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const originalRows = useSelector(selectAllEndTypesArray());
  const useableRows = originalRows;
  // const count = 0;

  const [isFiltering, setIsFiltering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [currentEditingRow, setCurrentEditingRow] = useState({});

  const [expandedRows, setExpandedRows] = useState([]);

  const [editedRows, setEditedRows] = useState([]);
  const [changes, setChanges] = useState({
    created: [],
    edited: [],
    deleted: []
  });

  const [newCompatRows, setNewCompatRows] = useState([]);
  const [editedCompatRows, setEditedCompatRows] = useState([]);
  const [compatChanges, setCompatChanges] = useState({
    created: [],
    edited: [],
    deleted: [],
  });

  const [currentEditingCompat, setCurrentEditingCompat] = useState(BlankCompat);

  const onBeforeCellEditShow = (e) => {
    const curRow = { ...(editedRows && editedRows.find(item => item.id == e.rowData.id) || originalRows.find(item => item.id == e.rowData.id)) };
    setCurrentEditingRow(curRow);
    setIsEditing(true);
  };

  const onCellChange = (value, field) => {
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = value;
    setCurrentEditingRow(_editedRow);
  };

  // HCC = handleCompatibilityChange from the ./actions file, Name was changed here to prevent name overlap
  const handleCompatibilityChange = (e, originalRow, field) =>
    HCC(e, originalRow, field, editedCompatRows, compatChanges,
      newCompatRows, setEditedCompatRows, setCompatChanges, !reduxProps.editable || originalRow.isDeleted,
      (originalRow) => removeItemFromCompatChanges(originalRow, compatChanges, setCompatChanges));

  //Cell Editors
  const codeCellEditor = () => <InputText value={currentEditingRow && currentEditingRow.code || ''} onChange={(e) => onCellChange(e.target.value, 'code')} />;

  const nameCellEditor = () => <InputText value={currentEditingRow && currentEditingRow.name || ''} onChange={(e) => onCellChange(e.target.value, 'name')} />;

  const scheduleSelector = (field) => (
    <Dropdown
      optionLabel='value'
      optionValue='id'
      value={currentEditingCompat && currentEditingCompat[field]}
      options={reduxProps.schedules} style={{ width: '90%' }}
      onChange={(e) => handleCompatibilityChange(e, currentEditingCompat, field)}
      filter
    />
  );

  const endTypeSelector = (field) => (
    <Dropdown
      optionLabel='code'
      optionValue='id'
      value={currentEditingCompat && currentEditingCompat[field]}
      options={originalRows.filter(item => !item.id.includes('NEW_ROW'))} style={{ width: '90%' }}
      onChange={(e) => handleCompatibilityChange(e, currentEditingCompat, field)}
      filter
    />
  );

  //Cell displays
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || rowdata;

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const compatEndTypeTextValueBody = (rowdata, field) => {
    let curRow = editedCompatRows && editedCompatRows.find(item => item.id == rowdata.id);
    if(!curRow) {
      curRow = !rowdata.id.includes('NEW_ROW_') ?
        reduxProps.compatibilities.find(item => item.id == rowdata.id) :
        newCompatRows.find(item => item.id == rowdata.id);
    }

    if (curRow[`${field}`] == '') {
      return (
        <Text style={{ color: 'rgba(0, 0, 0, .3)' }} >undefined</Text>
      );
    }

    const curEndType = originalRows.find(item => item.id == curRow[`${field}`]);

    return (
      <>
        <Text>{curEndType && curEndType.code}</Text>
      </>
    );
  };

  const compatScheduleTextValueBody = (rowdata, field) => {
    let curRow = editedCompatRows && editedCompatRows.find(item => item.id == rowdata.id);
    if (!curRow) {
      curRow = !rowdata.id.includes('NEW_ROW_') ?
        reduxProps.compatibilities.find(item => item.id == rowdata.id) :
        newCompatRows.find(item => item.id == rowdata.id);
    }

    if (curRow[`${field}`] == '') {
      return (
        <Text style={{ color: 'rgba(0, 0, 0, .3)' }} >undefined</Text>
      );
    }

    const curSchedule = reduxProps.schedules.find(item => item.id == curRow[`${field}`]);

    return (
      <>
        <Text>{curSchedule && curSchedule.value}</Text>
      </>
    );
  };

  //removes item from changes
  const removeItemFromChanges = (originalItem) => {
    let _changes = { ...changes };
    //the change will only ever be in one of the 'edited', 'created', or 'deleted' arrays
    let editChangeItem = _changes.edited.findIndex(item => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.edited.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }

    editChangeItem = _changes.created.findIndex(item => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.created.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }

    editChangeItem = _changes.deleted.findIndex(item => item.id == originalItem.id);
    if (editChangeItem != -1) {
      _changes.deleted.splice(editChangeItem, 1);
      setChanges(_changes);
      return;
    }
  };

  const resetEditedRows = () => {
    setEditedRows([]);
    setEditedCompatRows([]);
    setNewCompatRows([]);

    setChanges({
      created: [],
      edited: [],
      deleted: [],
    });
    setCompatChanges({
      created: [],
      edited: [],
      deleted: [],
    });
  };

  const removeItemFromRows = (originalRow) => {
    let _rows = [...editedRows];
    const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    _rows.splice(rowsItemIndex, 1);
    setEditedRows(_rows);
  };

  const removeItemFromCompatRows = (originalRow) => {
    let _rows = [...editedCompatRows];
    const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    _rows.splice(rowsItemIndex, 1);
    setEditedCompatRows(_rows);
  };

  const handleRowAction = (item, iconName) => {
    const originalItem = originalRows.find(itm => item.id == itm.id);
    let _rows = [...editedRows];
    let _changes = { ...changes };
    switch (iconName) {
    case 'undo':
      removeItemFromRows(originalItem);
      removeItemFromChanges(originalItem);
      break;
    case 'delete':
      removeItemFromRows(originalItem);
      _rows.push({ ...originalItem, isDeleted: true });
      removeItemFromChanges(originalItem);
      _changes.deleted.push({ ...originalItem });
      setEditedRows(_rows);
      break;
    }
  };

  // Expanded Rows Actions
  const addNewCompatibility = (firstEndTypeId) => {
    let _prevCompatRows = [...newCompatRows];
    _prevCompatRows.splice(0, 0, createEndTypeCompatibility({ ...BlankCompat, id: `NEW_ROW_${_prevCompatRows.length}`, firstEndTypeId: firstEndTypeId }));
    setNewCompatRows(_prevCompatRows);
  };

  const getNewCompatRows = useCallback((endTypeId) => {
    const filteredEditedRows = editedCompatRows.filter(row => row.id.includes('NEW_ROW_') && (row.firstEndTypeId == endTypeId || row.secondEndTypeId == endTypeId));
    const filteredNewRows = newCompatRows.filter(row => !editedCompatRows.find(item => item.id == row.id) && (row.firstEndTypeId == endTypeId));
    return filteredEditedRows.concat(filteredNewRows);
  }, [editedCompatRows, newCompatRows]);

  const onBeforeCompatibilityEditShow = (e) => {
    setCurrentEditingCompat(!e.rowData.id.includes('NEW_ROW_') ?
      reduxProps.compatibilities.find(item => item.id == e.rowData.id) :
      newCompatRows.find(item => item.id == e.rowData.id));
    setIsEditing(true);
  };

  function onCompatibilityEditComplete() { setIsEditing(false); }

  const handleCompatRowAction = (rowData, iconName) => {
    const originalItem = !rowData.id.includes('NEW_ROW_') ?
      reduxProps.compatibilities.find(item => item.id == rowData.id) :
      newCompatRows.find(item => item.id == rowData.id);
    let _rows = [...editedCompatRows];
    let _changes = { ...compatChanges };
    switch (iconName) {
    case 'undo':
      removeItemFromCompatRows(originalItem);
      removeItemFromCompatChanges(originalItem, compatChanges, setCompatChanges);
      break;
    case 'delete':
      removeItemFromCompatRows(originalItem);
      _rows.push({ ...originalItem, isDeleted: true });
      removeItemFromCompatChanges(originalItem, compatChanges, setCompatChanges);
      _changes.deleted.push({ ...originalItem });
      setEditedCompatRows(_rows);
      setCompatChanges(_changes);
      break;
    }
  };

  const getCompatRowTool = (rowdata) => {
    let curRow = editedCompatRows && editedCompatRows.find(item => item.id == rowdata.id);
    if (!curRow) {
      curRow = !rowdata.id.includes('NEW_ROW_') ?
        reduxProps.compatibilities.find(item => item.id == rowdata.id) :
        newCompatRows.find(item => item.id == rowdata.id);
    }

    return (
      <EntityRowTools rowdata={curRow} handleRowAction={handleCompatRowAction} />
    );
  };

  // entity row start tools
  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || originalRows.find(item => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  //onCellEditComplete methods
  const onCellEditCompleteRef = useRef(null);

  const onCellEditComplete = (e) => {
    setIsEditing(false);
    const originalRow = originalRows.find(item => item.id == e.rowData.id);
    let _editedAppearance = { ...currentEditingRow };

    if (!reduxProps.editable) {
      // user does not have permission to edit ( 'user' level permissions or invalid license )
      return;
    }

    if (_editedAppearance.isDeleted || _editedAppearance.id != originalRow.id) {
      return;
    }

    let _rows = [...editedRows];
    let _changes = { ...changes };

    // if the item is the same as the original item, remove it from the rows
    if (JSON.stringify({ ..._editedAppearance, isEdited: false, isDeleted: false, isCreated: false }) === JSON.stringify({ ...originalRow })) {
      const rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
      if (rowsItemIndex != -1) {
        _rows.splice(rowsItemIndex, 1);
        removeItemFromChanges(originalRow);
        setEditedRows(_rows);
        return;
      }
      return;
    }

    // if it is a new item, add it to rows and changes
    if (originalRow.id.includes('NEW_ROW')) {
      let editedRow = _rows.find(item => item.id == originalRow.id);
      let changeRow = _changes.created.find(item => item.id == originalRow.id);
      if (editedRow) { // row has already been created/edited
        editedRow[e.field] = _editedAppearance[e.field];
        changeRow[e.field] = _editedAppearance[e.field];
      } else { // row needs to be added to the 'created rows' list
        _editedAppearance.isEdited = true;
        _editedAppearance.isCreated = true;
        _rows.push(_editedAppearance);
        _changes.created.push(_editedAppearance);
      }
      setEditedRows(_rows);
      setChanges(_changes);
      return;
    }

    // if it is an existing item, add it to rows and changes
    let currentRowItem = _rows && _rows.find(item => item.id == originalRow.id);
    let changesItem = _changes.edited && _changes.edited.find(item => item.id == originalRow.id);
    if (currentRowItem) {
      //if the item is already in rows and changes, edit it
      currentRowItem[e.field] = _editedAppearance[e.field];
      changesItem[e.field] = _editedAppearance[e.field];
    } else {
      //if it is not, then add it to them
      _editedAppearance.isEdited = true;
      _rows.push(_editedAppearance);
      _changes.edited.push(_editedAppearance);
    }
    setEditedRows(_rows);
    setChanges(_changes);
  };

  // editor={(rowdata) => TextEditor(rowdata, code)} body={NameBody} onBeforeCellEditShow={onBeforeCellEditShow} onCellEditComplete={(e) => onCellEditCompleteRef.current(e)}

  useEffect(() => {
    //ref to solve value persistence (updates the function to use current values on state change)
    onCellEditCompleteRef.current = onCellEditComplete;
  }, [currentEditingRow]);

  useEffect(() => {
    dispatch(processFetchAllEndTypes());
    dispatch(processFetchAllEndTypeCompatibilities());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleEndTypesPath.defaultTitle}
          subtitle={isFiltering ? `Showing ${filtered.size}/${originalRows.length-10} ${pluralize('filtered End Type', originalRows.length-10)}` : `Showing ${originalRows.length-10} ${pluralize('End Type', originalRows.length-10)} Total`}
        />
        <Options
          updateEntities={() => saveAllEndTypeChanges(
            dispatch,
            changes,
            compatChanges,
            resetEditedRows,)}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={changes.created.length > 0 || changes.deleted.length > 0 || changes.edited.length > 0 || compatChanges.created.length > 0 || compatChanges.deleted.length > 0 || compatChanges.edited.length > 0 }
          canAdmin={reduxProps.canAdmin}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex> || <Skeleton style={{height: '2rem', marginBottom: '1rem'}} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={useableRows}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={(data) =>
            rowExpansionTemplate(
              reduxProps.compatibilities.filter(
                row => row.firstEndTypeId == data.id || row.secondEndTypeId == data.id
              ).concat(
                getNewCompatRows(data.id)
              ),
              data,
              compatEndTypeTextValueBody,
              compatScheduleTextValueBody,
              getCompatRowTool,
              scheduleSelector,
              endTypeSelector,
              addNewCompatibility,
              onBeforeCompatibilityEditShow,
              onCompatibilityEditComplete,
              editedCompatRows
            )}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            expander={(rowdata) => !rowdata.id.includes('NEW_ROW_')}
            style={{ width: '5rem', textAlign: 'left' }}
          />
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='code'
            header='Code'
            style={{ width: '40%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={codeCellEditor}
            onCellEditComplete={(e) => onCellEditCompleteRef.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'code', 'Code')}
            sortable
          />
          <Column
            field='name'
            header="Description"
            style={{ width: '58%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={nameCellEditor}
            onCellEditComplete={(e) => onCellEditCompleteRef.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'name', 'Description')}
            sortable
          />
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(EndTypesGrid);