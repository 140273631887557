import CombinedStateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const combinedStateFilterForm = formValueSelector(
  `filters.${EK.COMBINED_STATES.state}`
);

export const selectNormalizedCombinedStates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.COMBINED_STATES.state)
  );

export const selectNormalizedCombinedStatesList = () =>
  createSelector(selectNormalizedCombinedStates(), (combinedStates) =>
    combinedStates.toList()
  );

export const selectCurrentFilteredCombinedStates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedCombinedStates(),
    state => ({
      name: combinedStateFilterForm(state, 'name'),
      description: combinedStateFilterForm(state, 'description')
    }),
    (isFiltering, combinedStates, query) =>
      isFiltering
        ? combinedStates.filter((cs) => cs.doesMatchQuery(query)).toList()
        : combinedStates.toList()
  );

export const selectCurrentCombinedStatesCount = () =>
  createSelector(
    selectNormalizedCombinedStates(),
    (combinedStates) => (combinedStates && combinedStates.toList() && combinedStates.toList().size) || 0
  );

export const selectDenormalizedCombinedStatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.COMBINED_STATES.state),
      [CombinedStateSchema],
      entities
    )
  );