import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../../modules/entities/selectors';
import PipeFamilySchema from './schema';

import { formValueSelector } from 'redux-form/immutable';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

import EK from '../../keys';

export const selectNormalizedPipeFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.PIPES.state)
);
export const selectNormalizedUnarchivedPipeFamilies = () => createSelector(
  selectNormalizedPipeFamilies(),
  (pipes) =>
    pipes.filter((pipe) => !pipe.archivedFlag)
);

export const selectPipeFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PIPES.state), [PipeFamilySchema], entities)
);
export const selectPipeFamiliesList = () => createSelector(
  selectPipeFamilies(),
  pipeFamilies => pipeFamilies.toList()
);

export const selectPipeFamiliesCounts = () => createSelector(
  selectNormalizedPipeFamilies(),
  pipes => pipes.reduce(({ unarchived, total }, pipe) => ({
    unarchived: !pipe.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

const pipeFilterForm = formValueSelector(`filters.${EK.PIPES.state}`);

export const selectCurrentFilteredPipes = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectPipeFamilies(),
  state => pipeFilterForm(state, 'name', 'material', 'crossSectionShape', 'lineType'),
  (isFiltering, isShowingArchived, pipes, query) =>
    isFiltering ?
      pipes.filter(pipe => (isShowingArchived || !pipe.archivedFlag) && pipe.doesMatchQuery(query)) :
      pipes.filter(pipe => !pipe.archivedFlag)
);