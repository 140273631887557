import generateAPHTypes from '../../../utils/constants/generateAPHTypes';
import { List } from 'immutable';

const resource = 'AUTOMATE_ROUTING_PROFILES';

export const ALGORITHM_TYPES = {
  ANT_SYSTEM: 'ant_system',
  ANT_COLONY_SYSTEM: 'ant_colony_system',
  MIN_MAX_ANT_SYSTEM: 'min_max_ant_system',
  A_STAR_MIN_MAX_ANT_SYSTEM: 'a_star_min_max_ant_system'
};

export const ALGORITHM_OPTIONS = [
  { value: ALGORITHM_TYPES.ANT_SYSTEM, label: 'Ant System' },
  { value: ALGORITHM_TYPES.ANT_COLONY_SYSTEM, label: 'Ant Colony System' },
  { value: ALGORITHM_TYPES.MIN_MAX_ANT_SYSTEM, label: 'Min Max Ant System' },
  { value: ALGORITHM_TYPES.A_STAR_MIN_MAX_ANT_SYSTEM, label: 'A* Min Max Ant System' }
];

export const ALGORITHM_OPTIONS_LIST = List(ALGORITHM_OPTIONS);

export const fetchAllRoutingProfiles = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createRoutingProfile = generateAPHTypes(
  resource,
  'CREATE'
);

export const editRoutingProfile = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteRoutingProfile = generateAPHTypes(
  resource,
  'DELETE'
);

export const copyRoutingProfile = generateAPHTypes(
  resource,
  'COPY'
);

export const archiveRoutingProfile = generateAPHTypes(
  resource,
  'ARCHIVE'
);

export const unarchiveRoutingProfile = generateAPHTypes(
  resource,
  'UNARCHIVE'
);