import { toast } from 'react-toastify';

import EK from '../../keys';
import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllCatalogs,
  createCatalog,
  editCatalog,
  deleteCatalog,
  copyCatalog,
  archiveCatalog,
} from './constants';
import schema from './schema';

const client = api(schema, EK.ECAD_CATALOGS);

export const processFetchAllCatalogs = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllCatalogs,
    '/ecad/catalogs'
  );

export const processCreateCatalog = data =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createCatalog,
    '/ecad/catalogs',
    {
      data: {
        catalogs: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Catalog successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create catalog',
      },
    }
  );

export const processEditCatalog = (catalogId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editCatalog,
    `/ecad/catalogs/${catalogId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteCatalog = catalogId =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteCatalog,
    `/ecad/catalogs/${catalogId}?with_full_detail=true`,
    {
      deleteKey: EK.ECAD_CATALOGS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Catalog deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete catalog',
      },
    }
  );

export const processCopyCatalog = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyCatalog,
    '/ecad/catalogs/copy',
    {
      data: {
        catalogs: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Catalog successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy catalog',
      },
    }
  );