/**
 * Spacing is based on 4px/8px grid
 * Assumes base font size of 16px
 * Values are given as rem, and when converted into px, should be the following values:
 * [ 2, 4, 8, 12, 16, 24, 32, 40, 48, 56, 72, 96 ]
 */

export default [
  '0.125rem',
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '3rem',
  '3.5rem',
  '4.5rem',
  '6rem'
];
