import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import Button from '../../../components/common/Button';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { Dropdown } from 'primereact/dropdown';

// Header
import Flex from '../../../components/common/Flex';
import {
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// dropdown constants and validation
import {
  BlankSpec,
  ALLOWANCES,
  VALIDATION_FIELDS,
} from '../../../entities/Piping/Specs/model';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { areFieldsValid, doRequiredFieldsExist } from '../Specs/components';

// Actions
import { processEditFittingFamily, processFetchIndividualFittingFamily, processFetchFittingFamiliesForSpec } from '../../../entities/Piping/FittingFamilies/actions';
import { saveCurrentPipingSpecFittinginstanceChanges } from './actions';
import { convertToSpecFittingInstanceType } from '../SpecsFittingsEdit/actions';
import { processEditSpec, processFetchIndividualSpec } from '../../../entities/Piping/Specs/actions';
import { processSaveSpecFittingInstances } from '../../../entities/Piping/SpecFittingInstances/actions';
import { processFetchAllBoltNutFamilies } from '../../../entities/Piping/BoltNutFamilies/actions';
import { processFetchAllSchedules } from '../../../entities/Piping/Schedules/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectCurrentFilteredSpecFittingInstances } from '../../../entities/Piping/SpecFittingInstances/selectors';
import { selectCurrentSpec } from '../../../entities/Piping/Specs/selectors';
import { selectFittingInstanceCount } from '../../../entities/Piping/FittingInstances/selectors';
import { selectCurrentFittingFamily } from '../../../entities/Piping/FittingFamilies/selectors';
import { selectBoltNutFamilies } from '../../../entities/Piping/BoltNutFamilies/selectors';

const mapStateToProps = (specId, FittingId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(specId),
  selectCurrentFittingFamily(FittingId),
  selectCurrentFilteredSpecFittingInstances(specId, FittingId),
  selectFittingInstanceCount(FittingId),
  selectBoltNutFamilies(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    fitting,
    data,
    instanceCount,
    boltNuts,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && !fitting?.archivedFlag && !spec?.archivedFlag,
      canCollaborate,
      hasValidLicense,
      instanceCount,
      spec,
      fitting,
      boltNuts: boltNuts && boltNuts.toList().toArray() || [],
    };
  },
);

function FittingsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id, match.params.familyId));
  const dispatch = useDispatch();

  const [
    editedRows,
    resetEditedRows,
    currentEditingRow,
    isEditing,
    onBeforeCellEditShow,
    onCellChange,
    onCellEditComplete,
    handleRowAction,
    onDropdownComplete
  ] = useOnCellEditComplete(reduxProps.data, convertToSpecFittingInstanceType, reduxProps.editable, true);

  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5] }} >{placeholder}</Text>;
  };

  const cadModelNameBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);

    if (!curRow?.fittingInstance?.cadModelName) {
      return (
        <Text
          className='text-cell-body'
          style={{ color: colors.gray[5] }}
        >
          Summary Not Available
        </Text>
      );
    }

    return (
      <ComplexCellRenderer
        value={{
          primary: String(curRow.fittingInstance.cadModelName),
        }}
        isCapitalized={true}
        paddingLeft='0px'
      />
    );
  };

  const scheduleBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);

    if (!curRow?.fittingInstance?.schedule) {
      return (
        <Text
          className='text-cell-body'
          style={{ color: colors.gray[5] }}
        >
          No Schedule
        </Text>
      );
    }

    const schedule = curRow.fittingInstance.schedule;
    return (
      <ComplexCellRenderer
        value={{
          primary: String(schedule.name || schedule.value || 'No Schedule'),
          secondary: String(schedule.description || '')
        }}
        isCapitalized={true}
        paddingLeft='0px'
      />
    );
  };

  const sizeBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);

    if (!curRow?.fittingInstance || !curRow.fittingInstance[field]) {
      return (
        <Text
          className='text-cell-body'
          style={{ color: colors.gray[5] }}
        >
          No Size
        </Text>
      );
    }

    const size = curRow.fittingInstance[field];
    return (
      <ComplexCellRenderer
        value={{
          primary: String(size.name || size.value || 'No Size'),
          secondary: String(size.description || '')
        }}
        isCapitalized={true}
        paddingLeft='0px'
      />
    );
  };

  const BoltNutsBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);

    if (!curRow?.boltNutFamily?.name) {
      return (
        <Text
          className='text-cell-body'
          style={{ color: colors.gray[5] }}
        >
          No Bolt Nut Family
        </Text>
      );
    }

    return (
      <ComplexCellRenderer
        value={{
          primary: String(curRow.boltNutFamily.name),
          secondary: String(curRow.boltNutFamily.boltNutCode || '')
        }}
        isCapitalized={true}
        paddingLeft='0px'
      />
    );
  };

  // Cell Editors
  const textCellEditor = (field) => {
    return <InputText
      value={currentEditingRow && currentEditingRow[field] || ''}
      onChange={(e) => onCellChange(e.target.value, field)}
    />;
  };

  const instanceSelector = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToSpecFittingInstanceType(rowdata);
    return <Button
      icon={curRow.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'}
      iconFillColor={curRow.isSelectedForSpec ? 'primary.4' : 'gray.6'}
      onClick={() => onDropdownComplete.current(!curRow.isSelectedForSpec, 'isSelectedForSpec', rowdata)}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  const boltNutEditor = () => {
    return (
      <Dropdown
        optionLabel='name'
        value={currentEditingRow.boltNutFamily}
        options={reduxProps.boltNuts}
        onChange={(e) => onDropdownComplete.current(e.value, 'boltNutFamily', currentEditingRow)}
        itemTemplate={(item) => ComplexCellRenderer({
          value: { primary: item.name, secondary: item.boltNutCode }, placeholder: '', isCapitalized: false
        })}
        filter
      />
    );
  };

  useEffect(() => {
    dispatch(processFetchAllSchedules());
    dispatch(processFetchAllBoltNutFamilies());
    dispatch(processFetchFittingFamiliesForSpec());
    dispatch(processFetchIndividualFittingFamily(match.params.familyId));
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={reduxProps.spec && reduxProps.fitting && `${reduxProps.spec.specName} // ${reduxProps.fitting.name}` || PipingModuleSpecsFittingInstancesEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Fitting Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Fitting Instance', reduxProps.instanceCount)} total (${reduxProps.spec?.specFittingInstances?.filter(inst => inst?.fittingFamilyId?.id == match.params.familyId).size || 0} selected)`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          updateEntities={() => {
            saveCurrentPipingSpecFittinginstanceChanges(editedRows, (rows) => {
              dispatch(processEditSpec(match.params.id, { specFittingInstancesAttributes: rows }));
              resetEditedRows();
            });
          }}
          pendingValidChanges={editedRows.length > 0}
          isEditing={isEditing}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column header='' style={{ width: '2%' }} body={(rowdata) => instanceSelector(rowdata)} ></Column>
          <Column
            field='id'
            header='CAD Model Name'
            body={(rowdata) => cadModelNameBody(rowdata)}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            field='port0Size'
            header='Port 0 Size'
            body={(rowdata) => sizeBody(rowdata, 'port0Size')}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='port1Size'
            header='Port 1 Size'
            body={(rowdata) => sizeBody(rowdata, 'port1Size')}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='schedule'
            header='Schedule'
            body={scheduleBody}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='selectionName'
            style={{ width: '15%' }}
            header='Selection Name'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('selectionName')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'selectionName', 'Selection Name')}
            sortable
          />
          <Column
            field='stockno'
            style={{ width: '10%' }}
            header='Stock No'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('stockno')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'stockno', 'Stockno')}
            sortable
          />
          <Column
            field='mccsCode'
            style={{ width: '10%' }}
            header='MCCS Code'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('mccsCode')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'mccsCode', 'MCCS Code')}
            sortable
          />
          <Column
            field='boltNutFamily'
            style={{ width: '10%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => boltNutEditor()}
            header='Bolt Nut Family'
            body={(rowdata) => BoltNutsBody(rowdata)}
            sortable
          />
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(FittingsEditGrid);