import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.INSULATIONS.state;

export const fetchAllInsulationFamilys = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualInsulationFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createInsulationFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyInsulationFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editInsulationFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteInsulationFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveInsulationFamilys = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveInsulationFamilys = generateAPHTypes(resource, 'UNARCHIVE');
