import React from 'react';
import styled from 'styled-components';

import CadactiveHelmet from '../../../components/common/CadactiveHelmet';

import Box from '../../../components/common/Box';
import Flex from '../../../components/common/Flex';

const ExternalFullPageContainer = styled(Box)`
  width: 70%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ExternalFullPage = ({ title, children }) => (
  <Flex
    position='absolute'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    top='0px'
    right='0px'
    bottom='0px'
    left='0px'
  >
    <CadactiveHelmet title={title} />

    <ExternalFullPageContainer>
      { children }
    </ExternalFullPageContainer>
  </Flex>
);

export default ExternalFullPage;
