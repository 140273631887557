import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

import GlobalCadSearchRuleSchema from './schema';

export const selectNormalizedGlobalCadSearchRules = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.GLOBAL_CAD_SEARCH_RULES.state)
  );
export const selectNormalizedGlobalCadSearchRulesList = () =>
  createSelector(
    selectNormalizedGlobalCadSearchRules(),
    (globalCadSearchRules) => globalCadSearchRules.toList()
  );

export const selectGlobalCadSearchRulesAndCriteria = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.GLOBAL_CAD_SEARCH_RULES.state),
      [GlobalCadSearchRuleSchema],
      entities
    )
  );

export const selectGlobalCadSearchRulesAndCriteriaForStandardizeSimplifiedReps = () =>
  createSelector(
    selectGlobalCadSearchRulesAndCriteria(),
    (rules) => rules.filter(rule => rule.isValidForSimplifiedRep)
  );
