import { take, put, debounce, fork } from 'redux-saga/effects';

import {
  createNoteBlocks,
  editNoteBlocks,
  deleteNoteBlocks,
  sortNoteBlockNotes,
} from '../../../entities/Standardize/NoteBlocks/constants';

import {
  createNoteBlockNotes,
  editNoteBlockNotes,
  deleteNoteBlockNotes,
} from '../../../entities/Standardize/NoteBlockNotes/constants';

import { setNoteProfileEditStatus } from './actions';

export function* watchNoteProfilesEditSaga() {
  // and we leave this while-true loop here to make sure the saga doesnt end
  while (true) {
    yield take([
      createNoteBlocks.REQUEST,
      editNoteBlocks.REQUEST,
      deleteNoteBlocks.REQUEST,
      sortNoteBlockNotes.REQUEST,
      createNoteBlockNotes.REQUEST,
      editNoteBlockNotes.REQUEST,
      deleteNoteBlockNotes.REQUEST,
    ]);

    yield put(setNoteProfileEditStatus(true));

    yield debounce(
      1500,
      [
        createNoteBlocks.SUCCESS,
        createNoteBlocks.FAILURE,
        editNoteBlocks.SUCCESS,
        editNoteBlocks.FAILURE,
        deleteNoteBlocks.SUCCESS,
        deleteNoteBlocks.FAILURE,
        sortNoteBlockNotes.SUCCESS,
        sortNoteBlockNotes.FAILURE,
        createNoteBlockNotes.SUCCESS,
        createNoteBlockNotes.FAILURE,
        editNoteBlockNotes.SUCCESS,
        editNoteBlockNotes.FAILURE,
        deleteNoteBlockNotes.SUCCESS,
        deleteNoteBlockNotes.FAILURE,
      ],
      function* () {
        yield put(setNoteProfileEditStatus(false));
      }
    );
  }
}

// final output saga
export default function* main() {
  yield fork(watchNoteProfilesEditSaga);
}
