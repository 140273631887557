import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rowStyles } from '../../../components/primeGrid/RowStyles';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { Dropdown } from 'primereact/dropdown';
import Text from '../../../components/common/Text';
import { InputNumber } from 'primereact/inputnumber';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { InputText } from 'primereact/inputtext';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModuleBoltNutsEditPath } from '../../../paths';

//actions
import {
  processFetchIndividualBoltNutFamily,
  processEditBoltNutFamily,
  processArchiveBoltNutFamilies,
  processUnarchiveBoltNutFamilies
} from '../../../entities/Piping/BoltNutFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Piping/Sizes/actions';
import { processSaveBoltNutInstances } from '../../../entities/Piping/BoltNutInstances/actions';
import { processFetchAllSchedules } from '../../../entities/Piping/Schedules/actions';

// Family Dialog
import { BoltNutDialog } from '../../../containers/dialog/templates/Piping/BoltNutDialog';
import { doRequiredFieldsExist, areFieldsValid } from '../BoltNuts/components';
import { VALIDATION_FIELDS } from '../../../entities/Piping/BoltNutFamilies/model';

// misc.
import { convertToBoltNutInstanceType, saveCurrentBoltNutInstanceChanges } from './actions';
import colors from '../../../assets/themes/base/colors';

// Selectors
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Piping/Sizes/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectCurrentBoltNutFamily } from '../../../entities/Piping/BoltNutFamilies/selectors';
import { selectCurrentBoltNutInstancesForPage, selectBoltNutInstanceCount } from '../../../entities/Piping/BoltNutInstances/selectors';
import { selectNormalizedSchedules } from '../../../entities/Piping/Schedules/selectors';

const mapStateToProps = (familyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBoltNutFamily(familyId),
  selectCurrentBoltNutInstancesForPage(familyId),
  selectNormalizedSizes(),
  selectNormalizedSchedules(),
  selectBoltNutInstanceCount(familyId),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    boltNut,
    data,
    sizes,
    schedules,
    instanceCount,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && boltNut && !boltNut.archivedFlag,
      boltNut,
      boltNutId: boltNut && boltNut.id || null,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes?.toList().toArray() || [],
      schedules: schedules?.toList().toArray() || [],
      isFiltering,
    };
  },
);

function BoltNutsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedFamily, setEditedFamily] = useState(reduxProps.boltNut);

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToBoltNutInstanceType, reduxProps.editable, true);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedFamily };
    _editedRow[`${field}`] = newValue;
    setEditedFamily(_editedRow);
  };

  const openEditDialog = () => {setEditedFamily({ id: reduxProps.boltNutId, name: reduxProps.boltNut.name, boltNutCode: reduxProps.boltNut.boltNutCode, description: reduxProps.boltNut.description }); setIsDialogOpen(true);};

  const saveBendAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedFamily));
    if (!doRequiredFieldsExist(editedFamily)) {
      return;
    }

    const editedRow = {
      id: editedFamily.id,
      name: editedFamily.name,
      boltNutCode: editedFamily.boltNutCode,
      description: editedFamily.description
    };

    dispatch(processEditBoltNutFamily(reduxProps.boltNutId, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBoltNutInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToBoltNutInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  // Cell Editors
  const textCellEditor = (field) =>
    <InputText value={currentEditingRow[field]} style={{ width: '100%' }} onChange={(e) => onCellChange(e.target.value, field)} />;

  const numberCellEditor = (field) =>
    <InputNumber value={currentEditingRow[field]} style={{ width: '100%' }} onChange={(e) => onCellChange(e.value, field)} />;

  const sizeDropdown = () => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow.sizeProperty}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, 'sizeProperty', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const scheduleDropdown = () => {
    return <Dropdown
      value={currentEditingRow.schedule?.id}
      options={reduxProps.schedules}
      optionLabel='value'
      optionValue='id'
      filter={true}

      itemTemplate={(value) => ComplexCellRenderer({ value: value, isCapitalized: true })}
      valueTemplate={() => ComplexCellRenderer({ value: currentEditingRow.schedule, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(reduxProps.schedules.find(item => item.id == e.value), 'schedule', currentEditingRow)}
      style={{ width: '100%' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualBoltNutFamily(match.params.id));
    dispatch(processFetchAllSizes());
    dispatch(processFetchAllSchedules());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.boltNut?.name) || PipingModuleBoltNutsEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Bolt Nut Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Bolt Nut Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentBoltNutInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveBoltNutInstances(reduxProps.boltNutId, data)); resetEditedRows(); },
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={openEditDialog}
          isArchived={reduxProps.boltNut?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveBoltNutFamilies([reduxProps.boltNutId]))}
          unarchiveEntity={() => dispatch(processUnarchiveBoltNutFamilies([reduxProps.boltNutId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='sizeProperty'
            header='Size'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => sizeDropdown()}
            body={(rowdata) => sBody(rowdata, 'sizeProperty', 'Select Size')}
            style={{ width: '14%' }}
          />
          <Column
            field='schedule'
            header='Schedule'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => scheduleDropdown()}
            body={(rowdata) => sBody(rowdata, 'schedule', 'Select Schedule')}
            style={{ width: '14%' }}
          />
          <Column
            field='mccsCode'
            style={{ width: '14%' }}
            header='MCCS Code'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('mccsCode')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'mccsCode', 'MCCS Code')}
            sortable
          />
          <Column
            field='bulkItemName'
            style={{ width: '14%' }}
            header='Bulk Item Name'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('bulkItemName')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'bulkItemName', 'Bulk Item Name')}
            sortable
          />
          <Column
            field='quantity'
            style={{ width: '14%' }}
            header='Quantity'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => numberCellEditor('quantity')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'quantity', 'Quantity')}
            sortable
          />
          <Column
            field='boltType'
            style={{ width: '14%' }}
            header='Bolt Type'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('boltType')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'boltType', 'Bolt Type')}
            sortable
          />
          <Column
            field='materialDescription'
            style={{ width: '14%' }}
            header='Material Description'
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('materialDescription')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'materialDescription', 'Material Description')}
            sortable
          />
        </DataTable>
      </Flex>
      <BoltNutDialog
        currentEditingRow={editedFamily}
        editable={reduxProps.editable}
        getDialogHeader={() => `Edit ${editedFamily.name}`}
        handleEditedRowChange={handleEditedDialogChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveBendAction}
        cancelDialogs={() => setIsDialogOpen(false)}
        validation={validation}
      />
    </>);
}

export default withRouter(BoltNutsEditGrid);