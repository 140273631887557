import { createSelector } from 'reselect';
import { Map } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { formValueSelector } from 'redux-form/immutable';

const templateFilterForm = formValueSelector(`filters.${EK.REPORTING_TEMPLATES.state}`);

export const selectTemplates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.REPORTING_TEMPLATES.state, Map())
  );

export const selectCurrentFilteredTemplates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectTemplates(),
    state => templateFilterForm(state, 'name', 'description', 'status'),
    (isFiltering, templates, query) => isFiltering ?
      templates.filter(template => template.doesMatchQuery(query)) :
      templates
  );

export const selectCurrentTemplatesCount = () =>
  createSelector(selectTemplates(), (entities) => entities.size);

export const selectNormalizedTemplates = () =>
  createSelector(selectTemplates(), templates => templates);