import styled from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import shadows from '../../../assets/themes/base/shadows';

import Box from '../../common/Box';

const BaseOptions = styled(Box).attrs(_ => ({
  tabIndex: '-1',
  bg: 'white',
  border: 1,
  $borderRadii: 1,
}))`
  box-shadow: ${shadows[2]};
  overflow: hidden;
  border-color: ${colors.gray[4]};

  & > :first-child {
    border-top-left-radius: ${radii[1]};
    border-top-right-radius: ${radii[1]};
  }

  & > :last-child {
    border-bottom-left-radius: ${radii[1]};
    border-bottom-right-radius: ${radii[1]};
  }

  &:focus,
  &:active {
    outline: none;
    border-color: ${colors.gray[4]};
  }
`;

export default BaseOptions;