import { compose } from 'redux';
import { Switch } from 'react-router-dom';

import injectSaga from '../../../utils/sagas/injectSaga';

import saga from './sagas';

const enhance = compose(
  injectSaga({ key: 'automateModule', saga }),
);

export default enhance(Switch);