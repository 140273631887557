import { toast } from 'react-toastify';

import EK from '../../keys';
import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllTemplates,
  createTemplate,
  editTemplate,
  deleteTemplate,
  copyTemplate,
  archiveTemplate,
} from './constants';
import schema from './schema';

const client = api(schema, EK.REPORTING_TEMPLATES);

export const processFetchAllTemplates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllTemplates,
    '/reporting/templates'
  );

export const processCreateTemplate = data =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createTemplate,
    '/reporting/templates',
    {
      data: {
        templates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create template',
      },
    }
  );

export const processEditTemplate = (templateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editTemplate,
    `/reporting/templates/${templateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteTemplate = templateId =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteTemplate,
    `/reporting/templates/${templateId}?with_full_detail=true`,
    {
      deleteKey: EK.REPORTING_TEMPLATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete template',
      },
    }
  );

export const processCopyTemplate = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyTemplate,
    '/reporting/templates/copy',
    {
      data: {
        templates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy template',
      },
    }
  );