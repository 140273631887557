import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'ORGANIZATION_USER_INVITE';

export const fetchAllOrganizationUserInvites = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchOrganizationUserInviteByTokenTypes = generateAPHTypes(resource, 'FETCH');

export const createOrganizationUserInvites = generateAPHTypes(resource, 'CREATE');

export const deleteOrganizationUserInvites = generateAPHTypes(resource, 'DELETE');

export const acceptOrganizationUserInvites = generateAPHTypes(resource, 'ACCEPT');

export const resendOrganizationUserInvites = generateAPHTypes(resource, 'RESEND');