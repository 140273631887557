import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'EXPLODE_STATES';

export const fetchAllExplodeStates = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createExplodeStates = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyExplodeStates = generateAPHTypes(
  resource,
  'COPY'
);

export const editExplodeStates = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteExplodeStates = generateAPHTypes(
  resource,
  'DELETE'
);