import React, {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';

//Components
import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import Header from './Header';
import Options from '../../templates/Structures/Options';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import Box from '../../../components/common/Box/index';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import TextCell from '../../../components/grid/TextCell';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import { pluralize } from '../../../components/common/Header';

//Selectors
import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { selectParameterProfiles } from '../../../entities/Standardize/ParameterProfiles/selectors';
import { selectNoteProiles } from '../../../entities/Standardize/NoteProfiles/selectors';
import { selectOrientations } from '../../../entities/Standardize/Orientations/selectors.js';
import { selectDenormalizedSimplifiedRepsLists } from '../../../entities/Standardize/SimplifiedReps/selectors';
import { selectDenormalizedLayersLists } from '../../../entities/Standardize/Layers/selectors';
import { selectSections } from '../../../entities/Standardize/Sections/selectors';
import { selectCurrentFilteredModelTemplates, selectCurrentModelTemplatesCount } from '../../../entities/Standardize/ModelTemplates/selectors';

//Actions
import {
  processFetchAllModelTemplates,
  processCreateModelTemplate,
  processCopyModelTemplate,
  processEditModelTemplate,
  processDeleteModelTemplate,
  processArchiveModelTemplates,
  processUnarchiveModelTemplates,
} from '../../../entities/Standardize/ModelTemplates/actions';

import { processFetchAllNoteProfiles } from '../../../entities/Standardize/NoteProfiles/actions';
import { processFetchAllOrientations } from '../../../entities/Standardize/Orientations/actions.js';
import { processFetchAllSimplifiedReps } from '../../../entities/Standardize/SimplifiedReps/actions';
import { processFetchAllLayers } from '../../../entities/Standardize/Layers/actions';
import { processFetchAllParameterProfiles } from '../../../entities/Standardize/ParameterProfiles/actions.js';
import { processFetchAllSections } from '../../../entities/Standardize/Sections/actions.js';

//Constants
import {
  CAD_MODEL_TYPES,
  CAD_MODEL_TYPE_OPTIONS,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
  CAD_MODEL_DRAWING_SUBTYPE_OPTIONS,
} from '../../../entities/Standardize/ModelTemplates/model';
import { StandardizeModuleModelTemplatesPath } from '../../../paths';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredModelTemplates(),
  selectSidebarIsFiltering(),
  selectCurrentModelTemplatesCount(),
  selectIsShowingArchived(),
  selectSections(),
  selectDenormalizedLayersLists(),
  selectDenormalizedSimplifiedRepsLists(),
  selectOrientations(),
  selectNoteProiles(),
  selectParameterProfiles(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    isFiltering,
    count,
    isShowingArchived,
    sections,
    layers,
    simplifiedReps,
    orientations,
    noteProfiles,
    parameterProfiles,
  ) => {
    const isLoading = isLoadingInitialData || (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      sections: (!isLoading && sections && sections.toList().toArray()) || [],
      layers: (!isLoading && layers && layers.toList().toArray()) || [],
      simplifiedReps: (!isLoading && simplifiedReps && simplifiedReps.toList().toArray()) || [],
      orientations: (!isLoading && orientations && orientations.toList().toArray()) || [],
      noteProfiles: (!isLoading && noteProfiles && noteProfiles.toList().toArray()) || [],
      parameterProfiles: (!isLoading && parameterProfiles && parameterProfiles.toList().toArray()) || [],
      isLoading,
      count,
      isFiltering,
      hasValidLicense,
      canCollaborate,
    };
  }
);

const BLANK_TEMPLATE = {
  name: '',
  modelTemplateModelName: '',
  cadModelType: '',
  cadModelAssemblySubtype: '',
  cadModelPartSubtype: '',
  cadModelDrawingSubtype: '',
  modelParameterProfile: '',
  componentParameterProfile: '',
  noteProfile: '',
  orientations: '',
  layers: '',
  sections: '',
  simplifiedReps: '',
  instructionCreateSkeletonComponent: false,
  instructionCopyLayersFromTemplate: false,
  instructionDeleteNonstandardAppearanceStates: false,
  instructionDeleteNonstandardCombinedStates: false,
  instructionDeleteNonstandardExplodeStates: false,
  instructionDeleteNonstandardLayerStates: false,
  instructionDeleteNonstandardLayers: false,
  instructionDeleteNonstandardOrientations: false,
  instructionDeleteNonstandardSections: false,
  instructionDeleteNonstandardSimplifiedReps: false,
  instructionDeleteNonstandardStyleStates: false,
  instructionDrawingCopyWithModel: false,
  instructionDrawingReplaceSolid: false,
  instructionDrawingCreateBySolidCombinedStates: false,
};

const SET_VALIDATION_FIELDS = ({cadModelType}) => {
  return {
    name: true,
    modelTemplateModelName: true,
    cadModelType: true,
    ...(cadModelType === CAD_MODEL_TYPES.ASSEMBLY ? {cadModelAssemblySubtype: true} : {}),
    ...(cadModelType === CAD_MODEL_TYPES.PART ? {cadModelPartSubtype: true} : {}),
    ...(cadModelType === CAD_MODEL_TYPES.DRAWING ? {cadModelDrawingSubtype: true} : {}),
  };
};

export default function ModelTemplatesBody(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_TEMPLATE);


  const [validation, setValidation] = useState(SET_VALIDATION_FIELDS(currentEditingRow));


  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Template';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_TEMPLATE);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }
    const editedRow = {
      id: currentEditingRow?.id,
      name: currentEditingRow?.name,
      modelTemplateModelName: currentEditingRow?.modelTemplateModelName,
      cadModelType: currentEditingRow?.cadModelType,
      cadModelAssemblySubtype: currentEditingRow?.cadModelAssemblySubtype,
      cadModelPartSubtype: currentEditingRow?.cadModelPartSubtype,
      cadModelDrawingSubtype: currentEditingRow?.cadModelDrawingSubtype,
      modelParameterProfileId: currentEditingRow?.modelParameterProfile?.id,
      componentParameterProfileId: currentEditingRow?.componentParameterProfile?.id,
      noteProfileId: currentEditingRow?.noteProfile?.id,
      orientationIds: currentEditingRow?.orientations && currentEditingRow.orientations.map(item=>item?.id) || [],
      layerIds: currentEditingRow?.layers && currentEditingRow.layers.map(item=>item?.id) || [],
      sectionIds: currentEditingRow?.sections && currentEditingRow.sections.map(item=>item?.id) || [],
      simplifiedRepIds: currentEditingRow?.simplifiedReps && currentEditingRow.simplifiedReps.map(item=>item?.id) || [],
      instructionCreateSkeletonComponent: currentEditingRow?.instructionCreateSkeletonComponent || false,
      instructionCopyLayersFromTemplate: currentEditingRow?.instructionCopyLayersFromTemplate || false,
      instructionDeleteNonstandardAppearanceStates: currentEditingRow?.instructionDeleteNonstandardAppearanceStates || false,
      instructionDeleteNonstandardCombinedStates: currentEditingRow?.instructionDeleteNonstandardCombinedStates || false,
      instructionDeleteNonstandardExplodeStates: currentEditingRow?.instructionDeleteNonstandardExplodeStates || false,
      instructionDeleteNonstandardLayerStates: currentEditingRow?.instructionDeleteNonstandardLayerStates || false,
      instructionDeleteNonstandardLayers: currentEditingRow?.instructionDeleteNonstandardLayers || false,
      instructionDeleteNonstandardOrientations: currentEditingRow?.instructionDeleteNonstandardOrientations || false,
      instructionDeleteNonstandardSections: currentEditingRow?.instructionDeleteNonstandardSections || false,
      instructionDeleteNonstandardSimplifiedReps: currentEditingRow?.instructionDeleteNonstandardSimplifiedReps || false,
      instructionDeleteNonstandardStyleStates: currentEditingRow?.instructionDeleteNonstandardStyleStates || false,
      instructionDrawingCopyWithModel: currentEditingRow?.instructionDrawingCopyWithModel || false,
      instructionDrawingReplaceSolid: currentEditingRow?.instructionDrawingReplaceSolid || false,
      instructionDrawingCreateBySolidCombinedStates: currentEditingRow?.instructionDrawingCreateBySolidCombinedStates || false,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateModelTemplate(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyModelTemplate(editedRow));
    } else {
      dispatch(processEditModelTemplate(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      cadModelDrawingSubtype: rowdata.cadModelDrawingSubtype,
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      noteProfile: rowdata.noteProfile,
      orientations: rowdata.orientations.toArray(),
      layers: rowdata.layers.toArray(),
      sections: rowdata.sections.toArray(),
      simplifiedReps: rowdata.simplifiedReps.toArray(),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      modelTemplateModelName: rowdata.modelTemplateModelName,
      cadModelType: rowdata.cadModelType,
      cadModelAssemblySubtype: rowdata.cadModelAssemblySubtype,
      cadModelPartSubtype: rowdata.cadModelPartSubtype,
      cadModelDrawingSubtype: rowdata.cadModelDrawingSubtype,
      modelParameterProfile: rowdata.modelParameterProfile,
      componentParameterProfile: rowdata.componentParameterProfile,
      noteProfile: rowdata.noteProfile,
      orientations: rowdata.orientations.toArray(),
      layers: rowdata.layers.toArray(),
      sections: rowdata.sections.toArray(),
      simplifiedReps: rowdata.simplifiedReps.toArray(),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveModelTemplates([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveModelTemplates([rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteModelTemplate(currentEditingRow.id));
    setCurrentEditingRow(BLANK_TEMPLATE);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const getModelTypeDisplay = (rowData) => {
    const option = CAD_MODEL_TYPE_OPTIONS.find(opt => opt.value === rowData.cadModelType);
    return option?.primary || '';
  };

  const getModelSubtypeDisplay = (rowData) => {
    switch (rowData.cadModelType) {
    case CAD_MODEL_TYPES.ASSEMBLY:
      return CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelAssemblySubtype)?.primary || '';
    case CAD_MODEL_TYPES.PART:
      return CAD_MODEL_PART_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelPartSubtype)?.primary || '';
    case CAD_MODEL_TYPES.DRAWING:
      return CAD_MODEL_DRAWING_SUBTYPE_OPTIONS.find(opt => opt.value === rowData.cadModelDrawingSubtype)?.primary || '';
    default:
      return '';
    }
  };

  useEffect(() => {
    dispatch(processFetchAllModelTemplates());
    dispatch(processFetchAllParameterProfiles());
    dispatch(processFetchAllNoteProfiles());
    dispatch(processFetchAllOrientations());
    dispatch(processFetchAllSimplifiedReps());
    dispatch(processFetchAllLayers());
    dispatch(processFetchAllSections());
  }, []);

  const columns = [
    {
      field: 'name',
      header: 'Name',
      style: { width: '20%' },
      body: (rowData) => nameBody(rowData, (rowData) => editRowAction(reduxProps.data.find(template => template.id === rowData))),
      sortable: true,
    },
    {
      field: 'cadModelType',
      header: 'Model Type',
      body: (rowData) => <ComplexCellRenderer value={{ primary: getModelTypeDisplay(rowData) }} />,
      style: { width: '20%' },
      sortable: true,
    },
    {
      field: 'cadModelSubtype', // this is a computed field, not a real field in the database
      header: 'Model SubType',
      body: (rowData) => <ComplexCellRenderer value={{ primary: getModelSubtypeDisplay(rowData) }} />,
      sortable: true,
      sortField: (rowData) => {
        switch (rowData.cadModelType) {
        case CAD_MODEL_TYPES.ASSEMBLY:
          return rowData.cadModelAssemblySubtype;
        case CAD_MODEL_TYPES.PART:
          return rowData.cadModelPartSubtype;
        case CAD_MODEL_TYPES.DRAWING:
          return rowData.cadModelDrawingSubtype;
        default:
          return '';
        }
      }
    },
    {
      field: 'modelTemplateModelName',
      header: 'Model Name',
      body: (rowData) => TextCell(rowData.modelTemplateModelName),
      style: { width: '20%' },
      sortable: true,
    },
    {
      header: '',
      style: { textAlign: 'right', width: '20%' },
      body: (rowData) =>
        <RowEndEntityTools
          rowdata={rowData}
          editAction={editRowAction}
          copyAction={copyRowAction}
          archiveAction={archiveAction}
          unarchiveAction={unarchiveAction}
        />
    }
  ];

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleModelTemplatesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Model Template', reduxProps.data.length)}` :
            `Showing ${reduxProps.count} ${pluralize('Model Template', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          {columns.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </DataTable>
      </Flex>
      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '75%' }}
        header={()=>getDialogHeader()}
        footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)}
        modal
        className='p-fluid'
        closable={false}
      >
        <h3>Template Details</h3>
        <label>Name</label>
        <InputText
          value={currentEditingRow.name}
          onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
          className={!validation.name && 'p-invalid'}
        />

        <label>Model Template Model Name</label>
        <InputText
          value={currentEditingRow.modelTemplateModelName}
          onChange={(e) => handleEditedRowChange(e.target.value, 'modelTemplateModelName')}
          className={!validation.modelTemplateModelName && 'p-invalid'}
        />

        {!isCopyDialogOpen &&
          <>
            <label>CAD Model Type</label>
            <Dropdown
              value={currentEditingRow.cadModelType}
              options={CAD_MODEL_TYPE_OPTIONS}
              optionLabel='label'
              optionValue='value'
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelType')}
              className={!validation.cadModelType && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }
        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
        !isCopyDialogOpen &&
          <>
            <label>CAD Model Assembly Sub-Type</label>
            <Dropdown
              value={currentEditingRow.cadModelAssemblySubtype}
              options={CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS}
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelAssemblySubtype')}
              className={!validation.cadModelType && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }
        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.PART &&
        !isCopyDialogOpen &&
          <>
            <label>CAD Model Part Sub-Type</label>
            <Dropdown
              value={currentEditingRow.cadModelPartSubtype}
              options={CAD_MODEL_PART_SUBTYPE_OPTIONS}
              onChange={(e) => handleEditedRowChange(e.value, 'cadModelPartSubtype')}
              className={!validation.name && 'p-invalid'}
              filter
              placeholder='select'
            />
          </>
        }

        <h3>Model Details</h3>
        <Flex justifyContent='space-between' >
          <Box width={'48%'} marginRight={1} >
            <label>Model Parameter Profile</label>
            <Dropdown
              value={currentEditingRow.modelParameterProfile}
              options={reduxProps.parameterProfiles}
              filter
              optionLabel='name'
              onChange={(e) => handleEditedRowChange(e.value, 'modelParameterProfile')}
              itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder='--'/></Flex>}
              placeholder='select'
            />
          </Box>
          {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
            <>
              <Box width={'48%'}>
                <label>Component Parameter Profile</label>
                <Dropdown
                  value={currentEditingRow.componentParameterProfile}
                  options={reduxProps.parameterProfiles}
                  filter
                  optionLabel='name'
                  onChange={(e) => handleEditedRowChange(e.value, 'componentParameterProfile')}
                  itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder='--'/></Flex>}
                  placeholder='select'
                />
              </Box>
            </>
          }
        </Flex>

        <label>Note Profile</label>
        <Dropdown
          placeholder='select'
          value={currentEditingRow.noteProfile}
          options={reduxProps.noteProfiles}
          optionDisabled={'archivedFlag'}
          filter
          optionLabel='name'
          onChange={(e) => handleEditedRowChange(e.value, 'noteProfile')}
          itemTemplate={(item) => <Flex flexDirection='row'><ComplexCellRenderer value={{ primary: item?.primary }} isCapitalized={false} placeholder=''/></Flex>}
        />

        {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
          <>
            <label>Orientations</label>
            <MultiSelect
              filter
              placeholder='select'
              optionLabel='name'
              useOptionAsValue={true}
              value={currentEditingRow.orientations || []}
              options={reduxProps.orientations}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'orientations')}
            />
          </>
        }
        {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
          <>
            <label>Layers</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.layers || []}
              options={reduxProps.layers}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'layers')}
              filter
            />
          </>
        }
        {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
          <>
            <label>Sections</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.sections || []}
              options={reduxProps.sections}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'sections')}
              filter
            />
          </>
        }
        {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
          <>
            <label>Simplified Reps</label>
            <MultiSelect
              placeholder='select'
              optionLabel='name'
              value={currentEditingRow.simplifiedReps || []}
              options={reduxProps.simplifiedReps}
              display='chip'
              onChange={(e) => handleEditedRowChange(e.value, 'simplifiedReps')}
              filter
            />
          </>
        }

        <Box mt={4}>
          <h3>Model Instructions</h3>

          <Flex flexDirection="column" gap={2}>
            {currentEditingRow.cadModelType === CAD_MODEL_TYPES.ASSEMBLY &&
              <>
                <Flex alignItems='center' mt={2}>
                  <Checkbox
                    checked={currentEditingRow.instructionCreateSkeletonComponent}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionCreateSkeletonComponent')}
                  />
                  <Text ml={2}>Create Skeleton</Text>
                </Flex>
              </>
            }
            {currentEditingRow.cadModelType !== CAD_MODEL_TYPES.DRAWING &&
              <>
                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionCopyLayersFromTemplate}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionCopyLayersFromTemplate')}
                  />
                  <Text ml={2}>Copy Layers from Template</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardAppearanceStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardAppearanceStates')}
                  />
                  <Text ml={2}>Delete Non-Standard Appearance States</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardCombinedStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardCombinedStates')}
                  />
                  <Text ml={2}>Delete Non-Standard Combined States</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardExplodeStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardExplodeStates')}
                  />
                  <Text ml={2}>Delete Non-Standard Explode States</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardLayerStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardLayerStates')}
                  />
                  <Text ml={2}>Delete Non-Standard Layer States</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardLayers}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardLayers')}
                  />
                  <Text ml={2}>Delete Non-Standard Layers</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardOrientations}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardOrientations')}
                  />
                  <Text ml={2}>Delete Non-Standard Orientations</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardSections}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardSections')}
                  />
                  <Text ml={2}>Delete Non-Standard Sections</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardSimplifiedReps}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardSimplifiedReps')}
                  />
                  <Text ml={2}>Delete Non-Standard Simplified Reps</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDeleteNonstandardStyleStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDeleteNonstandardStyleStates')}
                  />
                  <Text ml={2}>Delete Non-Standard Style States</Text>
                </Flex>
              </>
            }

            {currentEditingRow.cadModelType === CAD_MODEL_TYPES.DRAWING && (
              <>
                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDrawingCopyWithModel}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingCopyWithModel')}
                  />
                  <Text ml={2}>Copy Drawing with Model</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDrawingReplaceSolid}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingReplaceSolid')}
                  />
                  <Text ml={2}>Replace Solid in Drawing</Text>
                </Flex>

                <Flex alignItems='center'>
                  <Checkbox
                    checked={currentEditingRow.instructionDrawingCreateBySolidCombinedStates}
                    onChange={(e) => handleEditedRowChange(e.checked, 'instructionDrawingCreateBySolidCombinedStates')}
                  />
                  <Text ml={2}>Create Drawing by Solid Combined States</Text>
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      </Dialog>
      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header={`Delete Model Template '${currentEditingRow.name}'`}
        footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)}
        closable={false}
      >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}