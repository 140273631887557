import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';

import Loading from '../../../components/common/Loading';

import { selectIsLoggingIn } from '../selectors';
import {
  selectCurrentUserCanAdmin,
  selectCurrentUserForcedPasswordResetUrl,
  selectCurrentUserHasNoOrganization,
  selectCurrentUserHasNoRequiredPasswordResetPending,
  selectCurrentUserHasOrganization,
  selectCurrentUserIsActive,
  selectCurrentUserIsInactive,
  selectCurrentUserIsNotPresent,
  selectCurrentUserIsNotPresentOrHasRequiredPasswordResetPending,
  selectCurrentUserIsPresent,
  selectCurrentUserIsVerified,
} from './_selectors';

import {
  AccountDeactivatedPath,
  AccountSetupPath,
  DashboardPath,
  EmailNotVerifiedPath,
  LoginPath,
  NoLicensePath,
} from '../../../paths';

/**
 * Current User information auth route decorators
 */
const locationHelper = locationHelperBuilder({});

export const UserIsAuthenticated = connectedRouterRedirect({
  redirectPath: LoginPath.url,
  authenticatedSelector: selectCurrentUserIsPresent(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserIsAuthenticated',
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || DashboardPath.url,
  authenticatedSelector: selectCurrentUserIsNotPresent(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

export const UserNotAuthenticatedOrHasRequiredPasswordResetPending =
  connectedRouterRedirect({
    redirectPath: (state, ownProps) =>
      locationHelper.getRedirectQueryParam(ownProps) || DashboardPath.url,
    authenticatedSelector:
      selectCurrentUserIsNotPresentOrHasRequiredPasswordResetPending(),
    authenticatingSelector: selectIsLoggingIn(),
    AuthenticatingComponent: Loading,
    allowRedirectBack: false,
    wrapperDisplayName: 'UserNotAuthenticatedOrHasRequiredPasswordResetPending',
  });

export const UserHasNoRequiredPasswordResetPending = connectedRouterRedirect({
  redirectPath: selectCurrentUserForcedPasswordResetUrl(),
  authenticatedSelector: selectCurrentUserHasNoRequiredPasswordResetPending(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserHasNoRequiredPasswordResetPending',
});

export const UserEmailVerified = connectedRouterRedirect({
  redirectPath: EmailNotVerifiedPath.url,
  authenticatedSelector: selectCurrentUserIsVerified(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  wrapperDisplayName: 'UserEmailVerified',
});

export const UserIsActive = connectedRouterRedirect({
  redirectPath: AccountDeactivatedPath.url,
  authenticatedSelector: selectCurrentUserIsActive(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsActive',
});

export const UserIsInactive = connectedRouterRedirect({
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || DashboardPath.url,
  authenticatedSelector: selectCurrentUserIsInactive(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsInactive',
});

export const UserHasOrganization = connectedRouterRedirect({
  redirectPath: AccountSetupPath.url,
  authenticatedSelector: selectCurrentUserHasOrganization(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserHasOrganization',
});

export const UserHasNoOrganization = connectedRouterRedirect({
  redirectPath: DashboardPath.url,
  authenticatedSelector: selectCurrentUserHasNoOrganization(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserHasNoOrganization',
});

export const UserCanAdmin = connectedRouterRedirect({
  redirectPath: DashboardPath.url,
  authenticatedSelector: selectCurrentUserCanAdmin(),
  authenticatingSelector: selectIsLoggingIn(),
  AuthenticatingComponent: Loading,
  allowRedirectBack: false,
  wrapperDisplayName: 'UserCanAdmin',
});
