import React, { useCallback } from 'react';

import Flex from '../../../common/Flex';
import Icon from '../../../common/Icon';

import BaseInput from '../../BaseInput';
import BaseAppend from '../../BaseAppend';
import BaseSelectDefaultEditableCurrentOptionTemplate from '../../BaseSelectDefaultEditableCurrentOptionTemplate';

const BaseCurrentEditableOptionValue = ({ forwardRef, isLoading, getInputProps, inputValue, name, placeholder, currentHighlightedItem, clearable, isOpen, setSelectState, clearSelection, itemHeight }) => {
  const onFocus = useCallback(() => setSelectState(prev => ({ ...prev, isOpen: true })), [setSelectState]);
  const onClear = useCallback(e => {
    e.stopPropagation();

    clearSelection();
    if (forwardRef && forwardRef.current) {
      forwardRef && forwardRef.current && forwardRef.current.focus();
    }
  }, [forwardRef, setSelectState]);
  const onClick = useCallback(e => {
    e.stopPropagation();

    if (forwardRef && forwardRef.current) {
      if (isOpen) {
        setSelectState(prev => ({ ...prev, isOpen: false }));
      } else {
        setSelectState(prev => ({ ...prev, isOpen: true }));
        forwardRef && forwardRef.current && forwardRef.current.focus();
      }
    }
  }, [forwardRef, setSelectState, isOpen]);

  const { clearable: _, defaultValue, ...inputProps } = getInputProps({
    placeholder: isOpen && currentHighlightedItem && currentHighlightedItem.primary || placeholder,
    name,
    value: inputValue,
    onFocus,
    ref: forwardRef,
  });

  return (
    <Flex flexDirection='row' alignItems='center' height='100%' cursor='pointer'>
      <BaseSelectDefaultEditableCurrentOptionTemplate isLoading={isLoading} item={currentHighlightedItem} itemHeight={itemHeight}>
        <BaseInput {...inputProps} />
      </BaseSelectDefaultEditableCurrentOptionTemplate>
      {
        (isOpen || clearable) && inputValue !== '' && (
          <BaseAppend onClick={onClear}>
            <Icon name='close' />
          </BaseAppend>
        ) || (
          <BaseAppend onClick={onClick}>
            <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} />
          </BaseAppend>
        )
      }
    </Flex>
  );
};

const CurrentEditableOptionValue = React.forwardRef((props, ref) => <BaseCurrentEditableOptionValue forwardRef={ref} { ...props } />);

export default CurrentEditableOptionValue;
