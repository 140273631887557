import React from 'react';
import Text from '../../common/Text';
import colors from '../../../assets/themes/base/colors';

interface TextCellProps {
  value: string;
  placeholder: string;
}

const TextCell: React.FC<TextCellProps> = ({ value, placeholder }) => (
  value ? (
    <Text as="div">{value}</Text>
  ) : (
    <Text as="div" style={{ color: colors.gray[5] }}>
      {placeholder}
    </Text>
  )
);

export default TextCell;