import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';

export const selectNormalizedConfigProfiles = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CONFIG_PROFILES.state)
);

export const selectNormalizedConfigProfilesList = () => createSelector(
  selectNormalizedConfigProfiles(),
  profiles => profiles.toList()
);

export const selectConfigProfilesCounts = () => createSelector(
  selectNormalizedConfigProfiles(),
  profiles => profiles.reduce(({ unarchived, total }, profile) => ({
    unarchived: !profile.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectCurrentConfigProfile = () => createSelector(
  selectNormalizedConfigProfiles(),
  (_, props) => props.configProfileId,
  (configProfiles, id) => configProfiles.get(id)
);

export const selectConfigProfileWithCounts = () => createSelector(
  selectNormalizedConfigProfilesList(),
  profiles => {
    if (!profiles) {
      return [];
    }

    return profiles.reduce((acc, profile) => {
      if (!profile) {
        return acc;
      }

      acc.push({
        id: profile.get('id'),
        settingsCount: profile.get('configProfileValuesCount') || 0,
        mapkeysCount: profile.get('configProfileMapkeysCount') || 0
      });
      return acc;
    }, []);
  }
);
