import { toast } from 'react-toastify';

import EK from '../../keys';
import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllSpools,
  createSpool,
  editSpool,
  deleteSpool,
  copySpool,
  archiveSpool,
} from './constants';
import schema from './schema';

const client = api(schema, EK.CABLING_SPOOLS);

export const processFetchAllSpools = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllSpools,
    '/cabling/spools'
  );

export const processCreateSpool = data =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createSpool,
    '/cabling/spools',
    {
      data: {
        spools: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Spool successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create spool',
      },
    }
  );

export const processEditSpool = (spoolId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editSpool,
    `/cabling/spools/${spoolId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteSpool = spoolId =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteSpool,
    `/cabling/spools/${spoolId}?with_full_detail=true`,
    {
      deleteKey: EK.CABLING_SPOOLS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Spool deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete spool',
      },
    }
  );

export const processCopySpool = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copySpool,
    '/cabling/spools/copy',
    {
      data: {
        spools: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Spool successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy spool',
      },
    }
  );
