import { Map } from 'immutable';

import { LOCATION_CHANGE } from 'connected-react-router';
import resetOnLogout from '../../../utils/reducers/resetOnLogout';

import { TOGGLE_FAMILY_SORTING } from './constants';

const generateInitialEditSpecState = () =>
  Map({
    familySortingOrder: false,
    isPrioritizedFamilySortingEnabled: false,
  });

const editSpecsReducer = (
  state = generateInitialEditSpecState(),
  { type, payload = {} }
) => {
  switch (type) {
  case TOGGLE_FAMILY_SORTING:
    return state.set('isPrioritizedFamilySortingEnabled', !state.get('isPrioritizedFamilySortingEnabled'));
  case LOCATION_CHANGE:
    return state.set('isPrioritizedFamilySortingEnabled', false);
  default:
    return state;
  }
};

export default resetOnLogout(editSpecsReducer);
