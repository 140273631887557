import { createSelector } from 'reselect';
import { Map } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { formValueSelector } from 'redux-form/immutable';

const catalogFilterForm = formValueSelector(`filters.${EK.ECAD_CATALOGS.state}`);

export const selectCatalogs = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.ECAD_CATALOGS.state, Map())
  );

export const selectCurrentFilteredCatalogs = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectCatalogs(),
    state => catalogFilterForm(state, 'name', 'description'),
    (isFiltering, catalogs, query) => isFiltering ?
      catalogs.filter(catalog => catalog.doesMatchQuery(query)) :
      catalogs
  );

export const selectCurrentCatalogsCount = () =>
  createSelector(selectCatalogs(), (entities) => entities.size);

export const selectNormalizedCatalogs = () =>
  createSelector(selectCatalogs(), catalogs => catalogs);