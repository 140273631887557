import { Map } from 'immutable';

import resetOnLogout from '../../../../../utils/reducers/resetOnLogout';

import { HIDE_MODAL } from '../../../../../modules/modal/constants';

import { CONFIG_PROFILE_MAPKEYS_MODAL_PREPARED } from './constants';

const initialModalState = Map({ isReady: false });

const configProfileMapkeysReducer = (state = initialModalState, { type }) => {
  if (type === CONFIG_PROFILE_MAPKEYS_MODAL_PREPARED) {
    return state.set('isReady', true);
  } else if (type === HIDE_MODAL) {
    return initialModalState;
  } else {
    return state;
  }
};

export default resetOnLogout(configProfileMapkeysReducer);
