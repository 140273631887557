export const saveCurrentMaterialChanges = (
  changes: any,
  saveAction: any,
) => {
  const collectionsdata: any = [];

  changes.forEach((item: any) => {
    if (item.isCreated) {
      collectionsdata.push({
        code: item.code,
        description: item.description,
        filename: item.filename,
        density: item.density,
        isValidForPipe: item.isValidForPipe,
        isValidForFitting: item.isValidForFitting,
        isValidForInsulation: item.isValidForInsulation,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ ...item, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({ ...item });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};

export const convertToMaterial = ({
  id, code, description, filename, density, isValidForPipe, isValidForFitting, isValidForInsulation,
  warnDeleteCounts, restrictDeleteCounts, isEdited=false, isCreated=false, isDeleted=false
}: any) => {
  return {
    id,
    code,
    description,
    filename,
    density,
    isValidForPipe,
    isValidForFitting,
    isValidForInsulation,
    isEdited: isEdited,
    isDeleted: isDeleted,
    isCreated: isCreated,
    warnDeleteCounts,
    restrictDeleteCounts
  } as any;
};

export const getMaterialTypesArray = ({
  isValidForPipe,
  isValidForFitting,
  isValidForInsulation,
  restrictDeleteCounts,
  warnDeleteCounts }: any) => {


  const pipeUseCount = warnDeleteCounts && warnDeleteCounts.pipeFamilies;
  const fittingUseCount = warnDeleteCounts && warnDeleteCounts.fittingFamilies;
  const insulationUseCount = restrictDeleteCounts && restrictDeleteCounts.insulationInstances;
  return [
    ...(isValidForPipe
      ? [
        {
          id: 'pipe',
          value: 'pipe',
          label: `Pipe${(pipeUseCount && ' (in use)') || ''}`,
          primary: 'Pipe',
          disabled: !!pipeUseCount,
        },
      ]
      : []),
    ...(isValidForFitting
      ? [
        {
          id: 'fitting',
          value: 'fitting',
          label: `Fitting${(fittingUseCount && ' (in use)') || ''}`,
          primary: 'Fitting',
          disabled: !!fittingUseCount,
        },
      ]
      : []),
    ...(isValidForInsulation
      ? [
        {
          id: 'insulation',
          value: 'insulation',
          label: `Insulation${(insulationUseCount && ' (in use)') || ''}`,
          primary: 'Insulation',
          disabled: !!insulationUseCount,
        },
      ]
      : []),
  ];
};

export const getMaterialOptions = (item: any) => {
  const pipeUseCount = item.warnDeleteCounts && item.warnDeleteCounts.pipeFamilies;
  const fittingUseCount = item.warnDeleteCounts && item.warnDeleteCounts.fittingFamilies;
  const insulationUseCount = item.restrictDeleteCounts && item.restrictDeleteCounts.insulationInstances;

  return [
    {
      id: 'pipe',
      value: 'pipe',
      label: `Pipe${(pipeUseCount && ' (in use)') || ''}`,
      primary: 'Pipe',
      disabled: !!pipeUseCount,
    },
    {
      id: 'fitting',
      value: 'fitting',
      label: `Fitting${(fittingUseCount && ' (in use)') || ''}`,
      primary: 'Fitting',
      disabled: !!fittingUseCount,
    },
    {
      id: 'insulation',
      value: 'insulation',
      label: `Insulation${(insulationUseCount && ' (in use)') || ''}`,
      primary: 'Insulation',
      disabled: !!insulationUseCount,
    },
  ];
};