import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { StandardizeModuleParametersPath } from '../../../paths';

export default function ParametersBreadcrumb() {
  return (
    <BreadcrumbTemplate
      title={StandardizeModuleParametersPath.defaultTitle}
    />
  );
}