import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'USERS';

export const fetchAllUsers = generateAPHTypes(resource, 'FETCH_ALL');

export const adminCreateUsers = generateAPHTypes(resource, 'ADMIN_POST');

export const editUsers = generateAPHTypes(resource, 'EDIT');

export const forceResetPasswordTypes = generateAPHTypes(resource, 'FORCE_RESET_PASSWORD');