import ModelTemplateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  CAD_MODEL_TYPES,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
  CAD_MODEL_DRAWING_SUBTYPE_OPTIONS
} from './model';

const modelTemplateFilterForm = formValueSelector(
  `filters.${EK.MODEL_TEMPLATES.state}`
);

export const selectNormalizedModelTemplates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.MODEL_TEMPLATES.state)
  );

export const selectNormalizedModelTemplatesList = () =>
  createSelector(selectNormalizedModelTemplates(), (modelTemplates) =>
    modelTemplates.toList()
  );

const getCadModelTypeFilterForm = (state) => modelTemplateFilterForm(state, 'cadModelType');

const determineSubtypeField = (subtypeValue) => {
  if (!subtypeValue) return null;

  const value = subtypeValue.get ? subtypeValue.get('value') : subtypeValue;

  if (CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS.some(opt => opt.value === value)) {
    return { field: 'cadModelAssemblySubtype', value, type: CAD_MODEL_TYPES.ASSEMBLY };
  }
  if (CAD_MODEL_PART_SUBTYPE_OPTIONS.some(opt => opt.value === value)) {
    return { field: 'cadModelPartSubtype', value, type: CAD_MODEL_TYPES.PART };
  }
  if (CAD_MODEL_DRAWING_SUBTYPE_OPTIONS.some(opt => opt.value === value)) {
    return { field: 'cadModelDrawingSubtype', value, type: CAD_MODEL_TYPES.DRAWING };
  }
  return null;
};

export const selectCurrentFilteredModelTemplates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedModelTemplates(),
    selectIsShowingArchived(),
    state => {
      const modelType = getCadModelTypeFilterForm(state);
      const subtypeValue = modelTemplateFilterForm(state, 'cadModelSubtype');

      let subtypeField = null;

      if (modelType) {
        switch (modelType) {
        case CAD_MODEL_TYPES.ASSEMBLY:
          subtypeField = { field: 'cadModelAssemblySubtype', value: subtypeValue, type: modelType };
          break;
        case CAD_MODEL_TYPES.PART:
          subtypeField = { field: 'cadModelPartSubtype', value: subtypeValue, type: modelType };
          break;
        case CAD_MODEL_TYPES.DRAWING:
          subtypeField = { field: 'cadModelDrawingSubtype', value: subtypeValue, type: modelType };
          break;
        }
      } else if (subtypeValue) {
        subtypeField = determineSubtypeField(subtypeValue);
      }

      return {
        name: modelTemplateFilterForm(state, 'name'),
        cadModelType: subtypeField ? subtypeField.type : modelType,
        modelTemplateModelName: modelTemplateFilterForm(state, 'modelTemplateModelName'),
        ...(subtypeField ? { [subtypeField.field]: subtypeField.value } : {})
      };
    },
    (isFiltering, modelTemplates, isShowingArchived, query) =>
      isFiltering
        ? modelTemplates.filter((mt) => mt.doesMatchQuery(query)).toList()
        : modelTemplates.toList()
  );

export const selectCurrentModelTemplatesCount = () =>
  createSelector(
    selectNormalizedModelTemplates(),
    (modelTemplates) => (modelTemplates && modelTemplates.toList() && modelTemplates.toList().size) || 0
  );

export const selectDenormalizedModelTemplatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.MODEL_TEMPLATES.state),
      [ModelTemplateSchema],
      entities
    )
  );