import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllNotes, createNoteTypes, saveNotes } from './constants';
import schema from './schema';

const client = api(schema, EK.NOTES);

export const processFetchAllNotes = () => client(REQUEST_TYPES.GET, 'plural', fetchAllNotes, '/standardize/notes');

export const processCreateNote = data => client(REQUEST_TYPES.POST, 'plural', createNoteTypes, '/standardize/notes', {
  data: {
    notes: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Note successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create note',
  },
});

export const processSaveNotes = (data) => client(REQUEST_TYPES.PATCH, 'plural', saveNotes, '/standardize/notes/collection?diff_result=true', {
  data: { notesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
