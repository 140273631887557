import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'SIZES';

export const fetchAllSizes = generateAPHTypes(resource, 'FETCH_ALL');

export const saveSizes = generateAPHTypes(resource, 'COLLECTION');

export const newSizesRows = [
  { name: '', description: '', code: '', id: 'NEW_ROW_1', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_2', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_3', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_4', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_5', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_6', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_7', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_8', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_9', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_10', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_11', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_12', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_13', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_14', isEdited: false, isDeleted: false, isCreated: false, },
  { name: '', description: '', code: '', id: 'NEW_ROW_15', isEdited: false, isDeleted: false, isCreated: false, },
];