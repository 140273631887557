import React from 'react';
import Box from '../../common/Box';

const BaseFormSection = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    border={1}
    $borderRadius={1}
    mb={3}
    {...props}
  />
));

export default BaseFormSection;