import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Divider } from 'primereact/divider';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';

import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import Box from '../../../components/common/Box';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';


import {
  dialogFooter,
  deleteDialogFooter,
  areFieldsValid,
  doRequiredFieldsExist,
} from './components';

import { selectSidebarIsFiltering, selectStandardizeModulePermissionsAndState } from '../../Dashboard/selectors';

import { selectCurrentFilteredOrientations, selectCurrentOrientationsCount } from '../../../entities/Standardize/Orientations/selectors';
import {
  processFetchAllOrientations,
  processSaveOrientations
} from '../../../entities/Standardize/Orientations/actions';
import { StandardizeModuleOrientationsPath } from '../../../paths';
import { pluralize } from '../../../components/common/Header';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredOrientations(),
  selectCurrentOrientationsCount(),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
    count,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      hasValidLicense,
      canCollaborate,
      isLoading,
      count,
      isFiltering,
    };
  },
);

const BLANK_ORIENTATION = {
  name: '',
  description: '',
  matrix: [[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]]
};

const VALIDATION_FIELDS = {
  name: true,
};

export default function(){

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_ORIENTATION);

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Orientation';
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_ORIENTATION);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_ORIENTATION);
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      description: rowdata.description,
      matrix: JSON.parse(rowdata.matrix),
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const handleEditedRowMatrixChange = (newValue, row, column) => {
    let _editedRow = { ...currentEditingRow };
    _editedRow.matrix[row][column] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processSaveOrientations([{id: currentEditingRow.id, _destroy:'1'}]));
    setCurrentEditingRow(BLANK_ORIENTATION);
    setIsDeleteDialogOpen(false);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      description: currentEditingRow.description,
      matrix: JSON.stringify(currentEditingRow.matrix),
    };

    const {id, ...rest} = editedRow;

    if (isCreateDialogOpen) {
      dispatch(processSaveOrientations([rest]));
    } else {
      dispatch(processSaveOrientations([editedRow]));
    }
    cancelDialogs();

  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow({});
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  useEffect(() => {
    dispatch(processFetchAllOrientations());
  }, []);

  return(
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={StandardizeModuleOrientationsPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Orientation', reduxProps.count)}` :
            `Showing ${reduxProps.count} ${pluralize('Orientation', reduxProps.count)} Total`}
        />
        <Options
          canFilter={true}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' stripedRows scrollable scrollHeight='flex' >
          <Column field='name' header='Name' style={{ width: '300px'}}/>
          <Column field='description' header='Description' style={{ width: '300px' }} bodyStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '300px' }}/>
          <Column field='matrix' header='Matrix' style={{overflow:'ellipsis', width: '300px'}}  />
          <Column header=''
            style={{textAlign: 'right', width: '25%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog
        visible={reduxProps.editable && isDialogOpen}
        style={{ width: '75%' }}
        header={()=>getDialogHeader()}
        footer={() => dialogFooter(isCreateDialogOpen, false, openDeleteDialog, saveAction, cancelDialogs)}
        modal
        className='p-fluid'
        closable={false}
      >
        <div>
          <label>Name</label>
          <InputText
            value={currentEditingRow.name}
            onChange={(e) => handleEditedRowChange(e.target.value, 'name')}
            className={!validation.name && 'p-invalid'}
          />

          <label>Description</label>
          <InputText
            value={currentEditingRow.description}
            onChange={(e) => handleEditedRowChange(e.target.value, 'description')}
          />

          <Box marginTop={4}>
            <Divider key="matrix-divider"/>
            <label>Transformation Matrix</label>
            <Box paddingLeft={2}>
              {currentEditingRow.matrix &&
              currentEditingRow.matrix.length > 0 &&
              currentEditingRow.matrix.map((arr,row) => {
                return(
                  <React.Fragment key={`matrix-row-${row}`}>
                    <Flex justifyContent='space-between'>
                      {arr.map(
                        (entry,col)=>(
                          <InputNumber
                            key={`matrix-${row}-${col}`}
                            value={entry}
                            onChange={(e) => handleEditedRowMatrixChange(e.value, row, col)}
                            style={{width:'20%'}}
                          />
                        )
                      )}
                    </Flex>
                  </React.Fragment>
                );
              })}
            </Box>
          </Box>
        </div>
      </Dialog>
      <Dialog
        visible={reduxProps.editable && isDeleteDialogOpen}
        style={{ width: '32rem' }}
        header={`Delete Orientation '${currentEditingRow.name}'`}
        footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)}
        closable={false}
      >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}