import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.MAPKEYS.state;

export const fetchAllMapkeysTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createMapkeys = generateAPHTypes(resource, 'CREATE');

export const editMapkeys = generateAPHTypes(resource, 'EDIT');

export const removeMapkeys = generateAPHTypes(resource, 'REMOVE');
