import StyleStateSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const styleStateFilterForm = formValueSelector(
  `filters.${EK.STYLE_STATES.state}`
);

export const selectNormalizedStyleStates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.STYLE_STATES.state)
  );

export const selectNormalizedStyleStatesList = () =>
  createSelector(selectNormalizedStyleStates(), (styleStates) =>
    styleStates.toList()
  );

export const selectCurrentFilteredStyleStates = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedStyleStates(),
    state => ({
      name: styleStateFilterForm(state, 'name'),
      description: styleStateFilterForm(state, 'description')
    }),
    (isFiltering, styleStates, query) =>
      isFiltering
        ? styleStates.filter((ss) => ss.doesMatchQuery(query)).toList()
        : styleStates.toList()
  );

export const selectCurrentStyleStatesCount = () =>
  createSelector(
    selectNormalizedStyleStates(),
    (styleStates) => (styleStates && styleStates.toList() && styleStates.toList().size) || 0
  );

export const selectDenormalizedStyleStatesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.STYLE_STATES.state),
      [StyleStateSchema],
      entities
    )
  );
