import { takeLatest, put, fork, all } from 'redux-saga/effects';

import { hideModal } from '../../../../../modules/modal/actions';

import {
  createSimplifiedReps,
  copySimplifiedReps,
  editSimplifiedReps,
  deleteSimplifiedReps,
} from '../../../../../entities/Standardize/SimplifiedReps/constants';

export function* handleCloseSimplifiedRepModal() {
  yield put(hideModal());
}

export function* watchCloseSimplifiedRepModalSaga() {
  yield takeLatest(
    [
      createSimplifiedReps.SUCCESS,
      copySimplifiedReps.SUCCESS,
      editSimplifiedReps.SUCCESS,
      deleteSimplifiedReps.SUCCESS,
    ],
    handleCloseSimplifiedRepModal
  );
}

// final output saga
export default function* main() {
  yield all([fork(watchCloseSimplifiedRepModalSaga)]);
}
