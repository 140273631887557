import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../../entities/keys';

import Box from '../../../../../components/common/Box';
import Button from '../../../../../components/common/Button';

import BaseFormSection from '../../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../../components/form/BaseFormRow';

import RDXTextInput from '../../../RDXTextInput';

import { ALLOWANCES } from '../../../../../entities/Standardize/NoteProfiles/model';
import { validations } from '../../../../../entities/Standardize/NoteProfiles/formValidation';

const NoteProfileForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.NOTE_PROFILES.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(NoteProfileForm);
