import { createSelector } from 'reselect';

import EK from '../../keys';

import selectEntities from '../../../modules/entities/selectors';
import selectDrafts from '../../../modules/drafts/selectors';
import sectionSchema from './schema';

import { denormalize } from 'normalizr';

import { formValueSelector } from 'redux-form/immutable';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const sectionFilterForm = formValueSelector(
  `filters.${EK.SECTIONS.state}`
);

export const selectNormalizedSections = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.SECTIONS.state)
  );
export const selectNormalizedSectionsList = () =>
  createSelector(selectNormalizedSections(), (sections) =>
    sections.toList()
  );

export const selectNormalizedDraftSections = () =>
  createSelector(selectDrafts(), (drafts) => {
    const state = drafts.get(EK.SECTIONS.state);
    const records = state.get('records');
    return state.get('ids').map((id) => records.get(id));
  });

export const selectSections = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.SECTIONS.state),
      [sectionSchema],
      entities
    )
  );

export const selectCurrentFilteredSections = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedSections(),
  (state) =>
    sectionFilterForm(
      state,
      'name',
      'sectionMethod',
      'datumFeatureName',
      'description'
    ),
  (isFiltering, sections, query) =>
    isFiltering
      ? sections.filter(
        (section) => {
          if (!query) return true;

          if (query.name && !section.name?.toLowerCase().includes(query.name.toLowerCase())) return false;
          if (query.sectionMethod && section.sectionMethod !== query.sectionMethod) return false;
          if (query.datumFeatureName && !section.datumFeatureName?.toLowerCase().includes(query.datumFeatureName.toLowerCase())) return false;
          if (query.description && !section.description?.toLowerCase().includes(query.description.toLowerCase())) return false;

          return true;
        }
      ) : sections
);

export const selectSectionsCount = () => createSelector(
  selectNormalizedSections(),
  (sections) => sections && sections.size || 0
);