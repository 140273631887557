import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { Map } from 'immutable';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';

import validateRules from '../../../../utils/validation/validateRules';
import mustMatchRDXField from '../../../../utils/validation/mustMatchRDXField';

const deletePhraseConfirmationRules = {
  matchPassword: {
    message: 'Must match the name exactly',
    validator: mustMatchRDXField('deletePhrase'),
  },
};

const deletePhraseConfirmationValidations = validateRules(deletePhraseConfirmationRules);

const BaseConfirmDeleteForm = ({ handleSubmit, isPerforming, valid, deletePhrase, onCancel }) => (
  <Box
    as='form'
    onSubmit={handleSubmit}
    border={1}
    $borderRadius={1}
    p={4}
    bg='white'
  >
    <Flex flexDirection='column' justifyContent='center' ml='auto' mr='auto' mb={3}>
      <Text as='span' fontSize={3} color='gray.7' mb={4}>
        Delete this item
      </Text>

      <Text as='div' fontSize={1} color='gray.7' mb={5}>
        Deleting this item is irreversible and could possibly affect the integrity of your data. <strong>Only delete this if you are certain that it needs to be removed from everywhere.</strong> If you are certain you want to delete this item, enter the name <strong>exactly</strong> as you see it below:
      </Text>

      <Text as='div' fontSize={5} color='gray.6' mb={5} textAlign='center'>
        { deletePhrase }
      </Text>
    </Flex>

    <Field component={RDXTextInput} name='deletePhrase' type='hidden' required />

    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name='deletePhraseConfirmation'
          required
          validate={deletePhraseConfirmationValidations}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button error type='submit' large full disabled={isPerforming || !valid} isPerformingAction={isPerforming}>Confirm Deletion</Button>

    { onCancel && <Button secondary type='button' large full disabled={isPerforming} onClick={onCancel} mt={2}>Cancel</Button> || null }
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'confirmDeleteForm',
    onSubmit(values, dispatch, props) {
      props.onRDXSubmit();
    },
  })
);

const ConfirmDeleteForm = enhance(BaseConfirmDeleteForm);

export default ({ deletePhrase, ...rest }) => {
  const initialValues = useMemo(() => Map({ deletePhrase }), [deletePhrase]);

  return <ConfirmDeleteForm initialValues={initialValues} deletePhrase={deletePhrase} { ...rest } />;
};