import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../../../entities/keys';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';
import { NOTE_TYPES_OPTIONS } from '../../../entities/Standardize/Notes/model';

const FilterForm = (props) => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="name"
        label="Name"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="noteBody"
        label="Note Body"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name="noteType"
        label="Note Type"
        options={List(NOTE_TYPES_OPTIONS)}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="description"
        label="Description"
        type="text"
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.NOTES.state}` })
);

export default enhance(FilterForm);
