import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllNoteProfilesTypes,
  fetchIndividualNoteProfiles,
  createNoteProfiles,
  copyNoteProfiles,
  editNoteProfiles,
  deleteNoteProfiles,
  archiveNoteProfilesTypes,
  unarchiveNoteProfilesTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.NOTE_PROFILES);

export const processFetchAllNoteProfiles = () => client(REQUEST_TYPES.GET, 'plural', fetchAllNoteProfilesTypes, '/standardize/note_profiles?all=true');

export const processFetchIndividualNoteProfile = profileId => client(REQUEST_TYPES.GET, 'single', fetchIndividualNoteProfiles, `/standardize/note_profiles/${profileId}?with_full_detail=true`, {
  mutateResponse: ({ noteProfile: { id, noteBlocks : notes, ...profile } = {} }) => ({
    noteProfile: {
      ...profile,
      id,
      noteBlocks: notes.map(({ id : nbId, noteBlockNotes, ...nb }) => ({
        ...nb,
        id: nbId,
        noteBlockNotes: noteBlockNotes.map(nbn => ({
          ...nbn,
          noteBlock: `${nbId}`,
          noteBlockId: `${nbId}`,
        })),
        noteProfile: `${id}`,
        noteProfileId: `${id}`
      })),
    },
  }),
});

export const processCreateNoteProfile = data => client(REQUEST_TYPES.POST, 'plural', createNoteProfiles, '/standardize/note_profiles', {
  data: {
    noteProfiles: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create profile',
  },
});

export const processCopyNoteProfile = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyNoteProfiles,
    '/standardize/note_profiles/copy',
    {
      data: {
        [EK.NOTE_PROFILES.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Note profile successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy note profile',
      },
    }
  );

export const processEditNoteProfile = (profileId, data) => client(REQUEST_TYPES.PATCH, 'single', editNoteProfiles, `/standardize/note_profiles/${profileId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteNoteProfile = profileId => client(REQUEST_TYPES.DELETE, 'single', deleteNoteProfiles, `/standardize/note_profiles/${profileId}?with_full_detail=true`, {
  deleteKey: EK.NOTE_PROFILES.state,
  dependentDeletionKeys: [EK.NOTE_PROFILE_BLOCKS],
  mutateResponse: ({ noteProfile: { id, ...other } = {}, noteBlocks = [] }) => ({
    noteProfile: {
      ...other,
      id,
      noteBlocks: noteBlocks.map(note => ({
        ...note,
        noteProfile: profileId,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete profile',
  },
});

export const processArchiveNoteProfiles = profileIds => client(REQUEST_TYPES.ARCHIVE, 'plural', archiveNoteProfilesTypes, '/standardize/note_profiles/archive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive profile',
  },
});

export const processUnarchiveNoteProfiles = profileIds => client(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveNoteProfilesTypes, '/standardize/note_profiles/unarchive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive profile',
  },
});







