import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  fetchAllConfigProfilesTypes,
  fetchIndividualConfigProfiles,
  createConfigProfiles,
  copyConfigProfiles,
  editConfigProfiles,
  deleteConfigProfiles,
  archiveConfigProfilesTypes,
  unarchiveConfigProfilesTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.CONFIG_PROFILES);

export const processFetchAllConfigProfiles = () => client(REQUEST_TYPES.GET, 'plural', fetchAllConfigProfilesTypes, '/synchronize/config_profiles?all=true');

export const processFetchConfigProfile = profileId => client(REQUEST_TYPES.GET, 'single', fetchIndividualConfigProfiles, `/synchronize/config_profiles/${profileId}?with_full_detail=true`, {
  mutateResponse: ({ [EK.CONFIG_PROFILES.single]: { id, ...profile } = {} }) => ({
    [EK.CONFIG_PROFILES.single]: {
      ...profile,
      id,
      [EK.CONFIG_PROFILE_VALUES.plural]: (profile[EK.CONFIG_PROFILE_VALUES.plural] || []).map(({
        [EK.CONFIG_SETTINGS.single]: { multipleAllowed, ...setting } = {}, ...pv
      }) => ({
        ...pv,
        [EK.CONFIG_SETTINGS.single]: { ...setting, multipleAllowed },
        [EK.CONFIG_PROFILES.single]: `${id}`,
        multipleAllowed,
      })),
    }
  }),
});

export const processCreateConfigProfile = data => client(REQUEST_TYPES.POST, 'plural', createConfigProfiles, '/synchronize/config_profiles', {
  data: {
    [EK.CONFIG_PROFILES.plural]: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create profile',
  },
});

export const processCopyConfigProfile = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyConfigProfiles,
    '/synchronize/config_profiles/copy?with_full_detail=true',
    {
      data: {
        [EK.CONFIG_PROFILES.plural]: [data],
      },
      mutateResponse: response => ({
        [EK.CONFIG_PROFILES.plural]: (response[EK.CONFIG_PROFILES.plural] || []).map(({ id, ...profile }) => ({
          ...profile,
          id,
          [EK.CONFIG_PROFILE_VALUES.plural]: (profile[EK.CONFIG_PROFILE_VALUES.plural] || []).map(pv => ({ ...pv, [EK.CONFIG_PROFILES.single]: `${id}` })),
        }))
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy profile',
      },
    }
  );

export const processEditConfigProfile = (profileId, data) => client(REQUEST_TYPES.PATCH, 'single', editConfigProfiles, `/synchronize/config_profiles/${profileId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteConfigProfile = profileId => client(REQUEST_TYPES.DELETE, 'single', deleteConfigProfiles, `/synchronize/config_profiles/${profileId}?with_full_detail=true`, {
  deleteKey: EK.CONFIG_PROFILES.state,
  dependentDeletionKeys: [EK.CONFIG_PROFILE_VALUES.state],
  mutateResponse: ({ [EK.CONFIG_PROFILES.single]: { id, ...profile } = {}, ...response }) => ({
    [EK.CONFIG_PROFILES.single]: {
      ...profile,
      id,
      [EK.CONFIG_PROFILE_VALUES.plural]: (response[EK.CONFIG_PROFILE_VALUES.plural] || []).map(({
        [EK.CONFIG_SETTINGS.single]: { multipleAllowed, ...setting } = {}, ...pv
      }) => ({
        ...pv,
        [EK.CONFIG_SETTINGS.single]: { ...setting, multipleAllowed },
        [EK.CONFIG_PROFILES.single]: `${id}`,
        multipleAllowed,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete profile',
  },
});

export const processArchiveConfigProfiles = profileIds => client(REQUEST_TYPES.ARCHIVE, 'plural', archiveConfigProfilesTypes, '/synchronize/config_profiles/archive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive profile',
  },
});

export const processUnarchiveConfigProfiles = profileIds => client(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveConfigProfilesTypes, '/synchronize/config_profiles/unarchive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive profile',
  },
});