import EK from '../../keys';

import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = EK.PARAMETER_PROFILES.state;

export const fetchAllParameterProfiles = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualParameterProfiles = generateAPHTypes(resource, 'FETCH');

export const createParameterProfiles = generateAPHTypes(resource, 'CREATE');

export const copyParameterProfiles = generateAPHTypes(resource, 'COPY');

export const editParameterProfiles = generateAPHTypes(resource, 'EDIT');

export const deleteParameterProfiles = generateAPHTypes(resource, 'DELETE');

export const archiveParameterProfiles = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveParameterProfiles = generateAPHTypes(resource, 'UNARCHIVE');
