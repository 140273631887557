import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../entities/keys';
import BaseFormRow from '../../../components/form/BaseFormRow';
import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';
import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

import { ALGORITHM_OPTIONS_LIST } from '../../../entities/Automate/RoutingProfiles/constants';

const FilterForm = (props) => (
  <FilterFormTemplate {...props}>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="name"
        label="Name"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name="description"
        label="Description"
        type="text"
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name="algorithm"
        label="Algorithm"
        options={ALGORITHM_OPTIONS_LIST}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({form: `filters.${EK.AUTOMATE_ROUTING_PROFILES.state}` })
);

export default enhance(FilterForm);
