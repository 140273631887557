import { List } from 'immutable';

import EntityRecord from '../../modules/entities/model';

export const PRODUCT_TIERS = {
  SYNCHRONIZE: 'synchronize',
  STANDARDIZE: 'standardize',
  AUTOMATE: 'automate',
  OPTIMIZE: 'optimize',
};

export const PRODUCT_MODULES = {
  PIPING: 'piping',
  CABLING: 'cabling',
  FRAMEWORK: 'framework',
  ECAD: 'ecad',
  REPORTING: 'reporting'
};

export default class Organization extends EntityRecord({
  name: {
    value: null,
  },
  domain: {
    value: null,
  },
  owner: {
    value: null,
    isEntity: true,
  },
  productTier: {
    value: PRODUCT_TIERS.SYNCHRONIZE,
  },
  productExpiration: {
    value: null,
  },
  productTierUserCount: {
    value: 1,
  },
  productTierUserCountErrorFlag: {
    value: false,
  },
  trialTier: {
    // this is allowed to be null because not all users have a trial
    value: null,
  },
  trialExpiration: {
    value: null,
  },
  moduleCablingUserCount: {
    value: 0,
  },
  moduleCablingUserCountErrorFlag: {
    value: false,
  },
  modulePipingUserCount: {
    value: 0,
  },
  modulePipingUserCountErrorFlag: {
    value: false,
  },
  moduleFrameworkUserCount: {
    value: 0,
  },
  moduleFrameworkUserCountErrorFlag: {
    value: false,
  },
  moduleEcadUserCount: {
    value: 0,
  },
  moduleEcadUserCountErrorFlag: {
    value: false,
  },
  moduleReportingUserCount: {
    value: 0,
  },
  moduleReportingUserCountErrorFlag: {
    value: false,
  },
}) {

  // Product Expiration
  get isNotExpired() {
    return (new Date(this.productExpiration) > new Date() || new Date(this.trialExpiration) > new Date());
  }

  get productExpirationFormatted() {
    return (new Date(this.productExpiration).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
  }

  // Product Privileges
  get hasProductTierSynchronizePrivileges() {
    return this.isNotExpired;
  }

  get hasProductTierStandardizePrivileges() {
    const validTiers = [
      PRODUCT_TIERS.STANDARDIZE,
      PRODUCT_TIERS.AUTOMATE,
      PRODUCT_TIERS.OPTIMIZE
    ];

    const hasValidProductLicense = validTiers.includes(this.productTier) &&
      new Date(this.productExpiration) > new Date();

    const hasValidTrialLicense = this.trialTier &&
      validTiers.includes(this.trialTier) &&
      new Date(this.trialExpiration) > new Date();

    return hasValidProductLicense || hasValidTrialLicense;
  }

  get hasProductTierAutomatePrivileges() {
    const validTiers = [
      PRODUCT_TIERS.AUTOMATE,
      PRODUCT_TIERS.OPTIMIZE
    ];

    const hasValidProductLicense = validTiers.includes(this.productTier) &&
      new Date(this.productExpiration) > new Date();

    const hasValidTrialLicense = this.trialTier &&
      validTiers.includes(this.trialTier) &&
      new Date(this.trialExpiration) > new Date();

    return hasValidProductLicense || hasValidTrialLicense;
  }

  // Product Modules
  get hasModuleCablingPrivileges() {
    return this.moduleCablingUserCount > 0 && this.isNotExpired;
  }

  get hasModulePipingPrivileges() {
    return this.modulePipingUserCount > 0 && this.isNotExpired;
  }

  get hasModuleEcadPrivileges() {
    return this.moduleEcadUserCount > 0 && this.isNotExpired;
  }

  get hasModuleFrameworkPrivileges() {
    return this.moduleFrameworkUserCount > 0 && this.isNotExpired;
  }

  get hasModuleReportingPrivileges() {
    return this.moduleReportingUserCount > 0 && this.isNotExpired;
  }
}
