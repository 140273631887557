import React from 'react';

import Icon from '../../components/common/Icon';

import {
  DashboardPath,

  // Synchronize
  SynchronizeModulePath,
  SynchronizeModuleAppearancesPath,
  SynchronizeModuleConfigProfilesPath,
  SynchronizeModuleMapkeysPath,

  // Standardize
  StandardizeModulePath,
  StandardizeModuleLayersPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleNotesPath,
  StandardizeModuleNoteProfilesPath,
  StandardizeModuleNoteProfilesEditPath,
  StandardizeModuleParameterProfilesPath,
  StandardizeModuleParameterProfilesEditPath,
  StandardizeModuleParametersPath,
  StandardizeModuleModelTemplatesPath,

  // Automate
  AutomateModulePath,
  AutomateModuleRoutingProfilesPath,

  // Cabling
  CablingModulePath,
  CablingModuleSpoolsPath,

  // Piping
  PipingModulePath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleEndTypesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleMaterialsPath,
  PipingModuleBoltNutsPath,
  PipingModuleBoltNutsEditPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  PipingModuleInsulationSpecsPath,

  // ECAD
  EcadModulePath,
  EcadModuleCatalogsPath,

  // Framework
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  FrameworkModuleProfileLibrariesBasePath,
  FrameworkModuleProfilePath,
  FrameworkModuleTablePath,
  FrameworkModuleProfileTablesPath,

  // Reporting
  ReportingModulePath,
  ReportingModuleTemplatesPath,

} from '../../paths';

// Synchronize
import SynchronizeBaseBreadcrumb from '../Synchronize/Base/Breadcrumb';
import SynchronizeAppearancesBreadcrumb from '../Synchronize/Appearances/Breadcrumb';
import SynchronizeConfigProfilesBreadcrumb from '../Synchronize/ConfigProfiles/Breadcrumb';
import SynchronizeMapkeysBreadcrumb from '../Synchronize/Mapkeys/Breadcrumb';

// Standardize
import StandardizeBaseBreadcrumb from '../Standardize/Base/Breadcrumb';
import LayersBreadcrumb from '../Standardize/Layers/Breadcrumb';
import OrientationsBreadcrumb from '../Standardize/Orientations/Breadcrumb';
import SectionsBreadcrumb from '../Standardize/Sections/Breadcrumb';
import SimplifiedRepsBreadcrumb from '../Standardize/SimplifiedReps/Breadcrumb';
import ExplodeStatesBreadcrumb from '../Standardize/ExplodeStates/Breadcrumb';
import LayerStatesBreadcrumb from '../Standardize/LayerStates/Breadcrumb';
import AppearanceStatesBreadcrumb from '../Standardize/AppearanceStates/Breadcrumb';
import StyleStatesBreadcrumb from '../Standardize/StyleStates/Breadcrumb';
import CombinedStatesBreadcrumb from '../Standardize/CombinedStates/Breadcrumb';

import NotesBreadcrumb from '../Standardize/Notes/Breadcrumb';
import NoteProfilesBreadcrumb from '../Standardize/NoteProfiles/Breadcrumb';
import NoteProfilesEditBreadcrumb from '../Standardize/NoteProfilesEdit/Breadcrumb';

import ParametersBreadcrumb from '../Standardize/Parameters/Breadcrumb';
import ParameterProfilesBreadcrumb from '../Standardize/ParameterProfiles/Breadcrumb';
import ParameterProfilesEditBreadcrumb from '../Standardize/ParameterProfilesEdit/Breadcrumb';

import ModelTemplatesBreadcrumb from '../Standardize/ModelTemplates/Breadcrumb';

// Automate
import AutomateBaseBreadcrumb from '../Automate/Base/Breadcrumb';
import AutomateRoutingProfilesBreadcrumb from '../Automate/RoutingProfiles/Breadcrumb';

// Cabling
import CablingModuleBreadcrumb from '../Cabling/Base/Breadcrumb';
import SpoolsBreadcrumb from '../Cabling/Spools/Breadcrumb';

// Piping
import PipingModuleBreadcrumb from '../Piping/Base/Breadcrumb';
import SchedulesBreadcrumb from '../Piping/Schedules/Breadcrumb';
import SizesBreadcrumb from '../Piping/Sizes/Breadcrumb';
import EndTypesBreadcrumb from '../Piping/EndTypes/Breadcrumb';
import FittingCategoriesBreadcrumb from '../Piping/FittingCategories/Breadcrumb';
import MaterialsBreadcrumb from '../Piping/Materials/Breadcrumb';
import BoltNutsBreadcrumb from '../Piping/BoltNuts/Breadcrumb';
import BoltNutsEditBreadcrumb from '../Piping/BoltNutsEdit/Breadcrumb';

import InsulationsBreadcrumb from '../Piping/Insulations/Breadcrumb';
import InsulationsEditBreadcrumb from '../Piping/InsulationsEdit/Breadcrumb';
import InsulationSpecsBreadcrumb from '../Piping/InsulationSpecs/Breadcrumb';
import FittingsBreadcrumb from '../Piping/Fittings/Breadcrumb';
import FittingsEditBreadcrumb from '../Piping/FittingsEdit/Breadcrumb';
import PipesBreadcrumb from '../Piping/Pipes/Breadcrumb';
import PipesEditBreadcrumb from '../Piping/PipesEdit/Breadcrumb';
import PipesBendsBreadcrumb from '../Piping/PipesBends/Breadcrumb';
import PipesBendsEditBreadcrumb from '../Piping/PipesBendsEdit/Breadcrumb';
import PipesBendMachinesBreadcrumb from '../Piping/PipesBendMachines/Breadcrumb';
import PipesBendMachinesEditBreadcrumb from '../Piping/PipesBendMachinesEdit/Breadcrumb';
import PipesBendSpringbacksBreadcrumb from '../Piping/PipesBendSpringbacks/Breadcrumb';
import PipesBendSpringbacksEditBreadcrumb from '../Piping/PipesBendSpringbacksEdit/Breadcrumb';
import PipesMitersBreadcrumb from '../Piping/PipesMiters/Breadcrumb';
import PipesMitersEditBreadcrumb from '../Piping/PipesMitersEdit/Breadcrumb';

import SpecsBreadcrumb from '../Piping/Specs/Breadcrumb';
import SpecsEditBreadcrumb from '../Piping/SpecsEditSwitch/Breadcrumb';
import SpecsFittingsEditBreadcrumb from '../Piping/SpecsFittingsEdit/Breadcrumb';
import SpecsFittingInstancesEditBreadcrumb from '../Piping/SpecsFittingInstancesEdit/Breadcrumb';
import SpecsPipesEditBreadcrumb from '../Piping/SpecsPipesEdit/Breadcrumb';
import SpecsPipeInstancesEditBreadcrumb from '../Piping/SpecsPipeInstancesEdit/Breadcrumb';
import ProjectsBreadcrumb from '../Piping/Projects/Breadcrumb';
import ProjectsEditBreadcrumb from '../Piping/ProjectsEdit/Breadcrumb';

// ECAD
import EcadModuleBreadcrumb from '../ECAD/Base/Breadcrumb';
import CatalogsBreadcrumb from '../ECAD/Catalogs/Breadcrumb';

// Framework
import FrameworkModuleBreadcrumb from '../Framework/Base/Breadcrumb';
import FrameworkProfileLibrariesBreadcrumb, { BaseLibraryBreadcrumb } from '../Framework/ProfileLibraries/Breadcrumb';
import FrameworkProfileBreadcrumb from '../Framework/Profile/Breadcrumb';
import FrameworkTablesBreadcrumb from '../Framework/ProfileTables/Breadcrumb';
import FrameworkTableBreadcrumb from '../Framework/Table/Breadcrumb';

// Reporting
import ReportingModuleBreadcrumb from '../Reporting/Base/Breadcrumb';
import TemplatesBreadcrumb from '../Reporting/Templates/Breadcrumb';


export default [
  {
    path: DashboardPath.url,
    breadcrumb: () => <Icon name="home" />,
    routes: [
      // Synchronize
      {
        path: SynchronizeModulePath.url,
        breadcrumb: SynchronizeBaseBreadcrumb,
        routes: [
          {
            path: SynchronizeModuleAppearancesPath.url,
            breadcrumb: SynchronizeAppearancesBreadcrumb,
          },
          {
            path: SynchronizeModuleConfigProfilesPath.url,
            breadcrumb: SynchronizeConfigProfilesBreadcrumb,
          },
          {
            path: SynchronizeModuleMapkeysPath.url,
            breadcrumb: SynchronizeMapkeysBreadcrumb,
          },
        ],
      },
      // Standardize
      {
        path: StandardizeModulePath.url,
        breadcrumb: StandardizeBaseBreadcrumb,
        routes: [
          {
            path: StandardizeModuleLayersPath.url,
            breadcrumb: LayersBreadcrumb,
          },
          {
            path: StandardizeModuleOrientationsPath.url,
            breadcrumb: OrientationsBreadcrumb,
          },
          {
            path: StandardizeModuleSectionsPath.url,
            breadcrumb: SectionsBreadcrumb,
          },
          {
            path: StandardizeModuleSimplifiedRepsPath.url,
            breadcrumb: SimplifiedRepsBreadcrumb,
          },
          {
            path: StandardizeModuleExplodeStatesPath.url,
            breadcrumb: ExplodeStatesBreadcrumb,
          },
          {
            path: StandardizeModuleLayerStatesPath.url,
            breadcrumb: LayerStatesBreadcrumb,
          },
          {
            path: StandardizeModuleAppearanceStatesPath.url,
            breadcrumb: AppearanceStatesBreadcrumb,
          },
          {
            path: StandardizeModuleStyleStatesPath.url,
            breadcrumb: StyleStatesBreadcrumb,
          },
          {
            path: StandardizeModuleCombinedStatesPath.url,
            breadcrumb: CombinedStatesBreadcrumb,
          },
          {
            path: StandardizeModuleParametersPath.url,
            breadcrumb: ParametersBreadcrumb,
          },
          {
            path: StandardizeModuleParameterProfilesPath.url,
            breadcrumb: ParameterProfilesBreadcrumb,
            routes: [
              {
                path: StandardizeModuleParameterProfilesEditPath.url,
                breadcrumb: ParameterProfilesEditBreadcrumb,
              },
            ],
          },
          {
            path: StandardizeModuleNotesPath.url,
            breadcrumb: NotesBreadcrumb,
          },
          {
            path: StandardizeModuleNoteProfilesPath.url,
            breadcrumb: NoteProfilesBreadcrumb,
            routes: [
              {
                path: StandardizeModuleNoteProfilesEditPath.url,
                breadcrumb: NoteProfilesEditBreadcrumb,
              },
            ],
          },
          {
            path: StandardizeModuleModelTemplatesPath.url,
            breadcrumb: ModelTemplatesBreadcrumb,
          },
        ],
      },
      // Automate
      {
        path: AutomateModulePath.url,
        breadcrumb: AutomateBaseBreadcrumb,
        routes: [
          {
            path: AutomateModuleRoutingProfilesPath.url,
            breadcrumb: AutomateRoutingProfilesBreadcrumb,
          },
        ],
      },
      // Cabling
      {
        path: CablingModulePath.url,
        breadcrumb: CablingModuleBreadcrumb,
        routes: [
          {
            path: CablingModuleSpoolsPath.url,
            breadcrumb: SpoolsBreadcrumb,
          },
        ],
      },
      // Piping
      {
        path: PipingModulePath.url,
        breadcrumb: PipingModuleBreadcrumb,
        routes: [
          {
            path: PipingModuleBoltNutsPath.url,
            breadcrumb: BoltNutsBreadcrumb,
            routes: [
              {
                path: PipingModuleBoltNutsEditPath.url,
                breadcrumb: BoltNutsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleInsulationsPath.url,
            breadcrumb: InsulationsBreadcrumb,
            routes: [
              {
                path: PipingModuleInsulationsEditPath.url,
                breadcrumb: InsulationsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleFittingsPath.url,
            breadcrumb: FittingsBreadcrumb,
            routes: [
              {
                path: PipingModuleFittingsEditPath.url,
                breadcrumb: FittingsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModulePipesPath.url,
            breadcrumb: PipesBreadcrumb,
            routes: [
              {
                path: PipingModulePipesEditPath.url,
                breadcrumb: PipesEditBreadcrumb,
                routes: [
                  {
                    path: PipingModulePipesBendsPath.url,
                    breadcrumb: PipesBendsBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendsEditPath.url,
                        breadcrumb: PipesBendsEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesBendMachinesPath.url,
                    breadcrumb: PipesBendMachinesBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendMachinesEditPath.url,
                        breadcrumb: PipesBendMachinesEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesBendSpringbacksPath.url,
                    breadcrumb: PipesBendSpringbacksBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendSpringbacksEditPath.url,
                        breadcrumb: PipesBendSpringbacksEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesMitersPath.url,
                    breadcrumb: PipesMitersBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesMitersEditPath.url,
                        breadcrumb: PipesMitersEditBreadcrumb,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PipingModuleSpecsPath.url,
            breadcrumb: SpecsBreadcrumb,
            routes: [
              {
                path: PipingModuleSpecsEditPath.url,
                breadcrumb: SpecsEditBreadcrumb,
                routes: [
                  {
                    path: PipingModuleSpecsFittingsEditPath.url,
                    breadcrumb: SpecsFittingsEditBreadcrumb,
                    routes: [
                      {
                        path:
                          PipingModuleSpecsFittingInstancesEditPath.url,
                        breadcrumb: SpecsFittingInstancesEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModuleSpecsPipesEditPath.url,
                    breadcrumb: SpecsPipesEditBreadcrumb,
                    routes: [
                      {
                        path: PipingModuleSpecsPipeInstancesEditPath.url,
                        breadcrumb: SpecsPipeInstancesEditBreadcrumb,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PipingModuleProjectsPath.url,
            breadcrumb: ProjectsBreadcrumb,
            routes: [
              {
                path: PipingModuleProjectsEditPath.url,
                breadcrumb: ProjectsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleSchedulesPath.url,
            breadcrumb: SchedulesBreadcrumb,
          },
          {
            path: PipingModuleSizesPath.url,
            breadcrumb: SizesBreadcrumb,
          },
          {
            path: PipingModuleEndTypesPath.url,
            breadcrumb: EndTypesBreadcrumb,
          },
          {
            path: PipingModuleFittingCategoriesPath.url,
            breadcrumb: FittingCategoriesBreadcrumb,
          },
          {
            path: PipingModuleMaterialsPath.url,
            breadcrumb: MaterialsBreadcrumb,
          },
          {
            path: PipingModuleInsulationSpecsPath.url,
            breadcrumb: InsulationSpecsBreadcrumb,
          },
        ],
      },
      // ECAD
      {
        path: EcadModulePath.url,
        breadcrumb: EcadModuleBreadcrumb,
        routes: [
          {
            path: EcadModuleCatalogsPath.url,
            breadcrumb: CatalogsBreadcrumb,
          },
        ],
      },
      // Reporting
      {
        path: ReportingModulePath.url,
        breadcrumb: ReportingModuleBreadcrumb,
        routes: [
          {
            path: ReportingModuleTemplatesPath.url,
            breadcrumb: TemplatesBreadcrumb,
          },
        ],
      },
      // Framework
      {
        path: FrameworkModulePath.url,
        breadcrumb: FrameworkModuleBreadcrumb,
        routes: [
          {
            path: FrameworkModuleProfileLibrariesPath.url,
            breadcrumb: FrameworkProfileLibrariesBreadcrumb,
            routes: [
              {
                path: FrameworkModuleProfileLibrariesBasePath.url,
                breadcrumb: BaseLibraryBreadcrumb,
                routes: [
                  {
                    path: FrameworkModuleProfilePath.url,
                    breadcrumb: FrameworkProfileBreadcrumb,
                    routes: [
                      {
                        path: FrameworkModuleProfileTablesPath.url,
                        breadcrumb: FrameworkTablesBreadcrumb,
                        routes: [
                          {
                            path: FrameworkModuleTablePath.url,
                            breadcrumb: FrameworkTableBreadcrumb,
                          }
                        ]
                      }
                    ]
                  },
                ]
              }
            ]
          },
        ]
      }
    ],
  },
];
