export const convertToFittingInstanceType = ({
  id,
  cadModelName,
  schedule,
  creoValidated,
  fittingCode,
  port0Size,
  port0EndType,
  port1Size,
  port1EndType,
  port2Size,
  port2EndType,
  stockno,
  mccsCode,
  selectionName,
  isCreated=false,
  isEdited=false,
  isDeleted=false,
}) => ({
  id,
  cadModelName,
  schedule,
  creoValidated,
  fittingCode,
  port0Size,
  port0EndType,
  port1Size,
  port1EndType,
  port2Size,
  port2EndType,
  stockno,
  mccsCode,
  selectionName,
  isCreated,
  isEdited,
  isDeleted,
});

export const saveCurrentIsulationInstanceChanges = (
  changes,
  saveAction,
) => {
  const collectionsdata = [];

  changes.forEach((item) => {
    if (item.isCreated) {
      collectionsdata.push({
        cadModelName: item.cadModelName,
        scheduleId: typeof(item.schedule) == 'string' ? item.schedule : item.schedule.id,
        port_0SizeId: item.port0Size?.id,
        port_0EndTypeId: item.port0EndType?.id,
        port_1SizeId: item.port1Size?.id,
        port_1EndTypeId: item.port1EndType?.id,
        port_2SizeId: item.port2Size?.id,
        port_2EndTypeId: item.port2EndType?.id,
        selectionName: item.selectionName,
        stockno: item.stockno,
        mccsCode: item.mccsCode,
      });
    } else if (item.isDeleted) {
      collectionsdata.push({ id: item.id, _destroy: '1' });
    } else if (item.isEdited) {
      collectionsdata.push({
        id: item.id,
        cadModelName: item.cadModelName,
        scheduleId: typeof (item.schedule) == 'string' ? item.schedule : item.schedule.id,
        port_0SizeId: item.port0Size?.id,
        port_0EndTypeId: item.port0EndType?.id,
        port_1SizeId: item.port1Size?.id,
        port_1EndTypeId: item.port1EndType?.id,
        port_2SizeId: item.port2Size?.id,
        port_2EndTypeId: item.port2EndType?.id,
        selectionName: item.selectionName,
        stockno: item.stockno,
        mccsCode: item.mccsCode,
      });
    }
  });

  collectionsdata.length > 0 && saveAction(collectionsdata);
};