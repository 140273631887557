import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';

import { SynchronizeModulePath } from '../../../paths';

export default function SynchronizeBaseBreadcrumb() {
  return (
    <BreadcrumbTemplate title={SynchronizeModulePath.defaultTitle}></BreadcrumbTemplate>
  );
}
