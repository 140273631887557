import React from 'react';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import DialogFooter from '../../../../components/modal/ModalFooters/DialogFooter';
import DeleteDialogFooter from '../../../../components/modal/ModalFooters/DeleteDialogFooter';

import Text from '../../../../components/common/Text';
import { ALLOWANCES } from '../../../../entities/Piping/BoltNutFamilies/model';


export const BoltNutDialog = ({ currentEditingRow, editable, getDialogHeader, handleEditedRowChange, isCreateDialogOpen = false, isCopyDialogOpen = false, isDialogOpen, openDeleteDialog = false, saveAction, cancelDialogs, validation,}) =>
  <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={() => getDialogHeader()} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
    <div>
      <label>Name</label>
      <InputText keyfilter={ALLOWANCES.NAME} value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
    </div>
    <div>
      <label>Bolt Nut Code</label>
      <InputText keyfilter={ALLOWANCES.BOLT_NUT_CODE} value={currentEditingRow.boltNutCode} onChange={(e) => handleEditedRowChange(e.target.value, 'boltNutCode')} className={!validation.boltNutCode && 'p-invalid'} />
    </div>
    <div>
      <label>Description</label>
      <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')} />
    </div>
  </Dialog>;

export const DeleteBoltNutDialog = ({ editable, isDeleteDialogOpen, currentEditingRow, setIsDeleteDialogOpen, deleteAction }) =>
  <Dialog visible={editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Bolt Nut Family '${currentEditingRow.name}'`} footer={() => DeleteDialogFooter(() => setIsDeleteDialogOpen(false), deleteAction)} closable={false} >
    <div>
      <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
      <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
      <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
    </div>
  </Dialog>;
