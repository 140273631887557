import { take, put, fork, select } from 'redux-saga/effects';

import { processFetchAllLayers } from '../../../entities/Standardize/Layers/actions';
import { processFetchAllNoteProfiles } from '../../../entities/Standardize/NoteProfiles/actions';
import { processFetchAllNotes } from '../../../entities/Standardize/Notes/actions';
import { processFetchAllOrientations } from '../../../entities/Standardize/Orientations/actions';
import { processFetchAllParameterProfiles } from '../../../entities/Standardize/ParameterProfiles/actions';
import { processFetchAllParameters } from '../../../entities/Standardize/Parameters/actions';
import { processFetchAllSections } from '../../../entities/Standardize/Sections/actions';
import { processFetchAllGlobalCadSearchRules } from '../../../entities/GlobalCadSearchRules/actions';
import { processFetchAllSimplifiedReps } from '../../../entities/Standardize/SimplifiedReps/actions';
import { processFetchAllModelTemplates } from '../../../entities/Standardize/ModelTemplates/actions';
import { processFetchAllExplodeStates } from '../../../entities/Standardize/ExplodeStates/actions';
import { processFetchAllCombinedStates } from '../../../entities/Standardize/CombinedStates/actions';
import { processFetchAllLayerStates } from '../../../entities/Standardize/LayerStates/actions';
import { processFetchAllAppearanceStates } from '../../../entities/Standardize/AppearanceStates/actions';
import { processFetchAllStyleStates } from '../../../entities/Standardize/StyleStates/actions';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { signout } from '../../../modules/auth/sagas';

import { isInitialLoadComplete } from '../../Dashboard/actions';

export function* watchLoadStandardizeModuleSaga() {
  try {
    yield put(processFetchAllLayers());
    yield put(processFetchAllNoteProfiles());
    yield put(processFetchAllNotes());
    yield put(processFetchAllOrientations());
    yield put(processFetchAllParameterProfiles());
    yield put(processFetchAllParameters());
    yield put(processFetchAllSections());
    yield put(processFetchAllGlobalCadSearchRules());
    yield put(processFetchAllSimplifiedReps());
    yield put(processFetchAllModelTemplates());
    yield put(processFetchAllExplodeStates());
    yield put(processFetchAllCombinedStates());
    yield put(processFetchAllLayerStates());
    yield put(processFetchAllAppearanceStates());
    yield put(processFetchAllStyleStates());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadStandardizeModuleSaga);
}
