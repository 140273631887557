import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import radii from '../../../../../../../assets/themes/base/radii';
import space from '../../../../../../../assets/themes/base/space';
import borders from '../../../../../../../assets/themes/base/borders';

import Button from '../../../../../../../components/common/Button';
import Flex from '../../../../../../../components/common/Flex';
import Icon from '../../../../../../../components/common/Icon';
import Text from '../../../../../../../components/common/Text';

const ListItem = styled(Flex)`
  padding-right: ${space[3]};
  border-bottom: ${borders[2]};

  &:first-of-type {
    border-top-left-radius: ${radii[2]};
    border-top-right-radius: ${radii[2]};
  }

  &:last-of-type {
    border-bottom-left-radius: ${radii[2]};
    border-bottom-right-radius: ${radii[2]};
    border-bottom: none;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      &:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`;

const Handle = styled(Flex)`
  cursor: move;
  flex-direction: column;
`;

const MinimizableListItem = ({
  item,
  isSelected,
  isHidden,
  sortable,
  onToggleSelect,
  onEdit,
  onDelete,
}) => {
  const handleToggleSelect = useCallback(() => onToggleSelect(item), [
    item,
    onToggleSelect,
  ]);
  const handleEdit = useCallback(() => onEdit && onEdit(item), [item, onEdit]);
  const handleDelete = useCallback(() => onDelete && onDelete(item), [
    item,
    onDelete,
  ]);

  if (isHidden) {
    return null;
  }

  return (
    <ListItem $isSelected={isSelected} flexDirection="row">
      {sortable && !isSelected && (
        <Handle pl={2} pr={2} bg="gray.1">
          <Icon color="gray.6" name="move" />
        </Handle>
      )}
      <Flex
        flexDirection="column"
        justifyContent="center"
        onClick={handleToggleSelect}
        cursor="pointer"
        pl={3}
        pr={3}
      >
        <Icon
          fontSize={6}
          color="primary.4"
          name={isSelected ? 'chevron-left' : 'chevron-right'}
        />
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        style={{ flexGrow: 1 }}
        onClick={handleToggleSelect}
        cursor="pointer"
        pl={2}
        pt={3}
        pb={3}
      >
        <Text
          fontSize={2}
          color={isSelected ? 'primary.4' : 'gray.7'}
          fontWeight={isSelected ? '600' : '400'}
        >
          {item.primary || item.primaryPlaceholder}
        </Text>
        <Text
          fontSize={1}
          color={isSelected ? 'primary.3' : 'gray.6'}
          fontWeight={isSelected ? '600' : '400'}
        >
          {item.secondary || item.secondaryPlaceholder}
        </Text>
      </Flex>
      {onEdit && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button transparent subtle primary icon="edit" onClick={handleEdit} />
        </Flex>
      )}
      {onDelete && (
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            transparent
            subtle
            error
            icon="delete"
            onClick={handleDelete}
          />
        </Flex>
      )}
    </ListItem>
  );
};

export default MinimizableListItem;
