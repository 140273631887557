import { compose } from 'redux';
import { Switch } from 'react-router-dom';

import EK from '../../../entities/keys';

import injectReducer from '../../../utils/reducers/injectReducer';

import editSpecsReducer from './reducer';

import injectSaga from '../../../utils/sagas/injectSaga';

import saga from './sagas';

const enhance = compose(
  injectReducer({
    key: `${EK.SPECS.state}EditSorting`,
    reducer: editSpecsReducer,
  }),
  injectSaga({ key: `${EK.SPECS.state}Edit`, saga })
);

export default enhance(Switch);
