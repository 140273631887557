import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

class GlobalCadSearchRule extends EntityRecord({
  name: {
    value: null,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    filter: FILTERS.STRING,
  },
  isValidForSimplifiedRep: {
    value: false,
    filter: FILTERS.BOOLEAN,
  },
  globalCadSearchRuleCriteria: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'globalCadSearchRuleCriterionIds',
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}

export default GlobalCadSearchRule;
