import React, { useMemo } from 'react';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';
import { connect } from 'react-redux';
import EK from '../../../entities/keys';
import BaseFormRow from '../../../components/form/BaseFormRow';
import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';
import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

import {
  CAD_MODEL_TYPES,
  CAD_MODEL_TYPE_OPTIONS,
  CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
  CAD_MODEL_PART_SUBTYPE_OPTIONS,
  CAD_MODEL_DRAWING_SUBTYPE_OPTIONS,
} from '../../../entities/Standardize/ModelTemplates/model';

const FilterForm = ({ cadModelType, ...props }) => {
  // Memoize the sorting function
  const sortByPrimary = useMemo(() => (
    (options) => [...options].sort((a, b) => a.primary.localeCompare(b.primary))
  ), []);

  // Memoize the options
  const subtypeOptions = useMemo(() => {
    switch (cadModelType?.value || cadModelType) {
    case CAD_MODEL_TYPES.ASSEMBLY:
      return CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS;
    case CAD_MODEL_TYPES.PART:
      return CAD_MODEL_PART_SUBTYPE_OPTIONS;
    case CAD_MODEL_TYPES.DRAWING:
      return CAD_MODEL_DRAWING_SUBTYPE_OPTIONS;
    default:
      return [
        ...CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS,
        ...CAD_MODEL_PART_SUBTYPE_OPTIONS,
        ...CAD_MODEL_DRAWING_SUBTYPE_OPTIONS,
      ];
    }
  }, [cadModelType]);

  const sortedSubtypeOptions = useMemo(() =>
    List(sortByPrimary(subtypeOptions))
  , [sortByPrimary, subtypeOptions]);

  return (
    <FilterFormTemplate {...props}>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          type="text"
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name='cadModelType'
          label='Model Type'
          options={List(CAD_MODEL_TYPE_OPTIONS)}
          clearable
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name='cadModelSubtype'
          label='Model SubType'
          options={sortedSubtypeOptions}
          clearable
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="modelTemplateModelName"
          label="Model Name"
          type="text"
        />
      </BaseFormRow>
    </FilterFormTemplate>
  );
};

const selector = formValueSelector(`filters.${EK.MODEL_TEMPLATES.state}`);

const mapStateToProps = (state) => ({
  cadModelType: selector(state, 'cadModelType')
});

const enhance = compose(
  reduxForm({ form: `filters.${EK.MODEL_TEMPLATES.state}` }),
  connect(mapStateToProps)
);

export default enhance(FilterForm);
