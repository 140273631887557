import { takeLatest, put, fork, call } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { EmailNotVerifiedPath } from '../../paths';

import { processLoginTypes } from '../../modules/auth/constants';

import { identify, setUserProperties, track } from '../../configuration/mixpanel';

export function* handleLoginSuccess({ payload = {} }) {
  const { authenticationToken, emailVerified, user } = payload;

  if (!authenticationToken && !emailVerified) {
    yield put(push(EmailNotVerifiedPath.url));
    return;
  }

  if (user) {
    // Track login in Mixpanel
    yield call(identify, user.id);
    yield call(setUserProperties, {
      $email: user.email,
      $name: `${user.firstName} ${user.lastName}`.trim(),
      organization: user.organization?.name,
      role: user.role
    });
    yield call(track, 'User Logged In');
  }
}

export function* watchLoginSaga() {
  yield takeLatest(processLoginTypes.SUCCESS, handleLoginSuccess);
}

// final output saga
export default function* main() {
  yield fork(watchLoginSaga);
}
