import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { AutomateModuleRoutingProfilesPath } from '../../../paths';

export default function AutomateRoutingProfilesBreadcrumb() {
  return (
    <BreadcrumbTemplate
      title={AutomateModuleRoutingProfilesPath.defaultTitle}
    />
  );
}