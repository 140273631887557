import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import noteProfileSchema from './schema';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';

export const selectNormalizedNoteProfiles = () =>  createSelector(
  selectEntities(),
  entities => entities.get('noteProfiles')
);
export const selectNormalizedNoteProfilesList = () => createSelector(
  selectNormalizedNoteProfiles(),
  noteProfiles => noteProfiles.toList()
);
export const selectNoteProiles = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.NOTE_PROFILES.state),
      [noteProfileSchema],
      entities
    )
  );

const noteProfileFilterForm = formValueSelector(
  `filters.${EK.NOTE_PROFILES.state}`
);

export const selectCurrentFilteredNoteProfiles = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectIsShowingArchived(),
    selectNormalizedNoteProfiles(),
    state => ({
      name: noteProfileFilterForm(state, 'name'),
    }),
    (isFiltering, isShowingArchived, noteProfiles, query) =>
      isFiltering
        ? noteProfiles.filter((np) => (isShowingArchived || !np.archivedFlag) && np.doesMatchQuery(query)).toList()
        : noteProfiles.filter(np => !np.archivedFlag).toList()
  );

export const selectCurrentNoteProfilesCount = () =>
  createSelector(
    selectNormalizedNoteProfiles(),
    (noteProfiles) => (noteProfiles && noteProfiles.toList() && noteProfiles.toList().size) || 0
  );