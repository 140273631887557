import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'CABLING_SPOOLS';

export const fetchAllSpools = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createSpool = generateAPHTypes(
  resource,
  'CREATE'
);

export const editSpool = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteSpool = generateAPHTypes(
  resource,
  'DELETE'
);

export const copySpool = generateAPHTypes(
  resource,
  'COPY'
);

export const archiveSpool = generateAPHTypes(
  resource,
  'ARCHIVE'
);