import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'NOTE_BLOCKS';

export const createNoteBlocks = generateAPHTypes(resource, 'CREATE');

export const editNoteBlocks = generateAPHTypes(resource, 'EDIT');

export const deleteNoteBlocks = generateAPHTypes(resource, 'DELETE');

export const sortNoteBlockNotes = generateAPHTypes(resource, 'SORT_CHILDREN');