/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell bodies/editors
import Text from '../../../components/common/Text';
import colors from '../../../assets/themes/base/colors';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { codeBody } from '../Fittings/components';
import categoryBody from '../../../components/grid/FittingCategoryBody';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSpecsFittingInstancesEditPath, PipingModuleSpecsFittingsEditPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../SpecsEditSwitch/Options';
import Skeleton from 'react-loading-skeleton';
import { saveCurrentPipingSpecFittingFamiliesChanges } from './actions';
import { pluralize } from '../../../components/common/Header';
import { push } from 'connected-react-router';
import SpecsDialog from '../../../containers/dialog/templates/Piping/SpecsDialog';

// validation
import useRowStyles from '../../../components/primeGrid/RowStyles';
import { VALIDATION_FIELDS, } from '../../../entities/Piping/Specs/model';
import { areFieldsValid, doRequiredFieldsExist } from '../Specs/components';

// actions
import { processFetchFittingFamiliesForSpec } from '../../../entities/Piping/FittingFamilies/actions';
import { processEditSpec, processFetchAllSpecs, processFetchIndividualSpec } from '../../../entities/Piping/Specs/actions';

// selectors
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { removeDuplicateFromObjectIdArray, selectCurrentSpec, selectOrderedFilteredUnarchivedFittingFamilies } from '../../../entities/Piping/Specs/selectors';
import Button from '../../../components/common/Button';
import { selectisPrioritizedFamilySortingEnabled } from '../SpecsEditSwitch/selectors';
import { selectDenormalizedFittingInstances } from '../../../entities/Piping/FittingInstances/selectors';
import { selectFittingFamiliesCounts } from '../../../entities/Piping/FittingFamilies/selectors';

const mapStateToProps = (specId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectOrderedFilteredUnarchivedFittingFamilies(specId),
  selectCurrentSpec(specId),
  selectisPrioritizedFamilySortingEnabled(),
  selectDenormalizedFittingInstances(),
  selectFittingFamiliesCounts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    spec,
    isPrioritizedFamilySortingEnabled,
    instances,
    { total, unarchived },
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      spec,
      isPrioritizedFamilySortingEnabled,
      instances,
      count: total,
    };
  },
);

function SpecFittingFamiliesGrid({ match, location }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // editing state
  const [editedRows, setEditedRows] = useState({});
  const [editedOrder, setEditedOrder] = useState([]);
  const [orderedRows, setOrderedRows] = useState(reduxProps.data);
  const rowStyles = useRowStyles(true);

  // dialog state
  const [currentEditingSpec, setCurrentEditingSpec] = useState(reduxProps.spec);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const handleEditedSpecChange = (newValue, field) => {
    // called on every edit
    setCurrentEditingSpec((prev) => {
      let _editedRow = { ...prev };
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  const openDialog = () => {
    const currentNonEntityRow = {
      id: reduxProps.spec.id,
      specName: reduxProps.spec.specName,
      mnemonic: reduxProps.spec.mnemonic,
      layerName: reduxProps.spec.layerName,
      appearance: reduxProps.spec.appearance,
      defaultInsulationSpec: reduxProps.spec.defaultInsulationSpec
    };
    setCurrentEditingSpec(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    setIsDialogOpen(false);
  };

  const saveSpecAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(currentEditingSpec));
    if (!doRequiredFieldsExist(currentEditingSpec)) {
      return;
    }

    const editedRow = {
      id: currentEditingSpec.id,
      specName: currentEditingSpec.specName,
      mnemonic: currentEditingSpec?.mnemonic,
      layerName: currentEditingSpec?.layerName,
      appearanceId: currentEditingSpec.appearance.id,
      defaultInsulationSpecId: currentEditingSpec?.defaultInsulationSpec?.id
    };

    dispatch(processEditSpec(editedRow.id, editedRow));
    cancelDialogs();
  };

  // reorder funcs
  const handleOnRowReorder = (e) => {
    const sorted = e.value.filter((fam) => {
      if (editedRows[fam.id]) {
        return editedRows[fam.id].isPrioritized;
      }
      return fam.isPrioritized;
    }).concat(e.value.filter((fam) => {
      if (editedRows[fam.id]) {
        return !editedRows[fam.id].isPrioritized;
      }
      return !fam.isPrioritized;
    }));

    if (editedRows[e.value[e.dropIndex].id]) {
      setEditedRows((prev) => {
        let _editedRows = { ...prev };
        _editedRows[e.value[e.dropIndex].id] = {
          ...editedRows[e.value[e.dropIndex].id],
          isEdited: true,
        };
        return _editedRows;
      });
    } else {
      setEditedRows((prev) => {
        let _editedRows = { ...prev };
        _editedRows[e.value[e.dropIndex].id] = {
          ...e.value[e.dropIndex],
          isEdited: true,
        };
        return _editedRows;
      });
    }
    setEditedOrder(sorted);
    setOrderedRows(sorted);
  };

  const rowCanReorder = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return !curRow.isPrioritized && 'hide-row-reorder-button';
  };

  //Cell displays
  const fittingFamilyBody = (rowdata, field, placeholder) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const instanceBody = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return curRow.isSelectedForSpec ? <Text>{`${curRow.selectedFittingInstancesForSpec} selected / ${curRow.fittingInstances.size} ${pluralize('instance', curRow.fittingInstances.size)}`}</Text> :
      <Text>{`${curRow.fittingInstances.size} ${pluralize('instance', curRow.fittingInstances.size)}`}</Text>;
  };

  // cell actions
  // this method assumes that the item is selected FOr the spec (filtering happens in a useEffect to ensure this is the case).
  //cases where the item is not selected for the spec are handled inside of the 'toggleForSpec' method
  const handlePrioritizedRowsChange = (fittingFamily, prevRows) => {
    let _orderedRows = [...prevRows];
    const curItem = _orderedRows.findIndex(item => item.id == fittingFamily.id);
    const _curRow = _orderedRows[curItem];
    switch (fittingFamily.isPrioritized) {
    case true:
      curItem == -1 && _orderedRows.push(fittingFamily.originalData || fittingFamily);
      break;
    case false:
      curItem != -1 && _orderedRows.splice(curItem, 1);
      _orderedRows.push(_curRow);
      break;
    }
    return _orderedRows;
  };

  const toggleForPriority = (fittingFamily) => {
    const newFamily = {
      id: fittingFamily.id,
      fittingInstances: fittingFamily.fittingInstances,
      isSelectedForSpec: fittingFamily.isSelectedForSpec,
      isPrioritized: !fittingFamily.isPrioritized,
      selectedFittingInstancesForSpec: fittingFamily.selectedFittingInstancesForSpec,
      isEdited: true,
      name: fittingFamily.name,
      material: fittingFamily.material,
      originalData: fittingFamily.isEdited && fittingFamily.originalData || fittingFamily,
    };

    setEditedRows((prev) => {
      let _editedRows = { ...prev };
      _editedRows[fittingFamily.id] = newFamily;
      if (fittingFamily.isEdited) {
        if (!fittingFamily.isPrioritized == fittingFamily.originalData.isPrioritized && fittingFamily.isSelectedForSpec == fittingFamily.originalData.isSelectedForSpec) {
          delete _editedRows[fittingFamily.id];
          return _editedRows;
        }
      }
      return _editedRows;
    });

    setOrderedRows((prevRows) => handlePrioritizedRowsChange(newFamily, prevRows));
  };

  const toggleForSpec = (fittingFamily) => {
    if (fittingFamily.fittingInstances.size <= 0) {
      return;
    }
    const originalData = fittingFamily.originalData || fittingFamily;
    const newFamily = {
      id: fittingFamily.id,
      fittingInstances: fittingFamily.fittingInstances,
      isSelectedForSpec: !fittingFamily.isSelectedForSpec,
      selectedFittingInstancesForSpec: !fittingFamily.isSelectedForSpec && fittingFamily.originalData.fittingInstances?.size || 0,
      isPrioritized: !fittingFamily.isSelectedForSpec ? originalData.isPrioritized : false,
      isEdited: true,
      name: fittingFamily.name,
      material: fittingFamily.material,
      originalData: originalData,
    };

    setEditedRows((prev) => {
      let _editedRows = { ...prev };

      if (fittingFamily.isEdited) {
        // if item is not selected for the spec and was not originally selected for spec, remove from changes
        // if item is slected for the spec and was originally selected for the spec, and the prioritization has not changed, remove from changes
        if (!newFamily.isSelectedForSpec && !newFamily.originalData.isSelectedForSpec) {
          delete _editedRows[newFamily.id];
        } else if (newFamily.isSelectedForSpec && newFamily.originalData.isSelectedForSpec && newFamily.isPrioritized === newFamily.originalData.isPrioritized) {
          delete _editedRows[newFamily.id];
        } else {
          _editedRows[fittingFamily.id] = newFamily;
        }
      } else {
        _editedRows[fittingFamily.id] = newFamily;
      }
      return _editedRows;
    });

    setOrderedRows((prevRows) => handlePrioritizedRowsChange(newFamily, prevRows));
  };

  // Cell Editors
  const SelectItemForPriorityCellBody = ({ fittingFamily }) => <Button icon='star' iconFillColor={fittingFamily.isPrioritized ? 'primary.4' : 'gray.4'} onClick={() => toggleForPriority(fittingFamily)} ></Button>;
  const SelectItemForSpecCellBody = ({ fittingFamily }) => <Button icon={fittingFamily.isSelectedForSpec ? 'checkbox' : 'checkbox-outline'} iconFillColor={fittingFamily.isSelectedForSpec ? 'primary.4' : 'gray.6'} onClick={() => toggleForSpec(fittingFamily)} ></Button>;

  const PipingSpecsRowTool = (rowdata) => {
    const curRow = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);

    if (curRow) {
      return reduxProps.isPrioritizedFamilySortingEnabled ?
        // eslint-disable-next-line react/no-unknown-property
        <SelectItemForPriorityCellBody fittingFamily={curRow} /> :
        <SelectItemForSpecCellBody fittingFamily={curRow} />;
    }
  };

  const handleRowAction = (item, iconName) => {
    setEditedRows((prevRows) => {
      let _rows = { ...prevRows };
      switch (iconName) {
      case 'undo':
        delete _rows[item.id];
        break;
      }
      return _rows;
    });
  };

  const getRowTool = (rowdata) => {
    const data = rowdata && (editedRows && editedRows[rowdata.id] || rowdata);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  // save action
  const saveAction = (collectionsData) => {
    let callData = {
      id: match.params.id
    };

    const meditedOrder = orderedRows.filter(item => editedRows[item.id] ? editedRows[item.id].isPrioritized && editedRows[item.id].isSelectedForSpec : !!item.isPrioritized ?? item.isSelectedForSpec);

    if (`[${meditedOrder.map(fam => fam.id)}]` != `[${reduxProps.spec?.prioritizedFittingFamilies?.map(fam => fam.id)}]`) {
      callData.prioritized_fitting_families = meditedOrder && `[${meditedOrder.map(fam => fam.id)}]` || reduxProps.spec?.prioritizedFittingFamilies?.map(fam => fam.id);
      dispatch(processEditSpec(match.params.id, callData));
    }
    let allinst = [];
    collectionsData.length > 0 && collectionsData.forEach(family => {
      allinst = allinst.concat(family.data);
    });

    collectionsData.length > 0 && dispatch(processEditSpec(match.params.id, { specFittingInstancesAttributes: allinst }));
    setEditedRows({});
  };

  useEffect(() => {
    dispatch(processFetchFittingFamiliesForSpec());
    dispatch(processFetchAllSpecs());
    dispatch(processFetchIndividualSpec(match.params.id));
  }, []);

  useEffect(() => {
    let _data = removeDuplicateFromObjectIdArray(editedOrder.concat(reduxProps.data));
    if (editedRows && Object.keys(editedRows).length > 0 && reduxProps.isPrioritizedFamilySortingEnabled) {
      for (const [key, value] of Object.entries(editedRows)) {
        const dataItemIndex = _data.findIndex(item => item.id == key);
        if (dataItemIndex != -1) {
          if (value.isSelectedForSpec) { // place selected items in their original location
            _data[dataItemIndex] = value.originalData || value;
          } else { // remove unselected items
            _data.splice(dataItemIndex, 1);
          }
        } else { // push new items to the end of the list
          _data.push(value.originalData || value);
        }
      }
      setOrderedRows(_data);
    } else {
      setOrderedRows(reduxProps.data);
    }
  }, [reduxProps.isLoading, reduxProps.isPrioritizedFamilySortingEnabled]);

  useEffect(() => {
    reduxProps.isPrioritizedFamilySortingEnabled && setEditedOrder(orderedRows.filter(item => editedRows[item.id] ? editedRows[item.id].isPrioritized && editedRows[item.id].isSelectedForSpec : !!item.isPrioritized ?? item.isSelectedForSpec));
  }, [orderedRows]);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.spec?.specName && `${reduxProps.spec.specName} // ${PipingModuleSpecsFittingsEditPath.defaultTitle}`) || 'Loading...'}
          subtitle={!reduxProps.isFiltering ? `${reduxProps.data.length} ${pluralize('Fitting Family', reduxProps.data.length)}` : `Showing ${reduxProps.data.length}/${reduxProps.count} ${pluralize('filtered Fitting', reduxProps.count)}`}
        />
        <Options
          location={location}
          specId={(match.params.id)}
          editSpec={openDialog}
          updateEntities={() => saveCurrentPipingSpecFittingFamiliesChanges(editedRows, reduxProps.spec?.specFittingInstances?.toArray() || [], saveAction)}
          pendingValidChanges={Object.keys(editedRows)?.length > 0}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableRows={reduxProps.isPrioritizedFamilySortingEnabled}
          onRowReorder={handleOnRowReorder}
          value={reduxProps.isPrioritizedFamilySortingEnabled ? orderedRows : reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} field='id' body={getRowTool} ></Column>
          {reduxProps.isPrioritizedFamilySortingEnabled && <Column rowReorder bodyClassName={rowCanReorder} style={{ width: '2%' }}></Column>}
          <Column header='' style={{ width: '2%' }} body={(rowdata) => PipingSpecsRowTool(rowdata, reduxProps.isPrioritizedFamilySortingEnabled)} ></Column>
          <Column
            field='name'
            header='Name'
            style={{ width: '20%' }}
            body={(rowdata) => textValueBody(rowdata, 'name', '')}
            sortable
          />
          <Column
            field='instances'
            header={'Instances'}
            body={(rowdata) => instanceBody(rowdata)}
            style={{ width: '10%' }}
            bodyStyle={{ fontSize: '.875rem' }}
            sortable
          />
          <Column
            field='fittingCategory'
            header={'Fitting Category'}
            body={(rowdata) => rowdata.fittingCategory && categoryBody(rowdata.fittingCategory)}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='fittingCode'
            header={'Fitting Code'}
            body={(rowdata) => codeBody(rowdata)}
            style={{ width: '10%' }}
            sortable
          />
          <Column
            field='material'
            header={'Material'}
            body={(rowdata) => fittingFamilyBody(rowdata, 'material', 'Material')}
            style={{ width: '15%' }}
            sortable
          />
          <Column
            header=''
            style={{ textAlign: 'right' }}
            body={(rowdata) =>
              <Button
                icon='list'
                onClick={() => dispatch(push(PipingModuleSpecsFittingInstancesEditPath.generate({ id: match.params.id, familyId: rowdata.id })))}
              />
            }
          />
        </DataTable>
      </Flex>
      <SpecsDialog
        currentEditingRow={currentEditingSpec}
        handleEditedRowChange={handleEditedSpecChange}
        isDialogOpen={isDialogOpen}
        saveAction={saveSpecAction}
        cancelDialogs={cancelDialogs}
        editable={reduxProps.editable}
        editSpec={openDialog}
      />
    </>
  );
}

export default withRouter(SpecFittingFamiliesGrid);