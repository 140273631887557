import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import EK from '../../../../../entities/keys';

import selectEntities from '../../../../../modules/entities/selectors';

import SimplifiedRepRulesetSchema from '../../../../../entities/Standardize/SimplifiedRepRulesets/schema';

export const selectCurrentSimplifiedRep = () =>
  createSelector(
    selectEntities(),
    (_, ownProps) => ownProps.simplifiedRepId,
    (entities, simplifiedRepId) =>
      entities.getIn([
        EK.SIMPLIFIED_REPS.state,
        simplifiedRepId,
      ])
  );

export const selectCurrentSimplifiedRepRulesets = () =>
  createSelector(
    selectEntities(),
    (_, ownProps) => ownProps.simplifiedRepId,
    (entities, simplifiedRepId) =>
      denormalize(
        entities
          .get(EK.SIMPLIFIED_REP_RULESETS.state)
          .filter(
            (ruleset) =>
              ruleset.simplifiedRepId === simplifiedRepId
          )
          .map((ruleset) =>
            ruleset.set(
              'simplifiedRepRulesetRules',
              entities
                .get(EK.SIMPLIFIED_REP_RULESET_RULES.state)
                .reduce(
                  (ruleIds, rule) =>
                    rule.simplifiedRepRulesetId === ruleset.id
                      ? ruleIds.push(rule.id)
                      : ruleIds,
                  List()
                )
            )
          )
          .toList(),
        [SimplifiedRepRulesetSchema],
        entities
      )
  );
