import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.CURRENT_USER.state;

export const fetchCurrentUsers = generateAPHTypes(resource, 'FETCH');

export const updateLastLegalInformedAtTypes = generateAPHTypes(resource, 'UPDATE_LAST_LEGAL_INFORMED_AT');

export const updateCurrentUsers = generateAPHTypes(resource, 'UPDATE');

export const changeCurrentUserPasswordTypes = generateAPHTypes(resource, 'CHANGE_PASSWORD');
