import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../assets/themes/base/colors';
import space from '../../assets/themes/base/space';
import transitions from '../../assets/themes/base/transitions';

import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Icon from '../../components/common/Icon';
import Text from '../../components/common/Text';

import FilterSidebar from '../templates/Structures/FilterSidebar';
import ShowArchivedWidget from '../templates/Structures/ShowArchivedWidget';

import { toggleSidebarFiltering } from './actions';

import {
  // Synchronize
  SynchronizeModuleConfigProfilesPath,
  SynchronizeModuleMapkeysPath,

  // Standardize
  StandardizeModuleLayersPath,
  StandardizeModuleNotesPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleParametersPath,
  StandardizeModuleParameterProfilesPath,
  StandardizeModuleParameterProfilesEditPath,
  StandardizeModuleNoteProfilesPath,
  // StandardizeModuleNoteProfilesEditPath,
  StandardizeModuleModelTemplatesPath,

  // Automate
  AutomateModuleRoutingProfilesPath,

  // Cabling
  CablingModuleSpoolsPath,

  // Piping
  PipingModuleEndTypesPath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleBoltNutsPath,
  PipingModuleBoltNutsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  PipingModuleMaterialsPath,
  PipingModuleInsulationSpecsPath,

  // ECAD
  EcadModuleCatalogsPath,

  // Framework
  FrameworkModulePath,

  // Reporting
  ReportingModuleTemplatesPath,

} from '../../paths';

// Synchronize
import ConfigProfileFilter from '../Synchronize/ConfigProfiles/Filter';
import MapkeysFilter from '../Synchronize/Mapkeys/Filter';

// Standardize
import LayerFilter from '../Standardize/Layers/Filter';
import NoteFilter from '../Standardize/Notes/Filter';
import OrientationFilter from '../Standardize/Orientations/Filter';
import SectionFilter from '../Standardize/Sections/Filter';
import SimplifiedRepsFilter from '../Standardize/SimplifiedReps/Filter';
import ExplodeStatesFilter from '../Standardize/ExplodeStates/Filter';
import LayerStatesFilter from '../Standardize/LayerStates/Filter';
import AppearanceStatesFilter from '../Standardize/AppearanceStates/Filter';
import StyleStatesFilter from '../Standardize/StyleStates/Filter';
import CombinedStatesFilter from '../Standardize/CombinedStates/Filter';

import ParameterFilter from '../Standardize/Parameters/Filter';
import ParameterProfilesFilter from '../Standardize/ParameterProfiles/Filter';
import ParameterProfilesEditFilter from '../Standardize/ParameterProfilesEdit/Filter';

import NoteProfilesFilter from '../Standardize/NoteProfiles/Filter';
// import NoteProfilesEditFilter from '../Standardize/NoteProfilesEdit/Filter';

import ModelTemplatesFilter from '../Standardize/ModelTemplates/Filter';

// Automate
import RoutingProfileFilter from '../Automate/RoutingProfiles/Filter';

// Cabling
import SpoolsFilter from '../Cabling/Spools/Filter';

// Piping
import SizeFilter from '../Piping/Sizes/Filter';
import MaterialFilter from '../Piping/Materials/Filter';
import ScheduleFilter from '../Piping/Schedules/Filter';
import EndTypeFilter from '../Piping/EndTypes/Filter';
import FittingCategoryFilter from '../Piping/FittingCategories/Filter';
import BoltNutFilter from '../Piping/BoltNuts/Filter';
import BoltNutEditFilter from '../Piping/BoltNutsEdit/Filter';
import FittingFilter from '../Piping/Fittings/Filter';
import FittingEditFilter from '../Piping/FittingsEdit/Filter';
import InsulationFilter from '../Piping/Insulations/Filter';
import InsulationEditFilter from '../Piping/InsulationsEdit/Filter';
import InsulationSpecFilter from '../Piping/InsulationSpecs/Filter';
import PipeFilter from '../Piping/Pipes/Filter';
import PipeEditFilter from '../Piping/PipesEdit/Filter';
import PipeBendFilter from '../Piping/PipesBends/Filter';
import PipeBendEditFilter from '../Piping/PipesBendsEdit/Filter';
import PipeBendMachineFilter from '../Piping/PipesBendMachines/Filter';
import PipeBendMachineEditFilter from '../Piping/PipesBendMachinesEdit/Filter';
import PipeBendSpringbackFilter from '../Piping/PipesBendSpringbacks/Filter';
import PipeBendSpringbackEditFilter from '../Piping/PipesBendSpringbacksEdit/Filter';
import PipeMiterFilter from '../Piping/PipesMiters/Filter';
import PipeMiterEditFilter from '../Piping/PipesMitersEdit/Filter';
import SpecFilter from '../Piping/Specs/Filter';
import SpecFittingFilter from '../Piping/SpecsFittingsEdit/Filter';
import SpecFittingInstanceFilter from '../Piping/SpecsFittingInstancesEdit/Filter';
import SpecPipeFilter from '../Piping/SpecsPipesEdit/Filter';
import SpecPipeInstanceFilter from '../Piping/SpecsPipeInstancesEdit/Filter';
import ProjectFilter from '../Piping/Projects/Filter';
import ProjectEditFilter from '../Piping/ProjectsEdit/Filter';

// ECAD
import EcadCatalogsFilter from '../ECAD/Catalogs/Filter';

// Framework
// import FrameworkCatalogsFilter from '../Framework/Catalogs/Filter';

// Reporting
import TemplatesFilter from '../Reporting/Templates/Filter';

// Components
const StyledFlex = styled(Flex)`
  display: flex;
  flex-direction: ${props => props.$flexDirection || 'row'};
  align-items: ${props => props.$alignItems || 'stretch'};
  justify-content: ${props => props.$justifyContent || 'flex-start'};
`;

const CloseTrigger = styled(StyledFlex)`
  position: absolute;
  top: ${space[5]};
  right: ${space[3]};
  cursor: pointer;
  height: 2.25rem;
  transition: color ${transitions.speed.fast}
    ${transitions.type.inOut};

  &:hover {
    color: ${colors.gray[7]};
  }
`;

const FilterTitle = styled(Text)`
  padding-top: ${space[5]};
  padding-bottom: ${space[5]};
  margin-left: ${space[3]};
`;

const DashboardFilterSidebar = ({ isMinimized, isFiltering }) => {
  const dispatch = useDispatch();
  const closeFilterSidebar = useCallback(
    () => dispatch(toggleSidebarFiltering()),
    [dispatch]
  );

  return (
    <FilterSidebar.Container $isFiltering={isFiltering}>
      <FilterSidebar.Inner $isMinimized={isMinimized}>
        <FilterSidebar.FormWrapper $isMinimized={isMinimized}>
          <FilterTitle
            color="gray.7"
            fontSize={4}
            fontWeight="600"
          >
            Filters
          </FilterTitle>

          <CloseTrigger
            onClick={closeFilterSidebar}
            $flexDirection="column"
            $justifyContent="center"
            $alignItems="center"
            p={3}
          >
            <Icon name="close" fontSize={3} />
          </CloseTrigger>

          <Route
            key="dashboard-filter-sidebar"
            path={[
              // Synchronize
              SynchronizeModuleConfigProfilesPath.url,

              // Standardize
              // StandardizeModuleParameterProfilesPath.url,
              // StandardizeModuleParameterProfilesEditPath.url,
              // StandardizeModuleNoteProfilesPath.url,
              // StandardizeModuleModelTemplatesPath.url,

              // Automate

              // Cabling

              // Piping
              PipingModuleBoltNutsPath.url,
              PipingModuleFittingsPath.url,
              PipingModuleInsulationsPath.url,
              PipingModulePipesPath.url,
              PipingModulePipesBendsPath.url,
              PipingModulePipesBendMachinesPath.url,
              PipingModulePipesBendSpringbacksPath.url,
              PipingModulePipesMitersPath.url,
              PipingModuleSpecsPath.url,
              PipingModuleProjectsPath.url,

              // Framework
            ]}
            component={ShowArchivedWidget}
          />

          <Switch>
            {/* Synchronize */}
            <Route
              key="sync-module-config"
              exact
              path={SynchronizeModuleConfigProfilesPath.url}
              component={ConfigProfileFilter}
            />
            <Route
              key="sync-module-mapkeys"
              exact
              path={SynchronizeModuleMapkeysPath.url}
              component={MapkeysFilter}
            />

            {/* Standardize */}
            <Route
              key="standardize-layers"
              exact
              path={StandardizeModuleLayersPath.url}
              component={LayerFilter}
            />
            <Route
              key="standardize-orientations"
              exact
              path={StandardizeModuleOrientationsPath.url}
              component={OrientationFilter}
            />
            <Route
              key="standardize-sections"
              exact
              path={StandardizeModuleSectionsPath.url}
              component={SectionFilter}
            />
            <Route
              key="standardize-simplified-reps"
              exact
              path={StandardizeModuleSimplifiedRepsPath.url}
              component={SimplifiedRepsFilter}
            />
            <Route
              key="standardize-explode-states"
              exact
              path={StandardizeModuleExplodeStatesPath.url}
              component={ExplodeStatesFilter}
            />
            <Route
              key="standardize-layer-states"
              exact
              path={StandardizeModuleLayerStatesPath.url}
              component={LayerStatesFilter}
            />
            <Route
              key="standardize-appearance-states"
              exact
              path={StandardizeModuleAppearanceStatesPath.url}
              component={AppearanceStatesFilter}
            />
            <Route
              key="standardize-style-states"
              exact
              path={StandardizeModuleStyleStatesPath.url}
              component={StyleStatesFilter}
            />
            <Route
              key="standardize-combined-states"
              exact
              path={StandardizeModuleCombinedStatesPath.url}
              component={CombinedStatesFilter}
            />
            <Route
              key="standardize-parameters"
              exact
              path={StandardizeModuleParametersPath.url}
              component={ParameterFilter}
            />
            <Route
              key="standardize-parameter-profiles"
              exact
              path={StandardizeModuleParameterProfilesPath.url}
              component={ParameterProfilesFilter}
            />
            <Route
              key="standardize-parameter-profiles-edit"
              exact
              path={StandardizeModuleParameterProfilesEditPath.url}
              component={ParameterProfilesEditFilter}
            />
            <Route
              key="standardize-notes"
              exact
              path={StandardizeModuleNotesPath.url}
              component={NoteFilter}
            />
            <Route
              key="standardize-note-profiles"
              exact
              path={StandardizeModuleNoteProfilesPath.url}
              component={NoteProfilesFilter}
            />
            <Route
              key="standardize-model-templates"
              exact
              path={StandardizeModuleModelTemplatesPath.url}
              component={ModelTemplatesFilter}
            />

            {/* Automate */}
            <Route
              key="automate-routing-profiles"
              exact
              path={AutomateModuleRoutingProfilesPath.url}
              component={RoutingProfileFilter}
            />

            {/* Cabling */}
            <Route
              key="cabling-spools"
              exact
              path={CablingModuleSpoolsPath.url}
              component={SpoolsFilter}
            />

            {/* Piping */}
            <Route
              key="piping-sizes"
              exact
              path={PipingModuleSizesPath.url}
              component={SizeFilter}
            />
            <Route
              key="piping-schedules"
              exact
              path={PipingModuleSchedulesPath.url}
              component={ScheduleFilter}
            />
            <Route
              key="piping-end-types"
              exact
              path={PipingModuleEndTypesPath.url}
              component={EndTypeFilter}
            />
            <Route
              key="piping-materials"
              exact
              path={PipingModuleMaterialsPath.url}
              component={MaterialFilter}
            />
            <Route
              key="piping-fitting-categories"
              exact
              path={PipingModuleFittingCategoriesPath.url}
              component={FittingCategoryFilter}
            />
            <Route
              key="piping-bolt-nuts"
              exact
              path={PipingModuleBoltNutsPath.url}
              component={BoltNutFilter}
            />
            <Route
              key="piping-bolt-nuts-edit"
              exact
              path={PipingModuleBoltNutsEditPath.url}
              component={BoltNutEditFilter}
            />
            <Route
              key="piping-fittings"
              exact
              path={PipingModuleFittingsPath.url}
              component={FittingFilter}
            />
            <Route
              key="piping-fittings-edit"
              exact
              path={PipingModuleFittingsEditPath.url}
              component={FittingEditFilter}
            />
            <Route
              key="piping-insulations"
              exact
              path={PipingModuleInsulationsPath.url}
              component={InsulationFilter}
            />
            <Route
              key="piping-insulations-edit"
              exact
              path={PipingModuleInsulationsEditPath.url}
              component={InsulationEditFilter}
            />
            <Route
              key="piping-insulation-specs"
              exact
              path={PipingModuleInsulationSpecsPath.url}
              component={InsulationSpecFilter}
            />
            <Route
              key="piping-pipes"
              exact
              path={PipingModulePipesPath.url}
              component={PipeFilter}
            />
            <Route
              key="piping-pipes-edit"
              exact
              path={PipingModulePipesEditPath.url}
              component={PipeEditFilter}
            />
            <Route
              key="piping-pipes-bends"
              exact
              path={PipingModulePipesBendsPath.url}
              component={PipeBendFilter}
            />
            <Route
              key="piping-pipes-bends-edit"
              exact
              path={PipingModulePipesBendsEditPath.url}
              component={PipeBendEditFilter}
            />
            <Route
              key="piping-pipes-bend-machines"
              exact
              path={PipingModulePipesBendMachinesPath.url}
              component={PipeBendMachineFilter}
            />
            <Route
              key="piping-pipes-bend-machines-edit"
              exact
              path={PipingModulePipesBendMachinesEditPath.url}
              component={PipeBendMachineEditFilter}
            />
            <Route
              key="piping-pipes-bend-springbacks"
              exact
              path={PipingModulePipesBendSpringbacksPath.url}
              component={PipeBendSpringbackFilter}
            />
            <Route
              key="piping-pipes-bend-springbacks-edit"
              exact
              path={PipingModulePipesBendSpringbacksEditPath.url}
              component={PipeBendSpringbackEditFilter}
            />
            <Route
              key="piping-pipes-miters"
              exact
              path={PipingModulePipesMitersPath.url}
              component={PipeMiterFilter}
            />
            <Route
              key="piping-pipes-miters-edit"
              exact
              path={PipingModulePipesMitersEditPath.url}
              component={PipeMiterEditFilter}
            />
            <Route
              key="piping-specs"
              exact
              path={PipingModuleSpecsPath.url}
              component={SpecFilter}
            />
            <Route
              key="piping-specs-fittings-edit"
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecFittingFilter}
            />
            <Route
              key="piping-specs-fitting-instances-edit"
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecFittingInstanceFilter}
            />
            <Route
              key="piping-specs-pipes-edit"
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecPipeFilter}
            />
            <Route
              key="piping-specs-pipe-instances-edit"
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecPipeInstanceFilter}
            />
            <Route
              key="piping-projects"
              exact
              path={PipingModuleProjectsPath.url}
              component={ProjectFilter}
            />
            <Route
              key="piping-projects-edit"
              exact
              path={PipingModuleProjectsEditPath.url}
              component={ProjectEditFilter}
            />

            {/* ECAD */}
            <Route
              key="ecad-catalogs"
              exact
              path={EcadModuleCatalogsPath.url}
              component={EcadCatalogsFilter}
            />

            {/* Reporting */}
            <Route
              key="reporting-templates"
              exact
              path={ReportingModuleTemplatesPath.url}
              component={TemplatesFilter}
            />

            {/* Framework */}

          </Switch>
        </FilterSidebar.FormWrapper>
      </FilterSidebar.Inner>
    </FilterSidebar.Container>
  );
};

export default DashboardFilterSidebar;
