import { createSelector } from 'reselect';
import { Map } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { formValueSelector } from 'redux-form/immutable';

const spoolFilterForm = formValueSelector(`filters.${EK.CABLING_SPOOLS.state}`);

export const selectSpools = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.CABLING_SPOOLS.state, Map())
  );

export const selectCurrentFilteredSpools = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectSpools(),
    state => spoolFilterForm(state, 'name', 'description'),
    (isFiltering, spools, query) => isFiltering ?
      spools.filter(spool => spool.doesMatchQuery(query)) :
      spools
  );

export const selectCurrentSpoolsCount = () =>
  createSelector(selectSpools(), (entities) => entities.size);