import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_PARAMETERS';

export const fetchAllParameters = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createParameters = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyParameters = generateAPHTypes(
  resource,
  'COPY'
);

export const editParameters = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteParameters = generateAPHTypes(
  resource,
  'DELETE'
);