import { toast } from 'react-toastify';

import materialClient from './client';

import { REQUEST_TYPES } from '../../../modules/api/constants';

import { fetchAllMaterials, saveMaterials } from './constants';

export const processFetchAllMaterials = () => materialClient(REQUEST_TYPES.GET, 'plural', fetchAllMaterials, '/piping/materials');

export const processSaveMaterials = (data) => materialClient(REQUEST_TYPES.PATCH, 'plural', saveMaterials, '/piping/materials/collection?diff_result=true', {
  data: { materialsAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
