import RoutingProfileSchema from './schema';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';

const routingProfileFilterForm = formValueSelector(
  `filters.${EK.AUTOMATE_ROUTING_PROFILES.state}`
);

export const selectNormalizedRoutingProfiles = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.AUTOMATE_ROUTING_PROFILES.state)
  );

export const selectNormalizedRoutingProfilesList = () =>
  createSelector(selectNormalizedRoutingProfiles(), (routingProfiles) =>
    routingProfiles ? routingProfiles.toList() : List()
  );

export const selectCurrentFilteredRoutingProfiles = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedRoutingProfiles(),
    state => ({
      name: routingProfileFilterForm(state, 'name'),
      description: routingProfileFilterForm(state, 'description'),
      algorithm: routingProfileFilterForm(state, 'algorithm'),
      // unitSystemId: routingProfileFilterForm(state, 'unitSystemId')
    }),
    (isFiltering, routingProfiles, query) =>
      isFiltering
        ? routingProfiles.filter((rp) => {
          const matchesName = !query.name || rp.name.toLowerCase().includes(query.name.toLowerCase());
          const matchesDescription = !query.description || (rp.description && rp.description.toLowerCase().includes(query.description.toLowerCase()));
          const matchesAlgorithm = !query.algorithm || rp.algorithm === query.algorithm;
          // const matchesUnitSystemId = !query.unitSystemId || rp.unitSystemId === query.unitSystemId;
          return matchesName && matchesDescription && matchesAlgorithm; // && matchesUnitSystemId
        }).toList()
        : routingProfiles.toList()
  );

export const selectCurrentRoutingProfilesCount = () =>
  createSelector(
    selectNormalizedRoutingProfiles(),
    (routingProfiles) => (routingProfiles && routingProfiles.toList() && routingProfiles.toList().size) || 0
  );

export const selectDenormalizedRoutingProfilesLists = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.AUTOMATE_ROUTING_PROFILES.state),
      [RoutingProfileSchema],
      entities
    )
  );