import { toast } from 'react-toastify';

import EK from '../../keys';

import api from '../../../modules/api/actions';
import { REQUEST_TYPES } from '../../../modules/api/constants';

import {
  saveParameterProfileParameters
} from './constants';
import schema from './schema';

const client = api(schema, EK.PARAMETER_PROFILE_PARAMETERS);

export const processSaveParameterProfileParameters = (profileId, data) => client(REQUEST_TYPES.PATCH, 'plural', saveParameterProfileParameters,  `/standardize/parameter_profiles/${profileId}/parameters/collection?diff_result=true`, {
  data: { parameterProfileParametersAttributes: data },
  mutateResponse: ({ parameterProfile: { id } = {}, parameterProfileParameters: { created = [], destroyed = [], updated = [] } = {} }) => ({
    parameterProfileParameters: {
      created: created.map(value => ({
        ...value,
        parameterProfile: `${id}`,
        parameterProfileId: `${id}`,
      })),
      destroyed: destroyed.map(value => ({
        ...value,
        parameterProfile: `${id}`,
        parameterProfileId: `${id}`,
      })),
      updated: updated.map(value => ({
        ...value,
        parameterProfile: `${id}`,
        parameterProfileId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});