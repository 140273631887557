import generateAPHTypes from '../../../utils/constants/generateAPHTypes';

const resource = 'ECAD_CATALOGS';

export const fetchAllCatalogs = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createCatalog = generateAPHTypes(
  resource,
  'CREATE'
);

export const editCatalog = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteCatalog = generateAPHTypes(
  resource,
  'DELETE'
);

export const copyCatalog = generateAPHTypes(
  resource,
  'COPY'
);

export const archiveCatalog = generateAPHTypes(
  resource,
  'ARCHIVE'
);

export const unarchiveCatalog = generateAPHTypes(
  resource,
  'UNARCHIVE'
);