import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../keys';
import selectEntities from '../../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../../routes/Dashboard/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import ParameterSchema from './schema';

const parameterProfileFilterForm = formValueSelector(
  `filters.${EK.PARAMETER_PROFILES.state}`
);

export const selectNormalizedParameterProfiles = () => createSelector(
  selectEntities(),
  entities => entities.get('parameterProfiles')
);

export const selectNormalizedParameterProfilesList = () => createSelector(
  selectNormalizedParameterProfiles(),
  parameterProfiles => parameterProfiles.toList()
);

export const selectCurrentFilteredParameterProfiles = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedParameterProfiles(),
  state => ({
    name: parameterProfileFilterForm(state, 'name'),
  }),
  (isFiltering, isShowingArchived, parameterProfiles, query) =>
    isFiltering
      ? parameterProfiles.filter((pp) =>
        (isShowingArchived || !pp.archivedFlag) &&
        pp.doesMatchQuery(query)
      ).toList()
      : parameterProfiles.filter(pp => !pp.archivedFlag).toList()
);

export const selectCurrentParameterProfilesCount = () => createSelector(
  selectNormalizedParameterProfiles(),
  (parameterProfiles) => (parameterProfiles && parameterProfiles.toList() && parameterProfiles.toList().size) || 0
);

export const selectParameterProfiles = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.PARAMETER_PROFILES.state),
      [ParameterSchema],
      entities
    )
  );

export const selectCurrentParameterProfile = (ppId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.PARAMETER_PROFILES.state, ppId]) && denormalize(entities.getIn([EK.PARAMETER_PROFILES.state, ppId]), ParameterSchema, entities)
);
