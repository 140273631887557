import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../../entities/keys';

import selectEntities from '../../../modules/entities/selectors';
import { selectRouteLocationPathname } from '../../../modules/router/selectors';

import UnitSystemSchema from '../../../entities/Synchronize/UnitSystems/schema';

import { PipingModulePipesEditPath } from '../../../paths';
import PipeFamilySchema from '../../../entities/Piping/PipeFamilies/schema';
import { selectNormalizedPipeFamilies } from '../../../entities/Piping/PipeFamilies/selectors';
import { selectPipingCatalogUnitSystem } from '../../../entities/Piping/Catalogs/selectors';

export const selectCurrentPipeId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, { path: PipingModulePipesEditPath.url });
    return (match && match.params.id) || null;
  });

export const selectCurrentPipe = (pipeId) =>
  createSelector(
    selectNormalizedPipeFamilies(),
    selectEntities(),
    (pipes, entities) => {
      return denormalize(pipes.get(pipeId), PipeFamilySchema, entities);
    }
  );

export const selectCurrentPipeUnitSystem = (pipeId) =>
  createSelector(
    selectCurrentPipe(pipeId),
    selectEntities(),
    selectPipingCatalogUnitSystem(),
    (pipe, defaultPipingCatalogUnitSystem) =>
      pipe?.unitSystem || defaultPipingCatalogUnitSystem
  );

export const selectCurrentPipeInstanceAndChildrenSerializationSelector = () =>
  createSelector(selectCurrentPipeUnitSystem(), (unitSystem) => ({
    unitSystem,
  }));
