import { toast } from 'react-toastify';
import { createAction } from 'redux-actions';
import { REQUEST_TYPES } from '../api/constants';
import { nonEntityApi } from '../api/actions';
import { client as currentUserClient } from '../../entities/CurrentUser/actions';
import userClient from '../../entities/Users/client';
import organizationUserInviteClient from '../../entities/OrganizationUserInvites/client';
import { track } from '../../configuration/mixpanel';

import {
  processLogout,
  processLoginTypes,
  processRegisterTypes,
  processVerifyEmailTypes,
  processAcceptInviteTypes,
  processFetchCurrentUsers,
  processRequestPasswordResetTypes,
  processResetPasswordTypes,
  processForceResetPasswordTypes,
  samlLoginTypes,
} from './constants';

export const processLogin = (data, rememberMe = true) => currentUserClient(REQUEST_TYPES.POST, 'single', processLoginTypes, '/sessions', {
  data,
  disableErrorRedirect: true,
  mutateResponse: ({ user }, { entityKey }) => ({
    [entityKey]: user.emailVerified ? user : {},
    emailVerified: user.emailVerified,
    user,
    ...(user.emailVerified ? {
      authenticationToken: user.authenticationToken,
      passwordResetRequired: user.passwordResetRequired,
      ...(rememberMe ? { rememberMe } : {}),
    } : {}),
  }),
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Error',
  },
});

export const processSAMLLogin = (data) => ({
  type: samlLoginTypes.SUCCESS,
  payload: {
    authenticationToken: data,
  },
});

export const processFetchCurrentUser = (disableErrorRedirect) =>
  currentUserClient(REQUEST_TYPES.GET, 'single', processFetchCurrentUsers, '/me', {
    disableErrorRedirect,
  });

export const processRegister = (data) =>
  userClient(REQUEST_TYPES.POST, 'single', processRegisterTypes, '/users', {
    data,
    disableErrorRedirect: true,
    mutateRequest: (data, { entityKey }) => ({ [entityKey]: data }),
  });

export const processVerifyEmail = (verifyToken) =>
  userClient(REQUEST_TYPES.PATCH, 'single', processVerifyEmailTypes, '/users/verify_email', {
    data: { verifyToken },
    mutateResponse: ({ user }, { entityKey }) => ({
      [entityKey]: user,
      authenticationToken: user.authenticationToken,
    }),
  });

export const processAcceptInvite = (data, token) =>
  organizationUserInviteClient(
    REQUEST_TYPES.PATCH,
    'single',
    processAcceptInviteTypes,
    `/organization_user_invites/${token}/accept`,
    {
      data,
      mutateResponse: ({ organizationUserInvite }, { entityKey }) => ({
        [entityKey]: organizationUserInvite,
        authenticationToken: organizationUserInvite.authenticationToken,
      }),
    }
  );

export const processRequestPasswordReset = (data) =>
  nonEntityApi(
    REQUEST_TYPES.POST,
    processRequestPasswordResetTypes,
    '/password_resets',
    { data }
  );

export const processPasswordReset = (data) =>
  nonEntityApi(
    REQUEST_TYPES.PATCH,
    processResetPasswordTypes,
    '/password_resets',
    { data }
  );

export const processForceResetPassword = (data) =>
  nonEntityApi(
    REQUEST_TYPES.POST,
    processForceResetPasswordTypes,
    '/password_resets',
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Password reset email sent',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to reset password',
      },
    }
  );

export const handleLogout = () => (dispatch) => {
  track('User Logged Out');
  dispatch(createAction(processLogout.HANDLE)());
};

export const logoutHandled = () => (dispatch) => {
  dispatch(createAction(processLogout.HANDLED)());
};
