import React from 'react';
import theme from '../../../../assets/themes/theme';

export default function AutomateGraphic({
  id = 'automate-graphic',
  backgroundColor = theme.colors.primary[4],
  disabledBackgroundColor = theme.colors.primary[1],
  disabled = false,
}) {
  return (
    <svg
      id={id}
      data-name={`data-${id}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 196.04642 195.70069"
    >
      <path
        style={{ fill: theme.colors.gray[7] }}
        d="M358.52917,172.6575"
        transform="translate(-239.65198 -134.91261)"
      />
      <ellipse
        style={{ fill: disabled ? disabledBackgroundColor : backgroundColor }}
        cx="98.02321" cy="97.85034" rx="98.02321" ry="97.85034"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M356.92213,245.17616l-9.77261-9.76212c4.33081,0,8.65051.01291,12.97-.009,1.09505-.00555.68651.74363.60047,1.19568A19.65248,19.65248,0,0,1,356.92213,245.17616Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M328.35428,249.16722l9.7401-9.74506c0,4.29463-.01084,8.61685.0075,12.93894.00468,1.10329-.7617.67057-1.20483.58668A19.55677,19.55677,0,0,1,328.35428,249.16722Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[4] }}
        d="M274.04074,186.861q-.558-.00009-1.116,0c-4.01352.00165-3.99012.00242-3.86077-4.02565.02562-.79792.19183-1.12081,1.06114-1.10433q4.06392.077,8.13006.00058c.85656-.01577,1.0454.28077,1.07114,1.09342.12791,4.03813.153,4.03732-3.85074,4.03611Q274.75816,186.86086,274.04074,186.861Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M334.199,230.30954c-4.381,0-8.61772-.022-12.85388.01854-.846.0081-.96939-.31132-.83575-1.02185a19.7902,19.7902,0,0,1,3.88173-8.80049Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[4] }}
        d="M301.06824,186.85788c-5.0477-.01645-10.09574-.069-15.14284-.022-1.14863.0107-1.54329-.21978-1.52211-1.463.06265-3.67626.00086-3.67264,3.74235-3.67976q6.13672-.01168,12.27332-.03868,19.72256.0206,39.44529.03757c24.85322-.013,45.21561,16.19391,50.76787,40.4572a51.982,51.982,0,0,1,1.138,9.33258c.04314.97673-.114,1.46539-1.24824,1.35282-1.20965-.12008-2.7131.35887-3.57764-.20577-.84589-.55244-.28306-2.16684-.40529-3.30971q-2.87867-26.91565-27.294-38.55533a40.96387,40.96387,0,0,0-17.61449-3.92165C328.11,186.79237,314.589,186.84631,301.06824,186.85788Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <ellipse
        style={{ fill: theme.colors.gray[4] }}
        cx="101.21585" cy="98.15646" rx="62.48633" ry="61.18268"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M364.91288,259.39255a49.22755,49.22755,0,0,0,9.6015,2.525,107.412,107.412,0,0,0,13.02507,1.58493,30.63607,30.63607,0,0,1-5.40792,7.22692c-11.93413,12.70907-26.65269,18.8755-44.07282,18.30306-25.81241-.8482-48.28626-20.668-52.7418-46.27436a56.9897,56.9897,0,0,1,2.38429-28.92245,1.78869,1.78869,0,0,1,2.0267-1.44562c6.545.08321,13.09129.06775,19.63713.086a4.80048,4.80048,0,0,1,.6352,1.649,47.578,47.578,0,0,0-4.29954,10.9093A35.86842,35.86842,0,0,0,335.564,268.36675C346.71416,269.87837,356.444,266.68253,364.91288,259.39255Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[4] }}
        d="M292.14566,232.86418c2.42542.001,2.42541.001,2.52924,2.345.94952,21.43577,17.59009,39.913,38.77848,43.06823a40.23863,40.23863,0,0,0,6.18918.56239c.74407-.00491,1.086.22016,1.00289.99886a12.05984,12.05984,0,0,0-.00736,1.27659c.00075,3.024.00073,3.024-3.09442,2.79825a51.16845,51.16845,0,0,1-46.85552-40.29744,58.45767,58.45767,0,0,1-1.16429-9.00442c-.09088-1.33033.16219-2.05019,1.66553-1.75649A5.09857,5.09857,0,0,0,292.14566,232.86418Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[4] }}
        d="M385.88989,190.64871c-3.00535-4.05472-12.79026-10.58987-12.91208-10.68092-4.74757-3.54818-13.1468-5.4133-14.28019-5.6985a78.22678,78.22678,0,0,0-19.33441-2.75861c-21.98222.03725-43.96538-.08615-65.946.106-4.05035.03546-7.3833-.50042-9.74559-4.012a1.46489,1.46489,0,0,0-.22685-.22393,2.56985,2.56985,0,0,0-3.0367-.71994,2.66772,2.66772,0,0,0-1.55225,2.72014q.013,22.597.00112,45.19412a2.66222,2.66222,0,0,0,1.56373,2.71355,2.54615,2.54615,0,0,0,3.03172-.73629c1.17851-1.15435,2.3558-2.31113,3.49745-3.50149a1.924,1.924,0,0,1,1.52987-.64451c4.682.0252,9.36423.01364,14.26317.01364-.14126.50541-.23.86044-.33942,1.20888-5.58427,17.779-3.75457,34.7695,5.70775,50.81281a61.22648,61.22648,0,0,0,61.923,29.04811,59.86321,59.86321,0,0,0,37.37622-21.06629c11.97367-14.28819,16.46238-30.82176,13.78992-49.24588a58.33169,58.33169,0,0,0-7.80446-21.64946"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M299.78744,176.70554c5.05457-.02245,10.10914-.058,15.16373-.06442q12.69335-.016,25.38672-.00164a56.15676,56.15676,0,0,1,55.43857,45.32792,54.84906,54.84906,0,0,1-7.18169,40.16184,14.784,14.784,0,0,1-1.05532,1.37322,107.412,107.412,0,0,1-13.02507-1.58493,49.22755,49.22755,0,0,1-9.6015-2.525A37.0639,37.0639,0,0,0,375.557,241.06774a35.816,35.816,0,0,0-30.785-43.714,35.21745,35.21745,0,0,0-28.22216,9.08313,2.99641,2.99641,0,0,1-2.24356.88992c-2.49662-.07784-4.99618-.06143-7.49468-.08243-.229-.64482-.44408-1.29489-.68881-1.93361a90.651,90.651,0,0,1-5.07644-18.39871c13.52077-.01158,27.0417-.06551,40.56225-.01564a40.96381,40.96381,0,0,1,17.61449,3.92165q24.37744,11.61737,27.294,38.55533c.12224,1.14287-.44059,2.75726.40529,3.30971.86454.56463,2.368.08569,3.57765.20576,1.13425.11258,1.29137-.37608,1.24823-1.35281a51.98007,51.98007,0,0,0-1.138-9.33258c-5.55225-24.26329-25.91465-40.47018-50.76786-40.4572q-19.72269.01029-39.44539-.03759C299.7817,180.09118,300.33586,178.33118,299.78744,176.70554Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M299.78744,176.70554c.54842,1.62564-.00574,3.38564.60965,5.00315q-6.13657.02-12.27333.03868c-3.74148.00711-3.6797.0035-3.74235,3.67976-.02118,1.24318.37349,1.47366,1.52211,1.463,5.04711-.047,10.09514.0055,15.14285.022a90.651,90.651,0,0,0,5.07644,18.39871c.24473.63872.45985,1.28879.68881,1.93362q-12.20433.0307-24.40886.05979c-4.9465.00882-9.89321.02515-14.83942-.014a3.94436,3.94436,0,0,0-3.46319,1.3851V175.40159c1.20241,1.31952,2.71911,1.2549,4.258,1.2514q14.83947-.03382,29.67906-.01024C298.62062,176.64294,299.204,176.68368,299.78744,176.70554Zm-25.7467,10.15551q.71742,0,1.43484,0c4.00374.00121,3.97865.002,3.85074-4.03611-.02574-.81265-.21458-1.10919-1.07114-1.09342q-4.064.07482-8.13006-.00058c-.86931-.01648-1.03552.30641-1.06114,1.10433-.12935,4.02807-.15275,4.0273,3.86077,4.02565Q273.48275,186.86073,274.04074,186.86105Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.primary[7] }}
        d="M309.97327,232.77417a30.66072,30.66072,0,1,1,30.57587,30.75794A30.569,30.569,0,0,1,309.97327,232.77417Zm5.11043-.01383a25.628,25.628,0,0,0,25.15485,25.66085c14.14864.09318,25.85243-11.20441,25.94579-25.04531a25.553,25.553,0,1,0-51.10064-.61554Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[7] }}
        d="M315.0837,232.76034a25.553,25.553,0,1,1,51.10064.61554c-.09336,13.8409-11.79715,25.13849-25.94579,25.04531A25.628,25.628,0,0,1,315.0837,232.76034Zm28.10845-6.33546c3.3369-3.34807,6.54239-6.56431,9.74227-9.77492a19.259,19.259,0,0,0-8.73348-3.92178c-.70512-.07966-1.03584-.01764-1.02753.83746C343.21454,217.80327,343.19215,222.04152,343.19215,226.42488Zm-8.99313,3.88557-9.8079-9.8038a19.7902,19.7902,0,0,0-3.88173,8.80049c-.13364.71053-.0103,1.02995.83575,1.02185C325.5813,230.28848,329.81805,230.31045,334.199,230.31045Zm22.72311,14.87294A19.65239,19.65239,0,0,0,360.72,236.608c.086-.452.49458-1.20123-.60047-1.19569-4.31952.02192-8.63922.009-12.97.009Zm-28.58033,3.98383a19.55682,19.55682,0,0,0,8.54277,3.78056c.44313.08389,1.20951.51661,1.20483-.58668-.01834-4.32209-.0075-8.64431-.0075-12.93894Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M343.19215,226.42488c0-4.38336.02239-8.62161-.01874-12.85924-.00831-.8551.32241-.91712,1.02753-.83746a19.259,19.259,0,0,1,8.73348,3.92178C349.73454,219.86057,346.52905,223.07681,343.19215,226.42488Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M338.08111,226.67933c-3.32969-3.33038-6.35394-6.3716-9.40293-9.38782-.47588-.47077-.40835-.73024.10251-1.08639a20.85675,20.85675,0,0,1,8.14217-3.44694c.73009-.13782,1.18633-.06177,1.17649.90585C338.0568,217.85281,338.08111,222.04225,338.08111,226.67933Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M347.05841,230.30759c3.38306-3.37463,6.5741-6.55773,9.8197-9.79523a19.64252,19.64252,0,0,1,3.86562,8.689c.11935.62661.1202,1.1271-.7983,1.12023C355.71339,230.28991,351.481,230.30759,347.05841,230.30759Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M334.43533,235.42533c-3.33146,3.336-6.39681,6.39343-9.44315,9.46966-.4382.44251-.6785.29607-.97517-.12647a20.41532,20.41532,0,0,1-3.48919-8.28962c-.09318-.53231-.16223-1.06538.6927-1.06179C325.49646,235.43506,329.77251,235.42533,334.43533,235.42533Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[1] }}
        d="M343.19479,239.321c3.36736,3.3723,6.55219,6.56179,9.7853,9.79962a19.76069,19.76069,0,0,1-8.69084,3.85586c-.64127.12208-1.11541.08957-1.10862-.8128C343.21248,247.93343,343.19479,243.70278,343.19479,239.321Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[4] }}
        d="M340.86789,197.12129h-.00012a36.05087,36.05087,0,0,0-36.153,35.94777v.00012a36.05063,36.05063,0,0,0,36.153,35.94766h.00012a36.05078,36.05078,0,0,0,36.153-35.94766v-.00012A36.051,36.051,0,0,0,340.86789,197.12129Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.gray[0] }}
        d="M371.87207,232.82139a30.57737,30.57737,0,0,0-2.40124-11.657,24.37782,24.37782,0,0,0-16.38069,4.17193,14.50736,14.50736,0,0,0-4.72872-4.59381,32.13951,32.13951,0,0,1,17.34214-6.12683,31.07609,31.07609,0,0,0-13.06757-10.0765,24.12967,24.12967,0,0,0-8.62327,14.46547,14.17069,14.17069,0,0,0-6.61238.07806,31.74624,31.74624,0,0,1,7.91738-16.52787,31.43151,31.43151,0,0,0-4.69609-.31386,31.05123,31.05123,0,0,0-11.72207,2.38771,24.04309,24.04309,0,0,0,4.19032,16.2895,14.50737,14.50737,0,0,0-4.62044,4.70444,31.707,31.707,0,0,1-6.15858-17.24625,30.9587,30.9587,0,0,0-10.13639,12.99742c1.08117,1.44746,5.60539,6.74018,14.548,8.57323a13.94042,13.94042,0,0,0,.08068,6.57839,32.06009,32.06009,0,0,1-16.62413-7.87067,30.90005,30.90005,0,0,0-.31542,4.662,30.5747,30.5747,0,0,0,2.40332,11.66231,24.40848,24.40848,0,0,0,16.38273-4.17052,14.50817,14.50817,0,0,0,4.72028,4.58447,32.1416,32.1416,0,0,1-17.33068,6.13912,31.07672,31.07672,0,0,0,13.06992,10.07065,24.14265,24.14265,0,0,0,8.621-14.46736,14.17807,14.17807,0,0,0,6.61235-.08174,31.74869,31.74869,0,0,1-7.91268,16.53068,31.40535,31.40535,0,0,0,4.68435.313,31.05474,31.05474,0,0,0,11.729-2.39086c.26368-1.77364.83253-8.70027-4.19581-16.28744a14.50611,14.50611,0,0,0,4.61949-4.70455,31.70937,31.70937,0,0,1,6.1631,17.24348,30.9594,30.9594,0,0,0,10.13185-12.99572c-1.069-1.43351-5.58849-6.73853-14.54891-8.56929a13.93957,13.93957,0,0,0-.07861-6.57389,32.04836,32.04836,0,0,1,16.62228,7.86438A30.9048,30.9048,0,0,0,371.87207,232.82139Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <path
        style={{ fill: theme.colors.primary[7] }}
        d="M346.80036,233.06912a5.93262,5.93262,0,1,1-5.93247-5.89879A5.91578,5.91578,0,0,1,346.80036,233.06912Z"
        transform="translate(-239.65198 -134.91261)"
      />
      <ellipse style={{ fill: 'none', stroke: theme.colors.gray[4], strokeMiterlimit: 10, strokeWidth: 7 }} cx="101.21586" cy="98.15657" rx="10.0642" ry="10.20992" />
    </svg>
  );
}