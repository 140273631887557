/* eslint-disable prefer-const */
import React, {useEffect, useState, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';

// Header
import Flex from '../../../components/common/Flex';
import { PipingModuleSizesPath } from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';
import { convertToSize, saveCurrentSizeChanges } from './actions';

// validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import { ALLOWANCES, VALIDATION_FIELDS } from '../../../entities/Piping/Sizes/model';

// actions
import { processFetchAllSizes, processSaveSizes } from '../../../entities/Piping/Sizes/actions';

// selectors
import { selectCurrentFilteredSizes, selectUnfilteredSizesCount } from '../../../entities/Piping/Sizes/selectors';
import { selectPipingModulePermissionsAndState, selectSidebarIsFiltering } from '../../Dashboard/selectors';
import colors from '../../../assets/themes/base/colors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectCurrentFilteredSizes(),
  selectUnfilteredSizesCount(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    data,
    totalSizes,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.length == 0));
    return {
      ...rest,
      isFiltering,
      isLoading,
      canCollaborate,
      hasValidLicense,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      totalSizes,
    };
  },
);

function SizesGrid () {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  // editing state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction] = useOnCellEditComplete(reduxProps.data, convertToSize, reduxProps.editable);

  // Cell Editors
  const textCellEditor = (field) => <InputText value={currentEditingRow && currentEditingRow[field] || ''} onChange={(e) => onCellChange(e.target.value, field)} />;

  //Cell displays
  const textValueBody = (rowdata, field) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || { id: rowdata.id, description: rowdata.description, code: rowdata.code, name: rowdata.name };

    if (curRow.id && curRow.id.includes('NEW_ROW_') && curRow[field] == '') {
      return (
        <Text style={{ color: colors.gray[5] }} >new row</Text>
      );
    }

    return (
      <>
        <Text>{curRow && curRow[`${field}`]}</Text>
      </>
    );
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchAllSizes());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={PipingModuleSizesPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.totalSizes} ${pluralize('filtered Size', reduxProps.data.length)}` :
            `Showing ${reduxProps.data.length - 15} ${pluralize('Size', reduxProps.data.length - 15)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentSizeChanges(
            editedRows,
            (data) => { dispatch(processSaveSizes(data)); resetEditedRows();},
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column
            header=''
            style={{ width: '2%' }}
            body={(rowdata) => getRowTool(rowdata)}
          />
          <Column
            field='name'
            header='Size'
            style={{ width: '25%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('name')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'name')}
            sortable
          />
          <Column
            field='code'
            header='Code'
            style={{ width: '25%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('code')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'code')}
            sortable
          />
          <Column
            field='description'
            header="Description"
            style={{ width: '50%' }}
            onBeforeCellEditShow={onBeforeCellEditShow}
            editor={() => textCellEditor('description')}
            onCellEditComplete={(e) => onCellEditComplete.current(e)}
            body={(rowdata) => textValueBody(rowdata, 'description')}
            sortable
          />
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(SizesGrid);
